import React from 'react';
import { createSvgIcon } from '@mui/material/utils';

export async function urlIsSvg(url) {
    const r = await fetch(url, { method: 'HEAD' });
    return r.headers.get('content-type') === 'image/svg+xml';
}

const convert = require('xml-js');
const JsonFind = require("json-find");

export async function readSVG(url) {
    console.log("readSVG: " + url);
    const r = await fetch(url);
    return r.text()
        .then(str => JSON.parse(convert.xml2json(str, { compact: true })))
        .then(data => {
            let svg = {};
            let doc = JsonFind(data);
            // path
            let paths = doc?.checkKey("path");
            let path = [];
            if (paths?._attributes?.d) {
                path.push(paths._attributes.d)
            } else {
                for (let i = 0; i < paths.length; i++) {
                    let p = paths[i]._attributes?.d;
                    path.push(p);
                }
            }
            svg.path = path;

            // circle
            let circles = doc?.checkKey("circle");
            let circle = [];
            if (circles?._attributes?.cx) {
                let c = {};
                c.cx = circles._attributes.cx;
                c.cy = circles._attributes.cy;
                c.r = circles._attributes.r;
                circle.push(c)
            } else {
                for (let i = 0; i < circles.length; i++) {
                    let ci = {};
                    ci.cx = circles[i]._attributes?.cx;
                    ci.cy = circles[i]._attributes?.cy;
                    ci.r = circles[i]._attributes.r;
                    circle.push(ci);
                }
            }
            svg.circle = circle;

            // rectangle
            let rects = doc?.checkKey("rect");
            let rect = [];
            if (rects?._attributes?.x) {
                let r = {};
                r.x = rects._attributes.x;
                r.y = rects._attributes.y;
                r.width = rects._attributes.width;
                r.reight = rects._attributes.height;
                if (rects._attributes.rx) {
                    r.rx = rects._attributes.rx;
                }
                if (rects._attributes.ry) {
                    r.ry = rects._attributes.ry;
                }
                rect.push(r)
            } else {
                for (let i = 0; i < rects.length; i++) {
                    let re = {};
                    re.x = rects[i]._attributes?.x;
                    re.y = rects[i]._attributes?.y;
                    re.width = rects[i]._attributes?.width;
                    re.height = rects[i]._attributes?.height;
                    if (rects[i]._attributes.rx) {
                        re.rx = rects[i]._attributes.rx;
                    }
                    if (rects[i]._attributes.ry) {
                        re.ry = rects[i]._attributes.ry;
                    }
                    rect.push(re);
                }
            }
            svg.rect = rect;

            svg.viewBox = doc?.checkKey("viewBox");
            svg.strokeWidth = doc?.checkKey("stroke-width")?.toString();
            svg.strokeLinecap = doc?.checkKey("stroke-linecap")?.toString();
            svg.strokeLinejoin = doc?.checkKey("stroke-linejoin")?.toString();
            console.log("return svg = " + JSON.stringify(svg));
            return svg;
        })
        .catch(e => {
            console.log(e);
        });

}


export const SVGIcon = (props) => {
    console.log("createSVGIcon ...");
    console.log(props.svg);
    let index = 1;

    const CusIcon = createSvgIcon(
        <svg
            xmlns="http://www.w3.org/2000/svg"
            fill={props.svg.fill}
            viewBox={props.svg?.viewBox}
            strokeWidth={props.svg?.strokeWidth}
            stroke="currentColor"
            alt=""
        >
            {props.svg.path.map(p =>
                <path key={index++} strokeLinecap={props.svg?.strokeLinecap} strokeLinejoin={props.svg?.strokeLinejoin} d={p}></path>
            )}
            {props.svg.circle.map(c =>
                <circle key={index++} cx={c.cx} cy={c.cy} r={c.r}></circle>
            )}
            {props.svg.rect.map(r =>
                <rect key={index++} x={r.x} y={r.y} width={r.width} height={r.height} rx={r.x ? r.x : ''} ry={r.y ? r.y : ''}></rect>
            )}
        </svg>,
        props.svg.name,
    );

    return <CusIcon {...props} />;
}