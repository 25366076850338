import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Stack, Box, Typography, Divider,
    Backdrop, CircularProgress
} from '@mui/material';
import { visuallyHidden } from "@mui/utils";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/grid';
import { orders, orderCancelled, isWSS } from '../../redux/Selectors';
import { fetchOrders, cancelOrder } from '../../redux/Actions';
import AppHeader from '../../components/AppHeader';
import RegistrationPaging from '../../m/components/RegistrationPaging';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import { ConfirmationDialog } from '../../components/ModalDialog';
import { Orders as OrdersAcc } from '../../components/customer/Orders';

export const Orders = () => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _orders = useSelector(state => orders(state));
    //    const period = useSelector(state => ordersPeriod(state));
    const _orderCancelled = useSelector(state => orderCancelled(state));
    const _isWSS = useSelector(state => isWSS(state));

    const [refreshCount, setRefreshCount] = useState(1);
    const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
    const [msg, setMsg] = useState('');
    const [title, setTitle] = useState('');
    const [selectedOrder, setSelectedOrder] = useState();
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [ordersInitialized, setOrdersInitialized] = useState(false);
    const [progressText, setProgressText] = useState("");

    const period = _isWSS ? 'month' : 'year';


    useEffect(() => {
        setBackdropOpen(true);
        console.log("set progressText to " + t("accessibility.orders.progress.description.load"));
        setProgressText(t("accessibility.orders.progress.description.load"));
        setOrdersInitialized(false);
        dispatch(fetchOrders(period, refreshCount));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setRefreshCount(refreshCount + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [period]
    );

    useEffect(() => {
        setBackdropOpen(true);
        console.log("fetch orders ...");
        setOrdersInitialized(false);
        dispatch(fetchOrders(period, refreshCount));
        //       setPending(true);
        setRefreshCount(refreshCount + 1);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_orderCancelled]);

    useEffect(() => {
        console.log(_orders);
        if (/*_orders?.length > 0 && */ ordersInitialized === true) {
            console.log("setBackdropOpen false");
            setBackdropOpen(false);
            setProgressText(t("accessibility.orders.progress.description.loaded"));
        } else {
            setOrdersInitialized(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_orders]);

    useEffect(() => {
        console.log("set progressText to " + (backdropOpen === true ? t("accessibility.orders.progress.description.load") : t("accessibility.orders.progress.description.loaded")));
        setProgressText(backdropOpen === true ? t("accessibility.orders.progress.description.load") : t("accessibility.orders.progress.description.loaded"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backdropOpen]);

    const handleCancelClick = (order) => {
        setSelectedOrder(order);
        setTitle("accessibility.orders.progress.table.actions.cancel.confirmation.title")
        setMsg(t('m.order.cancelConfirmation'));
        setConfirmationDialogOpen(true);
    }

    const okAction = (e) => {
        console.log("Do cancel ... ");
        let url = selectedOrder._links.cancel.href.replace('{id}', selectedOrder._id);
        console.log("cancel with url " + selectedOrder._links.cancel.href);
        dispatch(cancelOrder(url, false));
        setMsg('');
        setTitle("");
        setConfirmationDialogOpen(false);
    }

    const cancelAction = (e) => {
        setMsg('');
        setTitle("");
        setConfirmationDialogOpen(false);
        setSelectedOrder();
    }


    return (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack id="maincontent" sx={{ minHeight: windowHeight - 167 }} spacing={4} width='100%'>
                <RegistrationPaging step={0} pagetitle='accessibility.customer.orders.title' />
                <Divider />

                {_orders.length > 0 && (<Stack width='100%' alignItems='center'>
                    <Box width='90%' >
                        <OrdersAcc showBusyIndicator={setBackdropOpen} variant="a11y" handleCancelClick={handleCancelClick} />
                    </Box>
                </Stack>)}
                {_orders.length === 0 && (<Box aria-live="assertive"><Typography sx={{ pl: 4 }} variant="mobilePagePrimaryLabel">{t('m.customer.orders.empty')}</Typography></Box>)}
            </Stack>

            <Typography sx={visuallyHidden} aria-live="assertive">{progressText}</Typography>
            <NavigationBar
                back={{ clicked: () => navigate(-1), label: t('m.customer.orders.back') }}>
            </NavigationBar>
            <InfoText />
            <ConfirmationDialog open={confirmationDialogOpen} title={title} msg={msg} okAction={okAction} cancelAction={cancelAction} />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={() => {
                }}
            >
                <CircularProgress color="inherit" aria-busy={backdropOpen === true ? "true" : "false"} />
            </Backdrop>
        </Stack >
    );
}

export default Orders;