import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, Grid, Paper, Stack, Typography } from '@mui/material';
import { DataBold, Label } from '../../components/common/Commons';
import { visuallyHidden } from "@mui/utils";
import { PdfIcon } from '../../common/widgetTools';


export const OrderFailureDialog = ({ open, handleClose, message, apiResponse }) => {

    const { t } = useTranslation();

    const [msg, setMsg] = useState(t('order.orderFailure'));

    /*  useEffect(() => {
          setMsg(message ? message : t('order.orderFailure'));
      }, [message]); */

    const orderDetails = () => {
        return (
            <Stack container direction="column" spacing={5} sx={{ width: '90%', margin: 'auto' }}>
                {apiResponse.originalRequest.orderCode && (
                    <>
                        <Label id="label-ordercode" text='order.orderCode' />
                        <DataBold aria-label="label-ordercode" text={apiResponse.originalRequest.orderCode} />
                    </>
                )}
                <Label id="label-bp" text='customerdata.businessPartnerNo' />
                <DataBold aria-label="label-bp" text={apiResponse.originalRequest.businessPartnerNumber} />
                <Label text='order.orderFailure.date' />
                <DataBold text={new Date().toTimeString()} />
            </Stack>
        );
    };

    const getMessage = () => {
        return apiResponse?.originalRequest ? t('order.orderFailure.message') : t('order.orderFailure');
    };

    const handleDownload = () => {
        document.getElementById('motif-download').click();
    };

    const Download = () => {
        return (<Box sx={{ visuallyHidden, visibility: "hidden", width: 0 }}><a
            rel="noreferrer"
            aria-hidden="true"
            id="motif-download"
            href={apiResponse.props["motif-download"]}
            target="_blank"
            download={`${apiResponse.originalRequest.motif.motifUuid}.pdf`}>{`${apiResponse.originalRequest.motif.motifUuid}.pdf`}
        </a></Box>);
    }

    return (
        <Dialog fullScreen open={open} role="alertdialog" aria-modal="true" aria-labelledby="dialog_label_failure" aria-describedby="dialog_desc_failure">
            <Paper sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                <h1><Typography
                    id="dialog_label_failure"
                    sx={{ margin: '5vh', fontSize: 50, fontWeight: 'bold' }}>{t('order.orderFailure.title')}</Typography></h1>
                <Typography
                    id="dialog_desc_failure"
                    sx={{ textAlign: 'center', width: '90%', }}
                    dangerouslySetInnerHTML={{ __html: getMessage() }}></Typography>
                <Box sx={{ marginTop: '5vh' }} >
                    {apiResponse?.originalRequest && orderDetails()}
                </Box>
                <Stack direction="row" spacing={10} sx={{ marginTop: '10vh' }}>
                    <Button ssx={{ textTransform: 'none' }} variant="navigationBack" onClick={handleClose}>Ok</Button>
                    {apiResponse?.originalRequest && <>
                        <Download></Download>
                        <Button sx={{ textTransform: 'none' }} startIcon={<PdfIcon />} variant="navigationBack" onClick={handleDownload}>{t('order.motifDownload')}</Button>
                    </>}
                </Stack>
            </Paper >
        </Dialog >
    );
}
