import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { setCustomerType, initOrder } from '../redux/Actions.js';
import {
    customerType as selectCustomerType,
    customerTypeChecked as selectCustomerTypeChecked
} from '../redux/Selectors.js';

import Switch from '@mui/material/Switch';

import { useTranslation } from 'react-i18next';
import '../configuration/i18n';
import { Stack, Typography, Box, FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { properties, device } from '../redux/Selectors.js';
import { DEVICE_ACC } from '../common/navigationTools.js';


// https://www.digitalocean.com/community/tutorials/redux-reselect

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const CustomerType = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const customerType = useSelector((state) => selectCustomerType(state));
    const customerTypeChecked = useSelector((state) => selectCustomerTypeChecked(state));
    const _properties = useSelector((state) => properties(state));
    const _device = useSelector((state) => device(state));

    const VAL_PRIVATE = "private";
    const VAL_BUSINESS = "business";

    const changed = (e) => {
        if (e.target.checked && e.target.name === "switchCustomerType") {
            dispatch(setCustomerType((e.target.checked && (_device === 'desktop' || _device === DEVICE_ACC)) || (!e.target.checked && (_device !== 'desktop' && _device !== DEVICE_ACC)) ? VAL_BUSINESS : VAL_PRIVATE));
        } else {
            dispatch(setCustomerType((e.target.value)));
        }
    }

    const listenResize = () => {
        if (window.innerHeight < 900) {
            setCustomerType(VAL_PRIVATE);
        }
    };

    useEffect(() => {
        window.addEventListener('resize', listenResize);
        return (() => {
            window.removeEventListener('resize', listenResize);
        });
    }, []);

    useEffect(() => {
        if (customerType) {
            dispatch(initOrder(customerType));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerType])


    const styleSelected = {
        color: _device === DEVICE_ACC ? 'black' : _properties.appPrimaryColor,
    };

    const styleUnselected = {
        color: _device === DEVICE_ACC ? 'black' : _properties.appPrimaryColor,
    };

    const getStyle = (is, ct) => {
        return is === ct ? styleSelected : styleUnselected;
    };

    const RadioAcc = () => {
        return (
            <FormControl>
                <FormLabel component="legend" id="formCustomerType">{t("accessibility.customertype.formlabel")}</FormLabel>
                <RadioGroup
                    aria-labelledby='formCustomerType'
                    value={customerType}
                    name="groupCustomerType"
                    onChange={(e) => changed(e)}
                >
                    <FormControlLabel value={VAL_PRIVATE} control={<Radio />} label={t("accessibility.customertype.private")} />
                    <FormControlLabel value={VAL_BUSINESS} control={<Radio />} label={t("accessibility.customertype.business")} />
                </RadioGroup>
            </FormControl>
        );
    }



    return (
        // <Box width="100%" sx={{ alignItems: 'flex-end', justifyContent: 'center' }} display={{ xs: 'none', md: 'flex' }}>
        <>
            {(_device !== DEVICE_ACC) && (<Stack
                direction={(_device === 'desktop' || _device === DEVICE_ACC) ? "row" : "column"}
                spacing={5}
                alignItems={(_device === 'desktop' || _device === DEVICE_ACC) ? "center" : "left"}
                sx={_device === 'desktop' ? {} : { width: '90%' }}
            >
                <Typography fontSize={_device === DEVICE_ACC ? 20 : { xs: 14, lg: 14 }} sx={getStyle('private', customerType)}>{(_device === 'desktop' || _device === DEVICE_ACC) ? t('main.private') : t('main.m.customertype')}</Typography>
                <Box sx={{ display: 'none' }}><label htmlFor="customerType" >{(_device === 'desktop' || _device === DEVICE_ACC) ? t('main.private') : t('main.m.customertype')}</label></Box>
                <Switch id="customerType" name="switchCustomerType" onChange={(e) => changed(e)} checked={(_device === 'desktop' || _device === DEVICE_ACC) ? customerTypeChecked : !customerTypeChecked} />
                {_device === 'desktop' && (<Typography fontSize={{ xs: 12, lg: 14 }} sx={getStyle('business', customerType)}>{t('main.business')}</Typography>)}
                {_device === DEVICE_ACC && (<Typography fontSize={20} sx={getStyle('business', customerType)}>{t('main.business')}</Typography>)}
            </Stack>)}
            {_device === DEVICE_ACC && (<RadioAcc />)}
        </>
        // </Box>
    );

}

export default CustomerType;
