import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Divider, Stack, Typography, RadioGroup, FormControlLabel, Radio, Snackbar, List,
    ListItem, Backdrop, CircularProgress, FormControl
} from '@mui/material';
import AppHeader from '../../components/AppHeader';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import RegistrationPaging from '../components/RegistrationPaging';
import { MessageBox } from '../../components/ModalDialog';
import {
    bpResponse, device, paymentTypes, bpForModify,
    isGuestAuthenticated, preferredBankAccount, isWSS,
} from '../../redux/Selectors.js';
import {
    setBpForModify, saveBusinessPartner, getAuthentication, resetBpResponse, setPaymentMethod,
    setAlternativeIban, setAlternativeBic, setUseAlternativeIban, setBankAccount
} from '../../redux/Actions';
import { RadioButtonCheckedIcon, RadioButtonUncheckedIcon } from '../../common/widgetTools';
import { CreateBankAccountSmall } from '../../components/customer/BankAccount';
import { DEVICE_ACC } from '../../common/navigationTools.js';
/**
 * @returns 
 */
export const RegisterPayment = (props) => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const location = useLocation();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _bpForModify = useSelector((state) => bpForModify(state));
    const _paymentTypes = useSelector((state) => paymentTypes(state));
    const _preferredBankAccount = useSelector((state) => preferredBankAccount(state));
    const _bpResponse = useSelector((state) => bpResponse(state));
    const _device = useSelector((state) => device(state));
    const _isGuestAuthenticated = useSelector((state) => isGuestAuthenticated(state));
    const _isWSS = useSelector((state) => isWSS(state));

    const [preferredPaymentType, setPreferredPaymentType] = useState({});
    const [bankaccount, setBankaccount] = useState(_preferredBankAccount);
    const [isBankaccountValid, setIsBankaccountValid] = useState(false);
    const [key, setKey] = useState('');
    const [valid, setValid] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [saveMsg, setSaveMsg] = useState('');
    const [isNewBp, setIsNewBp] = useState(true);
    const [saveChangesInOrder, setSaveChangesInOrder] = useState(false);
    const [errorMessageOpen, setErrorMessageOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [errorText, setErrorText] = useState();

    const refBankAccount = useRef();

    const initialize = () => {
        const pt = paymentTypeFromBP(_bpForModify) || _paymentTypes[0];
        setPreferredPaymentType(pt);
        setKey(pt._key);
        setIsNewBp(Boolean(!_bpForModify?.businessPartnerNo));
        // Das ist doch schon falsch, weil im BP steht der shortname!
        // setPayMethod(_bpForModify?.commercialProps[0]?.preferredPaymentMethod ? _bpForModify?.commercialProps[0].preferredPaymentMethod : _paymentTypes[0]._key);
        refBankAccount?.current?.setIsReadOnly(saveChangesInOrder || (pt.ibanPayment && _device === DEVICE_ACC));
    }

    useEffect(() => {
        initialize();
        window.scrollBy({ left: 0, top: -window.innerHeight * 2, behavior: 'smooth' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        console.log(location.state);
        setSaveChangesInOrder(location?.state?.saveChangesInOrder === true ? true : false);
    }, [location]);

    useEffect(() => {
        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_bpForModify]);

    // useEffect(() => {
    //     console.log("set isReadOnly to " + saveChangesInOrder || (payMethod && !findPaymentType(payMethod)?.ibanPayment && _device === DEVICE_ACC))
    //     refBankAccount?.current?.setIsReadOnly(saveChangesInOrder || (payMethod !== undefined && payMethod !== '' && !findPaymentType(payMethod)?.ibanPayment && _device === DEVICE_ACC));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [payMethod])


    // ????? payMethod ist ein String ?!
    // useEffect(() => {
    //     setValid(!findPaymentType(payMethod)?.ibanPayment || isBankaccountValid);
    //     refBankAccount?.current?.setIsReadOnly(saveChangesInOrder || (payMethod && !findPaymentType(payMethod)?.ibanPayment && _device === DEVICE_ACC));
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [payMethod?.ibanPayment, isBankaccountValid]);

    const handleChanged = (e) => {
        let pt = findPaymentType(e.target.value);
        if (saveChangesInOrder === true && pt.ibanPayment === true && (bankaccount?.iban === undefined || bankaccount?.iban === "" || bankaccount?.iban === null)) {
            setSaveChangesInOrder(false);
        }
        setPreferredPaymentType(pt);
        setKey(pt._key);
        dispatch(setPaymentMethod(pt.shortname));
    };

    useEffect(() => {
        if (_bpResponse) {
            if (_bpResponse.success) {
                setSaveMsg(isNewBp ? t(_isGuestAuthenticated ? 'm.customerdata.guest.success' : 'm.customerdata.success') : t('m.customerdata.change.success'));
                setSnackbarOpen(true);
            } else {
                if (_bpResponse.errors && _bpResponse.errors[0] && _bpResponse.errors[0].text && _bpResponse.errors[0].text !== "") {
                    setErrorMessage(null);
                    setErrorText(_bpResponse.errors[0].text);
                    setErrorMessageOpen(true);
                }
            }
            setBackdropOpen(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_bpResponse]);

    const closeSnackbarHandler = () => {
        setSnackbarOpen(false);
        if (_bpResponse.success) {
            navigate(isNewBp || _isGuestAuthenticated ? -3 : -1);
            dispatch(getAuthentication());
        }
        dispatch(resetBpResponse());
    }

    const back = () => {
        if (!isWSS) {
            let tmpBpForModify = JSON.parse(JSON.stringify(_bpForModify));
            tmpBpForModify.commercialProps[0].preferredPaymentMethod = preferredPaymentType.shortname;
            if (preferredPaymentType.ibanPayment) {
                tmpBpForModify.bankAccounts.push(bankaccount);
            }
            dispatch(setBpForModify(tmpBpForModify));
        }
        navigate(-1);
    };

    const next = () => {

        let tmpBpForModify = JSON.parse(JSON.stringify(_bpForModify));

        if (preferredPaymentType._key === "paymentDirectDebit" && !bankaccount?.iban) {
            setErrorMessage('order.accountRequired');
            setErrorText(null);
            setErrorMessageOpen(true);
            setBackdropOpen(false);
            return;
        }

        if (valid) {
            if (saveChangesInOrder === true) {
                if (preferredPaymentType.ibanPayment) {
                    console.log(bankaccount);
                    dispatch(setBankAccount(bankaccount));
                }
                navigate(-1);
            } else if (!_isWSS || _isGuestAuthenticated) {
                setBackdropOpen(true);
                tmpBpForModify.commercialProps[0].preferredPaymentMethod = preferredPaymentType.shortname;
                if (preferredPaymentType.ibanPayment) {
                    tmpBpForModify.bankAccounts.push(bankaccount);
                }
                if (tmpBpForModify.bankAccounts.length === 0) {
                    let ba = { "iban": undefined, "preferred": false };
                    tmpBpForModify.bankAccounts.push(ba);
                }
                console.log(tmpBpForModify);
                dispatch(setBpForModify(tmpBpForModify));
                console.log("save: " + JSON.stringify(tmpBpForModify));
                if (isNewBp) {
                    dispatch(saveBusinessPartner(tmpBpForModify));
                } else {
                    dispatch(saveBusinessPartner(tmpBpForModify, tmpBpForModify.businessPartnerNo));
                }
            } else {
                console.log("take alternative payment method ...");
                // WSS -> save as alternative iban in order
                if (preferredPaymentType.ibanPayment) {
                    console.log("save as alternative iban " + JSON.stringify(bankaccount));
                    dispatch(setUseAlternativeIban(true));
                    dispatch(setAlternativeIban(bankaccount.iban));
                    dispatch(setAlternativeBic(bankaccount.swift));
                    bankaccount._id = 9999;
                    bankaccount.preferred = true;
                    tmpBpForModify.bankAccounts.forEach(ba => {
                        ba.preferred = false;
                    });
                    tmpBpForModify.bankAccounts.push(bankaccount);
                    if (tmpBpForModify.bankAccounts.length === 0) {
                        let ba = { "iban": undefined, "preferred": false };
                        tmpBpForModify.bankAccounts.push(ba);
                    }
                    console.log(tmpBpForModify);
                    dispatch(setBpForModify(tmpBpForModify));
                }
                dispatch(setBankAccount(bankaccount));
                navigate(-1);
            }
        } else {
            //          setShowError(true);
        }

        //       navigate('/webstore/app/m/order'); */
    };

    const findPaymentType = key => _paymentTypes.find(e => e._key === key);

    const paymentTypeFromBP = bp => {
        const shortname = bp?.commercialProps[0]?.preferredPaymentMethod;
        return shortname ? _paymentTypes.find(e => e.shortname === shortname) : undefined;
    }
    const primaryColor = _device === DEVICE_ACC ? "#000000" : 'primary.main';

    const secondaryColor = _device === DEVICE_ACC ? "#FFFFFF" : "secondary.main"

    const fontColor = _device !== DEVICE_ACC ? "" : ""

    return (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack id="maincontent" sx={{ xwidth: '90%', minHeight: windowHeight - 150 }} direction="column" alignItems="left" spacing={5}>
                <RegistrationPaging step={isNewBp ? 3 : 0} pagetitle='m.register.payment.title' />
                <Divider />
                <Typography variant={_device === 'mobile' ? "mobilePageSubTitle" : "pageTitleBold"}>
                    {_device === 'mobile' ? (_isWSS ? t('customer.m.home.payment.alternative.title') : t('customer.m.home.payment.title')) : ''}
                </Typography>
                <FormControl>
                    <RadioGroup
                        alignitems="center"
                        sx={{
                            width: '100%',
                        }}
                        onChange={handleChanged}
                        value={key}
                    >
                        <List

                        >
                            {_paymentTypes.map(it => {
                                return (
                                    <React.Fragment key={it._key}>
                                        <ListItem
                                            variant="outlined"
                                            key={it._key}
                                            sx={{
                                                backgroundColor: it._key === key ? primaryColor : secondaryColor,
                                                borderRadius: '20px',
                                                padding: '0px',
                                                m: 4,
                                                pt: 1,
                                                width: '80vw',
                                                border: _device === DEVICE_ACC ? "solid" : "",
                                            }}
                                        >

                                            <FormControlLabel
                                                control={<Radio
                                                    overlay="true"
                                                    value={it._key}

                                                    checkedIcon={<RadioButtonCheckedIcon />}
                                                    icon={<RadioButtonUncheckedIcon />}
                                                    sx={{
                                                        ml: 4,
                                                        fontSize: 12,
                                                        '&.Mui-checked': {
                                                            color: _device === DEVICE_ACC && it._key !== key ? '#000000' : '#FFFFFF'
                                                        }
                                                    }}
                                                />
                                                }
                                                label={<Typography sx={{ whiteSpace: 'nowrap', color: _device === DEVICE_ACC && it._key !== key ? '#000000' : '#FFFFFF' }} variant='paymentButtonLabels' >{it.name}</Typography>}
                                                sx={{ width: '80vw', fontSize: 12 }}
                                            />

                                        </ListItem>
                                        {((it._key === key || _device === DEVICE_ACC) && findPaymentType(it._key)?.ibanPayment) && (
                                            <CreateBankAccountSmall baccount={bankaccount} valid={setIsBankaccountValid} paymethod={key} ref={refBankAccount} readOnly={saveChangesInOrder || (it._key !== key && _device === DEVICE_ACC)} />
                                        )}
                                    </React.Fragment>

                                )
                            })}
                        </List>
                    </RadioGroup >
                </FormControl>
            </Stack>
            <NavigationBar
                back={{ clicked: back, label: isNewBp ? t('m.register.payment.back') : (isWSS ? 'customerdata.back' : 'm.register.back') }}
                next={{ clicked: next, label: isNewBp || _isGuestAuthenticated ? t(_isGuestAuthenticated ? 'm.register.payment.guest.save' : 'm.register.payment.save') : (_isWSS || saveChangesInOrder ? 'm.register.take' : 'm.register.save') }}>
            </NavigationBar>
            <InfoText />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={_device !== DEVICE_ACC ? 3000 : null}
                onClose={() => {
                    // setSnackbarOpen(false);
                    closeSnackbarHandler();
                }}
                message={saveMsg}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={() => {
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <MessageBox open={errorMessageOpen}
                handleClose={() => setErrorMessageOpen(false)}
                message={errorMessage}
                severity="error"
                messageText={errorText}
                title={'common.error.title'}>
            </MessageBox>

        </Stack>
    );
}

export default RegisterPayment;