import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Stack, Button, Dialog, IconButton, Paper, Typography } from '@mui/material';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import {
    print2,
    resetPrintData,
} from '../../redux/Actions';
import {
    fullOrder,
    orderPrintUrl,
    orderToEdit,
    properties
} from '../../redux/Selectors';
import { PrintIcon } from '../../common/widgetTools';

export const OrderConfirmationDialog = ({ open, handleClose, order, userName }) => {
    const dispatch = useDispatch();
    const _fullOrder = useSelector((state) => fullOrder(state));
    const _orderPrintUrl = useSelector((state) => orderPrintUrl(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state));
    const _properties = useSelector((state) => properties(state));
    const msg = _orderToEdit ? 'order.motifSave.success' : 'order.success';
    const { t } = useTranslation();

    // ----------------------------------------
    // order printing
    // ----------------------------------------  
    const printHandler = () => {
        dispatch(print2(_fullOrder));
    };

    return (order && userName && (
        <Dialog fullScreen open={open} role="alertdialog" aria-modal="true" aria-labelledby="dialog_label_confirm" aria-describedby="dialog_desc_confirm">
            <Paper sx={{ minHeight: '100vh', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }} >
                <h1><Typography
                    id="dialog_label_confirm"
                    sx={{ margin: '5vh', fontSize: 36, fontWeight: 'bold', textAlign: 'center' }}>{t('order.success.title')}</Typography></h1>
                <Typography
                    id="dialog_desc_confirm"
                    sx={{ textAlign: 'center' }}
                    dangerouslySetInnerHTML={{ __html: t(msg, { customerName: userName, orderCode: order.orderCode }) }}></Typography>
                <Stack direction="column" spacing={10} sx={{ marginTop: '5vh' }}>
                    <IconButton
                        onClick={printHandler}
                        aria-label={t("accessibility.order.confirmation.print.label")}
                        aria-description={t("accessibility.order.confirmation.print.description")}>
                        <PrintIcon sx={{ color: _properties.appPrimaryColor }} />
                    </IconButton>
                    <Button sx={{ textTransform: 'none' }} variant="navigationBack" onClick={handleClose}>{t('order.newAd')}</Button>
                </Stack>
            </Paper >
        </Dialog >
    )) || (<></>);
}
