import React from 'react';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Box, Divider, Stack, Snackbar, Backdrop, CircularProgress
} from '@mui/material';
import AppHeader from '../../components/AppHeader';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import RegistrationPaging from '../components/RegistrationPaging';
import { Contact } from '../../components/customer/Contact';
import { Account } from '../../components/customer/Account'
import {
    bpForModify, bpResponse, isWSS, isSsoAuthentication, device
} from '../../redux/Selectors.js';
import { setBpForModify, saveBusinessPartner, resetBpResponse } from '../../redux/Actions';
import { DEVICE_ACC, getPage } from '../../common/navigationTools.js';

/**
 * @returns 
 */
export const RegisterContact = () => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const refContact = useRef(null);
    const refAccount = useRef(null);

    const _bpForModify = useSelector((state) => bpForModify(state));
    const _bpResponse = useSelector((state) => bpResponse(state));
    const _isWSS = useSelector(state => isWSS(state));
    const _isSsoAuthentication = useSelector(state => isSsoAuthentication(state));
    const _isGuestAuthenticated = useSelector
    const _device = useSelector(state => device(state));

    const [teleComms, setTeleComms] = useState([]);
    const [netAddress, setNetAddress] = useState();
    const [credentials, setCredentials] = useState();
    const [valid, setValid] = useState(true);
    const [showError, setShowError] = useState(false);
    const [isContactValid, setIsContactValid] = useState(false);
    const [isAccountValid, setIsAccountValid] = useState(false);
    const [isNewBp, setIsNewBp] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [saveMsg, setSaveMsg] = useState('');

    useEffect(() => {
        console.log(_bpForModify);
        console.log("useEffect: setTeleComms " + JSON.stringify(_bpForModify?.legalEntity?.teleComms));
        setTeleComms(_bpForModify?.legalEntity?.teleComms);
        let netAddr = _bpForModify?.legalEntity?.netAddresses[0];
        console.log("useEffect: setNetAddress " + JSON.stringify(netAddr));
        setNetAddress(netAddr);
        console.log("useEffect: setCredentials " + JSON.stringify(_bpForModify?.legalEntity?.credentials));
        setCredentials(_bpForModify?.legalEntity?.credentials);
        if (!credentials && netAddr?.address) {
            let cred = { "name": netAddr?.address };
            console.log("useEffect[]: setCredentials " + JSON.stringify(cred));
            setCredentials(cred);
        }
        setIsNewBp(!Boolean(_bpForModify.businessPartnerNo));

        window.scrollBy({ left: 0, top: -window.innerHeight * 2, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        console.log(_bpForModify);
        console.log("useEffect[_bpForModify]: setTeleComms " + JSON.stringify(_bpForModify?.legalEntity?.teleComms));
        setTeleComms(_bpForModify?.legalEntity?.teleComms);
        let netAddr = _bpForModify?.legalEntity?.netAddresses[0];
        console.log("useEffect[_bpForModify]: setNetAddress " + JSON.stringify(netAddr));
        setNetAddress(netAddr);
        console.log("useEffect[_bpForModify]: setCredentials " + JSON.stringify(_bpForModify?.legalEntity?.credentials));
        setCredentials(_bpForModify?.legalEntity?.credentials);
        if (!credentials && netAddr?.address) {
            let cred = { "name": netAddr?.address };
            console.log("useEffect[_bpForModify]: setCredentials " + JSON.stringify(cred));
            setCredentials(cred);
        }
        setIsNewBp(!Boolean(_bpForModify.businessPartnerNo));
    }, [_bpForModify]);

    useEffect(() => {
        setValid(isContactValid && isAccountValid);
    }, [isContactValid, isAccountValid]);

    useEffect(() => {
        if (netAddress && credentials?.name) {
            netAddress.address = credentials?.name;
        }
    }, [credentials?.name])

    useEffect(() => {
        if (_bpResponse) {
            if (_bpResponse.success) {
                setSaveMsg(t('m.customerdata.change.success'));
            } else {
                if (_bpResponse.errors && _bpResponse.errors[0] && _bpResponse.errors[0].text && _bpResponse.errors[0].text !== "") {
                    setSaveMsg(_bpResponse.errors[0].text);
                }
            }
            setSnackbarOpen(true);
            setBackdropOpen(false);
        }
    }, [_bpResponse]);

    const closeSnackbarHandler = () => {
        setSnackbarOpen(false);
        if (_bpResponse.success) {
            navigate(-1);
        }
        dispatch(resetBpResponse());
    }

    const back = () => {
        if (!_isWSS || _isGuestAuthenticated) {
            let tmpBpForModify = JSON.parse(JSON.stringify(_bpForModify));
            //       tmpBpForModify.legalEntity.mailAddresses.push(mailAddress);
            tmpBpForModify.legalEntity.netAddresses[0] = netAddress;
            tmpBpForModify.legalEntity.teleComms = teleComms;
            dispatch(setBpForModify(tmpBpForModify));
        }
        navigate(-1);
    };

    const next = () => {
        console.log(netAddress);
        console.log(teleComms);
        console.log(credentials);
        if (valid) {
            let tmpBpForModify = JSON.parse(JSON.stringify(_bpForModify));
            //           tmpBpForModify.legalEntity.mailAddresses.push(mailAddress);
            tmpBpForModify.legalEntity.netAddresses[0] = netAddress;
            tmpBpForModify.legalEntity.teleComms = teleComms;
            tmpBpForModify.legalEntity.credentials = credentials;
            dispatch(setBpForModify(tmpBpForModify));
            if (isNewBp || _isGuestAuthenticated) {
                console.log("navigate to register-payment: " + JSON.stringify(tmpBpForModify));
                navigate(getPage('register-payment', _device));
            } else {
                setBackdropOpen(true);
                console.log("save: " + JSON.stringify(tmpBpForModify));
                dispatch(saveBusinessPartner(tmpBpForModify, tmpBpForModify.businessPartnerNo));
            }
        } else {
            setShowError(true);
            refAccount.current.showErrors();
            refContact.current.showErrors();
        }

    };


    return (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack sx={{ width: _device === DEVICE_ACC ? '90%' : 'inherit', minHeight: windowHeight - 167 }} direction="column" alignItems="left" spacing={2}>
                <RegistrationPaging step={isNewBp ? 2 : 0} pagetitle='m.register.contact.title' />
                <Divider />
                {teleComms && (
                    <Stack direction="column" sx={{ margin: '1em' }} spacing={5}>
                        <Contact
                            ref={refContact}
                            _teleComms={teleComms}
                            _netAddress={netAddress}
                            bpNew={isNewBp}
                            valid={setIsContactValid}>
                        </Contact>
                        {!_isSsoAuthentication ? (<Account
                            credentials={credentials}
                            valid={setIsAccountValid}
                            bpNew={isNewBp}
                            ref={refAccount}>
                        </Account>) : (<></>)}
                    </Stack>
                )}
            </Stack>
            <NavigationBar
                back={{ clicked: back, label: isNewBp ? t('m.register.contact.back') : t('m.register.back') }}
                next={!_isWSS || _isGuestAuthenticated ? { clicked: next, label: isNewBp || _isGuestAuthenticated ? t('m.register.contact.next') : t('m.register.save') } : undefined}>
            </NavigationBar>
            <InfoText />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={_device !== DEVICE_ACC ? 3000 : null}
                onClose={() => {
                    // setSnackbarOpen(false);
                    closeSnackbarHandler();
                }}
                message={saveMsg}
            />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={() => {
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack >
    );
}

export default RegisterContact;