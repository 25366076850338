import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, Box, Card, Typography, CardContent, Grid, Divider, IconButton } from '@mui/material';
import { DownloadIcon } from '../../common/widgetTools';
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/grid';
import AppHeader from '../../components/AppHeader';
import RegistrationPaging from '../../m/components/RegistrationPaging';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import { ServicePackages as ServicePackagesAcc } from "../../components/customer/ServicePackages"
import { properties, bpForModify } from '../../redux/Selectors';



export const ServicePackages = () => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _bpForModify = useSelector((state) => bpForModify(state));
    const _properties = useSelector(state => properties(state));

    const [servicePackages, setServicePackages] = useState(_bpForModify?.servicePackages);

    useEffect(() => {
        setServicePackages(_bpForModify.servicePackages);
    }, [_bpForModify.servicePackages]);


    const startDownload = e => {
        e.stopPropagation();
        const id = e.target.download;
        const checkDownload = (id, count) => {
            if (document.cookie.indexOf("download=" + id) !== -1 || count >= 20) {
                //            showBusyIndicator(false);
            }
            else {
                setTimeout(() => checkDownload(id, count + 1), 500);
            }
        }
        //    showBusyIndicator(true);
        document.cookie = "download=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        checkDownload(id, 0);
    };



    const DownloadButton = ({ servicePackage }) => {
        const fileName = `${servicePackage?.shortName}.pdf`;
        return <IconButton
            target="_blank"
            rel="noreferrer"
            download={fileName}
            onClick={startDownload}
            sx={{ color: _properties.appPrimaryColor }}
            href={servicePackage?._links.download.href}><DownloadIcon size='lg' /></IconButton>;
    }


    return (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack sx={{ minHeight: windowHeight - 150 }} width='100%' spacing={4}>
                <RegistrationPaging step={0} pagetitle='accessibility.customer.servicepackages.title' />

                <Divider />

                <Stack width='100%' alignItems='center'>
                    <Box width='90%' >
                        <ServicePackagesAcc variant="a11y" servicePackages={servicePackages} />
                    </Box>
                </Stack>
            </Stack>

            <NavigationBar
                back={{ clicked: () => navigate(-1), label: t('m.customer.servicepackages.back') }}>
            </NavigationBar>
            <InfoText />
        </Stack >
    );
}

export default ServicePackages;