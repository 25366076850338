import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';
import { editionPartsHierarchy, selectedEditionPart, editionParts } from '../../redux/Selectors';
import { useSelector } from 'react-redux';
import { RecursiveEditionPartSelect, RecursiveEditionPartSummary, RecursiveEditionPartSummaryOneLevel } from './RecursiveEditionPartSelect';
import Grid from '@mui/material/Grid';
import { visuallyHidden } from "@mui/utils";

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const EditionPartHierarchie = (props) => {
    const { t } = useTranslation();

    const _editionPartsHierarchy = useSelector((state) => editionPartsHierarchy(state));

    const root = _editionPartsHierarchy ? { editionParts: JSON.parse(JSON.stringify(_editionPartsHierarchy)), name: 'root' } : { editionParts: [], name: 'root' };

    console.log("variant = " + props.variant);

    return (
        <Box>
            <Typography id="datepicker-label" variant="mobilePageLabelBold"> {t("accessibility.editionParts.label")}    </Typography>

            <Box sx={visuallyHidden} tabIndex={0}>{t("accessibility.editionParts.description")}</Box>
            <Grid container>
                <RecursiveEditionPartSelect
                    editionPartsHierarchy={root}
                    level={root?.editionParts[0]?.level - 1}
                    styleSelect={props.styleSelect}
                    variant={props.variant}
                    labelLevel={1}
                />
            </Grid>
        </Box>
    );

}

export const MemoizedEditionPartHierarchie = React.memo(EditionPartHierarchie);

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const EditionPartHierarchieSummary = (props) => {
    const { t } = useTranslation();

    const _editionPartsHierarchy = useSelector((state) => editionPartsHierarchy(state));

    const root = _editionPartsHierarchy ? { editionParts: JSON.parse(JSON.stringify(_editionPartsHierarchy)), name: 'root' } : { editionParts: [], name: 'root' };



    return (
        <Box>
            {!props.variant && (<Grid container>
                <RecursiveEditionPartSummary
                    editionPartsHierarchy={root}
                    level={root?.editionParts[0]?.level - 1}
                    variant={props.variant}
                    labelLevel={1}
                />
            </Grid>)}
            {props.variant === "oneLevel" && (<RecursiveEditionPartSummaryOneLevel />)}
            {props.variant === "linkToSchedulePage" && (<RecursiveEditionPartSummaryOneLevel variant={props.variant} />)}
        </Box>
    );

}