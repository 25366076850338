import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/system';
import { Button } from '@mui/material';


const HiddenButton = (props) => {

    const { t } = useTranslation();
    const location = useLocation();
    let path = location.pathname.split("/");

    const getBoxStyle = () => {
        return ({
            position: "absolute",
            top: 10,
            width: "1px",
            height: "1px",
            transform: "translate(-250px)",
            '.MuiButton-root': {
                '&:focus': {
                    width: "350px",
                    height: "40px",
                    transform: "translate(250px)",
                    color: 'black',
                    backgroundColor: "red !important",
                    border: "3px solid #000000"
                },
            }
        });
    }

    return (
        <>
            <Box
                variant="outlined"
                sx={getBoxStyle}
            >
                <Button
                    href="#maincontent"
                    tabIndex={0}
                >
                    {t('accessibility.appHeader.hiddenButton.mainContent')}
                </Button>
            </Box >
            {(path.includes("schedule") || path.includes("editions") || (path.includes("order") && !path.includes("orders"))) && <Box
                variant="outlined"
                sx={getBoxStyle}
            >
                <Button
                    href="#summary"
                    tabIndex={0}
                >
                    {t('accessibility.appHeader.hiddenButton.summary')}
                </Button>
            </Box >}
            {(path.includes("creative") || (path.includes("order") && !path.includes("orders"))) && <Box
                variant="outlined"
                sx={getBoxStyle}
            >
                <Button
                    href="#preview"
                    tabIndex={0}
                >
                    {t('accessibility.appHeader.hiddenButton.preview')}
                </Button>
            </Box>}
            {path.includes("creative") && <Box
                variant="outlined"
                sx={getBoxStyle}
            >
                <Button
                    href="#options"
                    tabIndex={0}
                >
                    {t('accessibility.appHeader.hiddenButton.options')}
                </Button>
            </Box>}           {path.includes("creative") && <Box
                variant="outlined"
                sx={getBoxStyle}
            >
                <Button
                    href="#form"
                    tabIndex={0}
                >
                    {t('accessibility.appHeader.hiddenButton.form')}
                </Button>
            </Box>}
        </>
    );

}

export default HiddenButton