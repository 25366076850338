import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
    Stack, Box, Card, Typography, CardContent, Grid, Backdrop, CircularProgress, Divider, IconButton
} from '@mui/material';
import { visuallyHidden } from "@mui/utils";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/grid';
import AppHeader from '../../components/AppHeader';
import RegistrationPaging from '../../m/components/RegistrationPaging';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import { fetchInvoices } from '../../redux/Actions';
import { invoices, properties } from '../../redux/Selectors';
import { DownloadIcon } from '../../common/widgetTools';
import { Invoices as InvoicesAcc } from "../../components/customer/Invoices"

export const Invoices = () => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const _invoices = useSelector((state) => invoices(state));
    const _properties = useSelector(state => properties(state));

    const [backdropOpen, setBackdropOpen] = useState(false);
    const [invoicesInitialized, setInvoicesInitialized] = useState(false);
    const [progressText, setProgressText] = useState("");

    useEffect(() => {
        setBackdropOpen(true);
        console.log("set progressText to " + t("accessibility.invoices.progress.description.load"));
        setProgressText(t("accessibility.invoices.progress.description.load"));
        setInvoicesInitialized(false);
        dispatch(fetchInvoices('year'));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        if (/*_invoices?.length > 0 && */ invoicesInitialized === true) {
            console.log("setBackdropOpen false");
            setBackdropOpen(false);
            setProgressText(t("accessibility.invoices.progress.description.loaded"));
        } else {
            setInvoicesInitialized(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_invoices]);

    useEffect(() => {
        console.log("set progressText to " + (backdropOpen === true ? t("accessibility.invoices.progress.description.load") : t("accessibility.invoices.progress.description.loaded")));
        setProgressText(backdropOpen === true ? t("accessibility.invoices.progress.description.load") : t("accessibility.invoices.progress.description.loaded"));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [backdropOpen]);

    const startDownload = e => {
        e.stopPropagation();
        const id = e.target.download;
        const checkDownload = (id, count) => {
            if (document.cookie.indexOf("download=" + id) !== -1 || count >= 20) {
                setBackdropOpen(false);

            }
            else {
                setTimeout(() => checkDownload(id, count + 1), 500);
            }
        }
        setBackdropOpen(true);
        document.cookie = "download=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        checkDownload(id, 0);
    };

    const DownloadButton = ({ invoice }) => {
        const fileName = `${invoice?.invoiceNumber}.pdf`;
        return <IconButton
            target="_blank"
            rel="noreferrer"
            download={fileName}
            onClick={startDownload}
            sx={{ color: _properties.appPrimaryColor }}
            href={invoice?._links.download.href}><DownloadIcon size='lg' /></IconButton>;
    }

    return (
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack sx={{ minHeight: windowHeight - 150 }} spacing={4} width='100%'>
                <RegistrationPaging step={0} pagetitle='accessibility.customer.invoices.title' />

                <Divider />

                {_invoices.length > 0 && (<Stack width='100%' alignItems='center'>
                    <Box width='90%' >
                        <InvoicesAcc showBusyIndicator={setBackdropOpen} variant="a11y" />
                    </Box>
                </Stack>)}
                {_invoices.length === 0 && (<Typography aria-live="assertive" sx={{ pl: 4 }} variant="mobilePagePrimaryLabel">{t('m.customer.invoices.empty')}</Typography>)}
            </Stack>

            <Typography sx={visuallyHidden} aria-live="assertive">{progressText}</Typography>
            <NavigationBar
                back={{ clicked: () => navigate(-1), label: t('m.customer.invoices.back') }}>
            </NavigationBar>
            <InfoText />
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={backdropOpen}
                onClick={() => {
                }}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Stack >
    );
}

export default Invoices;