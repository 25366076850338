import { DEVICE_ACC, DEVICE_MOBILE } from "./navigationTools";

export const urlParamsToState = (state) => {

	const urlParams = new URLSearchParams(window.location.search);

	let defaults = Object.assign({}, state.defaults);
	let customer = urlParams.get('customer');
	if (customer && (customer === "business" || customer === 'private')) {
		defaults.customer = customer;
	}
	else {
		defaults.customer = 'private';
	}
	let voucher = urlParams.get('voucher');
	if (voucher) {
		defaults.voucher = voucher;
	}

	let values = urlParams.getAll('editionId');
	let editionIds = values ? values.map(v => Number(v)) : null;

	let orderCode = urlParams.get('order');

	let cicBusinessPartnerNo = urlParams.get('sapgpno');
	let cicUserName = urlParams.get('sapuser');

	let deepLinks = urlParams.get('deepLinks');

	let forceLogin = urlParams.get('forceLogin');

	let mobile = urlParams.has('mobile') && 'true' === urlParams.get('mobile');

	let accessibility = urlParams.has('ac');

	let preSelectedMarketId = Number(urlParams.get('category'));

	let offerId = Number(urlParams.get('offer'));

	let templateId = Number(urlParams.get('template'));

	return {
		orgCustomerType: state.customerType,
		customerType: customer,
		defaults: defaults,
		editionIds: editionIds,
		orderCode: orderCode,
		cicBusinessPartnerNo: cicBusinessPartnerNo ? atob(cicBusinessPartnerNo) : undefined,
		cicUserName: cicUserName ? atob(cicUserName) : undefined,
		deepLinks: Boolean(deepLinks),
		forceLogin: Boolean(forceLogin),
		mobile: Boolean(mobile),
		accessibility,
		preSelectedMarketId,
		offerId,
		templateId
	};
};

export const urlParamValue = (name) => {
	const urlParams = new URLSearchParams(window.location.search);
	return urlParams.get(name);
};


/**
 * LegalEntity-Type der Domain-Objekte auf WebStore Customer-Type umsetzen
 */
export const customerTypeFromLE = (leType) => {
	if ('privatePerson' === leType) {
		return 'private';
	}
	else if ('company' === leType) {
		return 'business';
	}
	else {
		return 'business';
	}
};

/**
 * WebStore Customer-Type auf LegalEntity-Type der Domain-Objekte  umsetzen
 */
export const leTypeFromCT = (ct) => {
	if ('private' === ct) {
		return 'privatePerson';
	}
	else if ('business' === ct) {
		return 'company';
	}
};

export const privacyFromLE = (leType) => {
	if ('privatePerson' === leType) {
		return 'home';
	}
	else {
		return 'office';
	}
};

export const propertiesToState = (state, action) => {
	const properties = {};
	for (const [key, value] of Object.entries(action.json)) {
		if (isNaN(value)) {
			if ('true' === value) {
				properties[key] = true;
			}
			else if ('false' === value) {
				properties[key] = false;
			}
			else {
				properties[key] = value;
			}
		} else {
			properties[key] = Number(value);
		}
	}

	var customerType = state.customerType || properties.legalEntityTypes.split(',')[0];
	return Object.assign({}, state, {
		properties: properties,
		orgCustomerType: customerType,
		customerType: customerType
	});
};

export const isMarketValidForDevice = (market, device) => {
	let from, to,
		now = Date.now();
	if (device === 'desktop') {
		from = Date.parse(market.validFromForDesktop);
		to = Date.parse(market.validToForDesktop);
	}
	else if (device === 'mobile' || device === DEVICE_ACC) {
		from = Date.parse(market.validFromForMobile);
		to = Date.parse(market.validToForMobile);
	}
	return Boolean(from < now && to > now);
};

export const getFromTo = (period) => {
	var fmt = function (d) {
		var iso = d.toISOString();
		return iso.substring(0, 4) + "-" + iso.substring(5, 7) + "-" + iso.substring(8, 10);
	}
	var today = new Date();
	var to = fmt(today);
	switch (period) {
		case "month": today.setDate(today.getDate() - 30); break;
		case "half": today.setMonth(today.getMonth() - 6); break;
		case "year": today.setFullYear(today.getFullYear() - 1); break;
		default: today.setDate(today.getDate() - 30);
	}
	var from = fmt(today);
	return { "from": from, "to": to };
};

export const tomorrow = () => {
	let d = new Date();
	d.setDate(d.getDate() + 1);
	return d.toJSON().substring(0, 10);
};

/**
 * Formulardaten
 * Funktion zum Erstellen der motifCriterionValues anhand des $mapping-Wertes der Formularfelder
 * zur Weitergabe an das Anzeigensystem.
 */
export const mapMotifCriterionValues = (motif, formxValues) => {
	console.log(formxValues);
	if (motif && formxValues) {
		let tmpFormxValues = JSON.parse(JSON.stringify(formxValues));
		delete tmpFormxValues.changedField;
		motif.formxData = formxValues ? JSON.stringify(formxValues) : null;
		var map = form => {
			const ar = [];
			if (form.$mapping) for (const [key, value] of Object.entries(form.$mapping)) {
				if (Array.isArray(value)) {
					value.forEach(v => {
						if (v) {
							ar.push({ shortName: key, value: v.shortName });
						}
					})
				}
				else if (value) {
					ar.push({ shortName: key, value: value });
				}
			};
			if (form.$type) for (const [key, value] of Object.entries(form.$type)) {
				if (value === "Form" && form[key] && form[key].length) {
					form[key].forEach(f => {
						let arr = ar.slice();
						while (ar.length > 0) {
							ar.pop();
						}
						arr = arr.concat(map(f));
						for (let i = 0; i < arr.length; i++) {
							ar.push(arr[i]);
						}
					});
				}
			}
			console.log(ar);
			return ar;
		}
		motif.motifCriterionValues = map(formxValues);
		motif.schema = formxValues.$schema ? JSON.stringify(formxValues.$schema) : null;
	}
}

export const findKeyValue = (keys, key) => {
	return keys.find(e => e.key === key);
};

/**
 * 
 * @param {*} callback 
 * @param {*} delay 
 * @returns
 * 
 * Achtung: Funktioniert nicht bei direkter Verwendung in react-Komponenten 
 */
export const debounce = (callback, delay = 1000) => {
	var time;
	return (...args) => {
		clearTimeout(time);
		time = setTimeout(() => {
			callback(...args);
		}, delay);
	};
};

/**
 * 
 * @param {*} cw 
 */
export const cutCatchword = (cw, l) => {
	// cw = 'Anna25.â€_Juniâ€_2017 2150â€_Gramm 52â€_Zentimeter';
	let org = cw;
	if (cw && -1 !== cw.lastIndexOf(' ')) {
		while (cw.length > l) {
			let lwsp = cw.lastIndexOf(' ');
			if (-1 !== lwsp) {
				cw = cw.substring(0, lwsp);
			}
			else {
				return org.substring(0, l);
			}
		}
	}
	return cw;
}

export const getEditionPartsPath = (editionParts, lastEditionPartId) => {
	let path = [];
	let ep = editionParts.find(e => e._id === lastEditionPartId);
	if (ep) {
		path.unshift(ep);
		while (ep.parentShortName) {
			let parentShortName = ep.parentShortName;
			ep = editionParts.find(e => e.shortName === parentShortName);
			path.unshift(ep);
		}
	}
	//console.log(path);
	return path;
}

export const getEditionPartIdForLevel = (editionParts, lastEditionPartId, level) => {
	let path = getEditionPartsPath(editionParts, lastEditionPartId);
	return level < path.length ? path[level] : undefined;
}

export const newBusinessPartner = (customerType, properties, locale, selectedPaymentType, device) => {

	const privacy = customerType === "private" ? "home" : "office";
	const salutation = customerType === "private" ? '' : properties.defaultcustomerdataSalutation || '';
	const businessPartner = {
		foreignBusinessPartnerNo: '',

		legalEntity: {
			type: leTypeFromCT(customerType),
			vatNo: '',
			mailAddresses: [
				{
					salutation: salutation,
					title: '',
					forename: '',
					name: '',
					street: '',
					houseNumber: '',
					houseNumberAnnex: '',
					country: locale ? locale.toUpperCase() : "DE",
					zipCode: '',
					cityName: '',
					//	vatNo: ''
				}
			],
			teleComms: [],
			netAddresses: [{
				address: '',
				privacy: privacy
			}],
			credentials: {
				name: '',
				password: ''
			}
		},
		bankAccounts: [/*{
                iban: undefined,
                preferred: false
            }*/],
		// default payment stored in commercial props
		commercialProps: selectedPaymentType?.shortname ? [{ "preferredPaymentMethod": selectedPaymentType?.shortname }] : [{ "preferredPaymentMethod": '' }],
		marketingApproval: {
			value: false
		}
	};

	[{ type: 'mobile', teleComm: '' }].forEach(t => {
		//   if (t.teleComm && t.teleComm.subscriberCode) {
		businessPartner.legalEntity.teleComms.push({
			mobilityType: device === DEVICE_MOBILE || device === DEVICE_ACC ? 'mobile' : '',
			internationalAreaCode: '',
			areaCode: '',
			subscriberCode: '',
			privacy: privacy
		});
		//    }
	});


	//console.log("Registration - newBusinessPartner = " + JSON.stringify(businessPartner));
	return businessPartner;

}

export const parseMotifStructure = xmlMotifStructure => {

	const xmlDoc = xmlMotifStructure ? new DOMParser().parseFromString(xmlMotifStructure, "text/xml") : null;

	if (!xmlDoc) {
		return null;
	}
	else {
		const structure = {};
		// convert NodeLists to Arrays
		const userFields = Array.from(xmlDoc.getElementsByTagName("UserField"));
		const textFlows = Array.from(xmlDoc.getElementsByTagName("Textflow"));
		const namedMedias = Array.from(xmlDoc.getElementsByTagName("NamedMedia"));
		//		const medias = Array.from(xmlDoc.getElementsByTagName("Media"));
		//		const hyperLinks = Array.from(xmlDoc.getElementsByTagName("HyperLink"));
		structure.userFields = userFields.map(e => {
			return {
				name: e.getAttribute('name'),
				value: e.getAttribute('value'),
			}
		});
		structure.textFlows = textFlows.map(e => {
			return {
				dummy: e.getAttribute('dummy'),
				name: e.getAttribute('name'),
				content: e.getAttribute('content'),
				contentProtected: Boolean(e.getAttribute('contentProtected'))
			}
		});
		structure.namedMedias = namedMedias.map(e => {
			return {
				dummy: e.getAttribute('dummy'),
				name: e.getAttribute('name'),
				url: e.getAttribute('url')
			}
		});
		return structure;
	}
};

export const isMotifColourChangeable = (template) => {
	return template?.defaultColorType === null;
}

export const motifHasOptions = (template, adBoxNumberTypes) => {
	return isMotifColourChangeable(template) || (adBoxNumberTypes && template?.allowAdBox) ||
		(template?.unitWidth === 'COLUMNS' && template?.minWidth !== template?.maxWidth);
}