import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    adBoxTextFlows,
    assetPreviewUrl,
    designAlternatives,
    device,
    formxUserFieldValues,
    onlineId,
    pendingOrder,
    properties,
    selectedTemplate,
    selectUpsellingImages,
    services,
    upsellingColumns,
    upsellingTextflows,
} from '../../redux/Selectors.js';
import { setTemplate } from '../../redux/Actions.js';
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation } from "swiper";
import Skeleton from '@mui/material/Skeleton';
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
    Box,
    Card,
    CardContent,
    Typography
} from '@mui/material';
import AreaCardHeader from '../common/AreaCardHeader.jsx';
import { render } from '../../common/ServerRenderer';
import { useDebounce } from '../../hooks/Hooks';
import axios from 'axios';

// https://www.mediaevent.de/javascript/intersection-observer.html


export const DesignAlternative = ({ item, upselling, onItemSelected, onRendered, pageDimension }) => {

    const { t } = useTranslation();

    const dispatch = useDispatch();

    const [mmToPx, setMmToPx] = useState(2);
    const [pending, setPending] = useState(false);
    const [rendition, setRendition] = useState(null);
    const [creativeParams, setCreativeParams] = useState(null);
    const [price, setPrice] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const imageRef = useRef(null);
    const observerRef = useRef(null);

    // https://stackoverflow.com/questions/53949393/cant-perform-a-react-state-update-on-an-unmounted-component
    const isMounted = React.useRef(true);

    const _adBoxTextflows = useSelector((state) => adBoxTextFlows(state));
    const _assetPreviewUrl = useSelector((state) => assetPreviewUrl(state));
    const _device = useSelector((state) => device(state));
    const _formxUserFieldValues = useSelector((state) => formxUserFieldValues(state));
    const _onlineId = useSelector((state) => onlineId(state));
    const _pendingOrder = useSelector((state) => pendingOrder(state));
    const _properties = useSelector((state) => properties(state));
    const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    const _services = useSelector((state) => services(state));
    const _upsellingColumns = useSelector((state) => upsellingColumns(state));
    const _upsellingImages = useSelector((state) => selectUpsellingImages(state));
    const _upsellingTextflows = useSelector((state) => upsellingTextflows(state));

    useEffect(() => {
        setRendition(item._links.thumbnail.href);
        return (() => {
            isMounted.current = false;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        observerRef.current = new IntersectionObserver((e, o) => {
            if (observerRef.current) {
                e.forEach(it => {
                    setIsVisible(it.isIntersecting);
                })
            }
        });
        let imageR = imageRef.current;
        observerRef.current.observe(imageRef.current);
        return (() => {
            if (imageR) observerRef.current.disconnect(imageR);
        });

    }, [isVisible]);

    useEffect(() => {
        if (_device) {
            setMmToPx('desktop' === _device ? 2 : 1);
        }
    }, [_device]);

    const refresh = useDebounce(() => {
        setPending(true);
        // Motiv rendern 

        // Bilder 
        let imageFields = [];
        if (_upsellingImages) {
            Object.keys(_upsellingImages).forEach(key => {
                imageFields.push('&setNamedMediaUrl' + key + '=' + encodeURIComponent(_upsellingImages[key]));
            });
        }

        const renderOptions = {};
        let w = item.__width;
        if (w && pageDimension) {
            renderOptions.widthInMM = w * pageDimension.columnWidth + ((w - 1) * pageDimension.columnGap);
        }

        render(
            item,
            {
                formxUserFieldValues: _formxUserFieldValues,
                textFlows: _upsellingTextflows,
                adBoxFields: _adBoxTextflows,
                onlineId: _onlineId,
                imageFields
            },
            _properties,
            null,
            renderOptions)
            .then(r => {
                if (isMounted.current) {
                    setPrice(null);
                    setRendition(r.preview);
                    setCreativeParams(r.creativeParams);
                    // Preis berechnen
                    const data = Object.assign({}, _pendingOrder);
                    data.templateId = item._id;
                    if (onRendered) {
                        onRendered(r.creativeParams);
                    }
                    if (data.motif) {
                        data.motif.columnCount = 0;
                        data.motif.creativeParams = r.creativeParams;
                        data.motif.creativeParamsString = JSON.stringify(r.creativeParams);
                        data.motif.height = r.creativeParams["doc-height-1000mm"];
                        data.motif.lineCount = r.creativeParams["row-count"];
                        data.motif.nominalHeight = r.creativeParams["doc-nominal-height-1000mm"];
                        data.motif.nominalWidth = r.creativeParams["doc-width-1000mm"];
                        data.motif.width = r.creativeParams["doc-width-1000mm"];
                        data.motif.wordCount = r.creativeParams["word-count"];
                    }
                    axios({
                        method: 'post',
                        url: _services.calculateTemplatePrice.href,
                        data: data,
                        'headers': {
                            'content-type': 'application/json'
                        }
                    }).then((p) => {
                        if (isMounted.current) {
                            setPending(false);
                            if (p.status === 200 && p.data?.success) {
                                setPrice(t('global.currency.' + p.data.value.currency, { value: p.data.value.price }));
                            }
                        }
                    }, (err) => {
                        setPending(false);
                        console.log(err);
                    }
                    );
                }
            }, () => {
                setPending(false);
            });

    }, 500);

    /**
     * 
     */
    useEffect(() => {
        if (isVisible && (_upsellingTextflows || _formxUserFieldValues || _upsellingColumns || _upsellingImages)) {
            refresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isVisible, _upsellingTextflows, _formxUserFieldValues, _upsellingColumns, _upsellingImages]);

    // useEffect(() => {
    //     console.log("useEffect _upsellingTextflows .... " + isVisible);
    //     if (_upsellingTextflows && isVisible) {
    //         refresh();
    //     }
    // }, [_upsellingTextflows]);

    // useEffect(() => {
    //     console.log("useEffect _formxUserFieldValues .... " + isVisible);
    //     if (_formxUserFieldValues) {
    //         refresh();
    //     }
    // }, [_formxUserFieldValues]);

    // useEffect(() => {
    //     console.log("useEffect _upsellingColumns .... " + isVisible);
    //     if (_upsellingColumns) {
    //         refresh();
    //     }
    // }, [_upsellingColumns]);

    // useEffect(() => {
    //     console.log("useEffect isVisible .... " + isVisible);
    //     if (isVisible && _upsellingTextflows) {
    //         refresh();
    //     }
    // }, [isVisible]);

    const UpsellingTag = () => {
        return (
            price ?
                <Box sx={{ position: 'absolute', top: 1, left: iw() }}>
                    <Typography sx={{
                        backgroundColor: _properties.appPrimaryColor,
                        color: 'white',
                        fontSize: 14,
                        fontWeight: 'bold',
                        m: 2,
                        p: 2,
                        borderRadius: '10px',
                        textAlign: 'center'
                    }}>{price}</Typography>
                </Box>
                :
                <></>);
    };

    const AlternativesTag = () => {
        return (
            price ?
                <Typography sx={{ fontSize: 14, fontWeight: 'bold', ml: 0, pl: 0 }}>{price}</Typography>
                :
                <Skeleton variant="text" sx={{ fontSize: 14 }} />
        );

    };

    const imageWidth = () => {
        if (creativeParams) {
            let w = Math.floor(Number(creativeParams['doc-width-1000mm']) / 1000);
            w *= mmToPx;
            return `${w}px`;
        }
        else {
            let iw = item.__width ? item.__width : item.minWidth;
            let w = pageDimension.columnWidth * iw + (pageDimension.columnGap * (iw - 1));
            w *= mmToPx;
            return `${w}px`;
        }
    };

    const iw = () => {
        if (creativeParams) {
            let h = Math.floor(Number(creativeParams['doc-height-1000mm']) / 1000);
            let w = Math.floor(Number(creativeParams['doc-width-1000mm']) / 1000);
            if ('mobile' === _device || h < 20) {
                w *= mmToPx;
                return w + 2;
            }
            else {
                return 1;
            }
        }
    };

    return (
        upselling ?
            // Upselling
            (<Box
                id={`upselling-alternative-${item._id}`}
                ref={imageRef}
                sx={{ position: 'relative' }}
                onClick={(e) => { onItemSelected(e, item) }}
            >
                <img
                    alt={item.name}
                    id={`alternative-preview-${item._id}`}
                    width={imageWidth(item)}
                    //                    width='100%'
                    //width={width}
                    height='auto'
                    src={_selectedTemplate._id !== item._id ? rendition : _assetPreviewUrl}>
                    </img>
                <UpsellingTag></UpsellingTag>
            </Box>)
            :
            // Alternatives
            (<Box sx={{ opacity: pending ? 0.5 : 1 }}>
                <figure>
                    <img
                        ref={imageRef}
                        alt={item.name}
                        id={`alternative-preview-${item._id}`}
                        onClick={(e) => {
                            dispatch(setTemplate(item._id, null, true));
                        }}
                        style={{ cursor: "pointer", height: '80px', maxWidth: '12vw' }}
                        src={rendition}></img>
                    <AlternativesTag></AlternativesTag>
                </figure>
            </Box>)
    );

};


/**
 * 
 * @param {*} props FormControlLabel
 * @returns 
 */
const AlternativesPicker = forwardRef((props, ref) => {
    const { t } = useTranslation();

    const _designAlternatives = useSelector((state) => designAlternatives(state));
    const _properties = useSelector((state) => properties(state));

    const [slidesPerView, setSlidesPerView] = useState(3);

    const [expanded, setExpanded] = useState(true);

    useImperativeHandle(ref, () => ({ setExpanded }));

    useEffect(() => {
        var maxSlides = 3;
        if (props.width) {
            if (props.width < 800) {
                maxSlides = 1;
            } else if (props.width < 1200) {
                maxSlides = 2;
            }
        }
        setSlidesPerView(maxSlides);
    }, [props.width]);

    const getSwiperStyle = () => {
        return {
            "--swiper-pagination-color": _properties.appPrimaryColor,
            "--swiper-navigation-color": _properties.appPrimaryColor,
            "--swiper-navigation-size": "25px"
        }
    }

    return (
        <>
            <Card sx={{ height: '100%' }}>
                <AreaCardHeader title={t('creative.alternativesCardTitle')} fullCard={expanded} handleExpand={props.handleExpand} />

                <CardContent>
                    <Swiper
                        slidesPerView={slidesPerView}
                        spaceBetween={1}
                        keyboard={{
                            enabled: true,
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={true}
                        modules={[Keyboard, Pagination, Navigation]}
                        className="mySwiper"
                        style={getSwiperStyle()}
                    >
                        {_designAlternatives &&
                            _designAlternatives.map((item, i) => {
                                return (
                                    <SwiperSlide
                                        key={item._id}>
                                        <DesignAlternative item={item}></DesignAlternative>
                                    </SwiperSlide>)
                            }
                            )}
                    </Swiper>
                </CardContent>
            </Card>
        </>
    );
});

export default AlternativesPicker;
