import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { adBoxNumberTypes, businessPartner, orderToEdit, orderToRepeat, device } from '../redux/Selectors.js';
import { getAdBoxNumber, setAdBoxNumber } from '../redux/Actions.js';
import store from '../redux/Store'
import { useTranslation } from 'react-i18next';

import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    TextField,
    Typography
} from '@mui/material';
import { DEVICE_ACC } from '../common/navigationTools.js';

export const AdBoxOptions = ({ open = false, handleClose }) => {

    const { t } = useTranslation();

    const [type, setType] = useState();
    const [index, setIndex] = useState(0);
    const [email, setEmail] = useState();
    const [emailError, setEmailError] = useState(false);
    const [branchOffice, setBranchOffice] = useState();

    const _adBoxNumberTypes = useSelector((state) => adBoxNumberTypes(state));
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state));
    const _orderToRepeat = useSelector((state) => orderToRepeat(state));
    const _device = useSelector((state) => device(state));

    useEffect(() => {
        // choose one of them as default value
        if (_adBoxNumberTypes) {
            if (_adBoxNumberTypes.AD_BOX_NUMBER_TYPE_SEND) {
                setType('AD_BOX_NUMBER_TYPE_SEND')
            }
            else if (_adBoxNumberTypes.AD_BOX_NUMBER_TYPE_PICKUP) {
                setType('AD_BOX_NUMBER_TYPE_PICKUP')
            }
            else if (_adBoxNumberTypes.AD_BOX_NUMBER_TYPE_EMAIL) {
                setType('AD_BOX_NUMBER_TYPE_EMAIL')
            }
            else if (_adBoxNumberTypes.AD_BOX_NUMBER_TYPE_WEB) {
                setType('AD_BOX_NUMBER_TYPE_WEB')
            }
            else if (_adBoxNumberTypes.AD_BOX_NUMBER_TYPE_ANSWERPHONE) {
                setType('AD_BOX_NUMBER_TYPE_ANSWERPHONE')
            }
        }
    }, [_adBoxNumberTypes]);

    useEffect(() => {
        if (_businessPartner) {
            if (_orderToEdit && _orderToEdit?.adBoxNumber?.type === 'AD_BOX_NUMBER_TYPE_EMAIL' && _orderToEdit.adBoxNumber.email) {
                console.log("use email from _orderToEdit " + _orderToEdit.adBoxNumber.email);
                setEmail(_orderToEdit.adBoxNumber.email);
            } else if (_orderToRepeat && _orderToRepeat?.adBoxNumber?.type === 'AD_BOX_NUMBER_TYPE_EMAIL' && _orderToRepeat.adBoxNumber.email) {
                console.log("use email from _orderToRepeat " + _orderToRepeat.adBoxNumber.email);
                setEmail(_orderToRepeat.adBoxNumber.email);
            } else {
                console.log("use email from _businessPartner " + _businessPartner.legalEntity.netAddresses[0].address);
                setEmail(_businessPartner.legalEntity.netAddresses[0].address);
            }
        }
    }, [_businessPartner, _orderToEdit, _orderToRepeat]);

    const buttonSize = () => {
        return _device === DEVICE_ACC ? "medium" : "small";
    }

    const handleCancel = () => {
        handleClose();
    };

    const handleOk = () => {
        if ('AD_BOX_NUMBER_TYPE_EMAIL' === type) {
            if (!email) {
                setEmailError(true);
                return;
            }
        }
        var abt = JSON.parse(JSON.stringify(_adBoxNumberTypes[type][type === 'AD_BOX_NUMBER_TYPE_PICKUP' ? index : 0]));
        abt.email = email;
        handleClose(abt);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        handleOk();
    };

    const handleTypeChanged = (e) => {
        setType(e.target.value);
        setBranchOffice(_adBoxNumberTypes[e.target.value][e.target.value === 'AD_BOX_NUMBER_TYPE_PICKUP' ? index : 0].branchOfficeCode);
    };

    const handleBranchOfficeChange = (e) => {
        if (e.target.value) {
            setBranchOffice(e.target.value);
            setIndex(_adBoxNumberTypes[type].findIndex(it => it.branchOfficeCode === e.target.value));
        }
    };

    if (!_adBoxNumberTypes) {
        return null;
    }

    return (
        <Dialog
            aria-modal="true"
            PaperProps={{
                sx: {
                    m: 0,
                    position: 'fixed',
                    top: 200,
                    maxWidth: '90vw'
                }
            }}
            maxWidth="lg"
            open={open}
            onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle >
                    <h3><Typography variant="dialogTitle">{t('adboxOptions.title')}</Typography></h3>
                </DialogTitle>
                <DialogContent>
                    <Box alignItems="left" sx={{ display: 'flex', flexDirection: 'column' }}>
                        <FormControl>
                            <FormLabel xsx={{ fontSize: 18 }} id="types-label"> {t('adboxOptions.contact')}</FormLabel>
                            <RadioGroup
                                sx={{ mt: 10 }}
                                aria-labelledby="types-label"
                                value={type}
                                onChange={handleTypeChanged}
                                name="radio-buttons-group"
                            >
                                <Box alignItems="left" sx={{
                                    display: 'flex', flexDirection: _device === 'mobile' ? 'column' : 'row', justifyContent: 'space-evenly'
                                }}>
                                    {_adBoxNumberTypes.AD_BOX_NUMBER_TYPE_SEND && (
                                        < FormControlLabel
                                            value='AD_BOX_NUMBER_TYPE_SEND'
                                            control={< Radio size={buttonSize()} inputProps={{ autofocus: true, 'aria-description': t('accessibility.creative.options.adbox.send.description') }} />}
                                            label={t('adboxOptions.send')} />
                                    )}
                                    {_adBoxNumberTypes.AD_BOX_NUMBER_TYPE_PICKUP && (
                                        < FormControlLabel
                                            value='AD_BOX_NUMBER_TYPE_PICKUP'
                                            control={< Radio size={buttonSize()} inputProps={{ 'aria-description': t('accessibility.creative.options.adbox.pickup.description') }} />}
                                            label={t('adboxOptions.pickup')} />
                                    )}
                                    {_adBoxNumberTypes.AD_BOX_NUMBER_TYPE_EMAIL && (
                                        < FormControlLabel
                                            value='AD_BOX_NUMBER_TYPE_EMAIL'
                                            control={< Radio size={buttonSize()} inputProps={{ 'aria-description': t('accessibility.creative.options.adbox.email.description') }} />}
                                            label={t('adboxOptions.email')} />
                                    )}
                                    {_adBoxNumberTypes.AD_BOX_NUMBER_TYPE_WEB && (
                                        < FormControlLabel
                                            value='AD_BOX_NUMBER_TYPE_WEB'
                                            control={< Radio size={buttonSize()} inputProps={{ 'aria-description': t('accessibility.creative.options.adbox.web.description') }} />}
                                            label={t('adboxOptions.web')} />
                                    )}
                                    {_adBoxNumberTypes.AD_BOX_NUMBER_TYPE_ANSWERPHONE && (
                                        < FormControlLabel
                                            value='AD_BOX_NUMBER_TYPE_ANSWERPHONE'
                                            control={< Radio size={buttonSize()} inputProps={{ 'aria-description': t('accessibility.creative.options.adbox.answerphone.description') }} />}
                                            label={t('adboxOptions.answerphone')} />
                                    )}
                                </Box>
                            </RadioGroup>
                        </FormControl>
                        {type === 'AD_BOX_NUMBER_TYPE_EMAIL' && (
                            <FormControl sx={{ m: 10 }}>
                                <DialogContentText id="email-description">
                                    {t('adboxOptions.email.description')}
                                </DialogContentText>
                                <TextField
                                    autoFocus
                                    inputProps={{ 'aria-describedby': 'email-description' }}
                                    s margin="dense"
                                    id="name"
                                    label={t('adboxOptions.email.title')}
                                    type="email"
                                    fullWidth
                                    variant="standard"
                                    required
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                    error={emailError}
                                />
                            </FormControl>
                        )}
                        {type === 'AD_BOX_NUMBER_TYPE_PICKUP' && (
                            <FormControl sx={{ m: 10 }}>
                                <DialogContentText id="branchoffice-label">
                                    {t('adboxOptions.branchOffice')}
                                </DialogContentText>
                                <Select
                                    variant="standard"
                                    inputProps={{ 'aria-label': t('adboxOptions.branchOffice') }}
                                    id="select-branchOffice"
                                    value={branchOffice}
                                    required
                                    onChange={handleBranchOfficeChange}>
                                    {
                                        _adBoxNumberTypes['AD_BOX_NUMBER_TYPE_PICKUP'].filter(e => e.branchOfficeCode).map(e => {
                                            return (
                                                <MenuItem key={e.ranchOfficeCode} value={e.branchOfficeCode}>{e.branchOfficeCode}</MenuItem>
                                            );
                                        })
                                    }
                                </Select>
                            </FormControl>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" onClick={handleCancel}>{t('main.cancel')}</Button>
                    <Button variant="contained" type="submit" >{t('main.ok')}</Button>
                </DialogActions>
            </form>
        </Dialog >
    );
};

export const confirmAdbox = (adBoxNumberType) => {
    store.dispatch(getAdBoxNumber(adBoxNumberType));
    //    this.dispatch(this.actions.trackEvent('Order', 'click', 'CONFIRM_ADBOX'));
};

export const dismissAdbox = () => {
    store.dispatch(setAdBoxNumber());
    //    this.dispatch(this.actions.trackEvent('Order', 'click', 'DISMISS_ADBOX'));
};