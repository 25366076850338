import React from 'react';
import { useRef } from 'react';
import { Box, CssBaseline } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { useSelector } from 'react-redux';
import { device, imprintUrl, properties } from '../redux/Selectors.js';
import { appTheme, appAccessibilityTheme } from '../AppTheme';
import { DEVICE_ACC } from '../common/navigationTools.js';

//var __html = require('./InfoText.html');
//var infoHtmlText = { __html: __html };
var infoHtmlText = { __html: "<div>Das ist der InfoText ...</div>" };

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const InfoText = (props) => {
    const _device = useSelector((state) => device(state));
    const _imprintUrl = useSelector((state) => imprintUrl(state));
    const _properties = useSelector((state) => properties(state));
    const theme = createTheme(_device === DEVICE_ACC ? appAccessibilityTheme(_properties) : appTheme(_properties));
    const frame = useRef(null);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box
                sx={{
                    overflowX: "hidden",
                    width: '100%',
                    minHeight: 10,
                    //                    backgroundColor: 'primary.main',
                    color: '#fff'
                }}
            // dangerouslySetInnerHTML={infoHtmlText}
            >
                <iframe title="info" style={{
                    height: 'mobile' === _device ? "130vh" : "30vh",
                    left: 0,
                    top: 0,
                    width: "100vw"
                }} src={_imprintUrl}></iframe>
            </Box>
        </ThemeProvider >
    );
}

export default InfoText;