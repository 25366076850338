import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import i18n from 'i18next';
import { languages, loadMessages } from '../configuration/i18n';
import { features } from '../redux/Selectors.js';
import {
    isAccessibilityMode,
    activeProfiles,
    busy,
    deepLink,
    device,
    helpUrl,
    isCreateDeeplinks,
    language,
    properties,
    versionInfo,
} from '../redux/Selectors.js';
import {
    AppBar,
    Alert,
    AlertTitle,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    IconButton,
    LinearProgress,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    MenuList,
    Snackbar,
    Stack,
    Toolbar,
    Tooltip,
    Typography
} from '@mui/material';
import { visuallyHidden } from "@mui/utils";
import { HelpDialog } from '../components/order/HelpDialog';
import { AccessibilityIcon, DeepLinkIcon, HelpIcon, InfoIcon, SettingsIcon } from '../common/widgetTools';

import ReactCountryFlag from "react-country-flag";

import Check from '@mui/icons-material/Check';
import { useTranslation } from 'react-i18next';
import '../configuration/i18n';
import AppLogo from './AppLogo';
import AuthenticationStatus from './AuthenticationStatus.js';
import { DEVICE_ACC, DEVICE_DESKTOP, DEVICE_MOBILE } from '../common/navigationTools.js';
import HiddenButton from '../a/components/HiddenButton.jsx';

const AppHeader = () => {

    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const _isAccessibilityMode = useSelector(state => isAccessibilityMode(state));
    const _activeProfiles = useSelector(state => activeProfiles(state));
    const _busy = useSelector(state => busy(state));
    const _deepLink = useSelector(state => deepLink(state));
    const _device = useSelector(state => device(state));
    const _helpUrl = useSelector(state => helpUrl(state));
    const _isCreateDeepLinks = useSelector(state => isCreateDeeplinks(state));
    const _language = useSelector(state => language(state));
    const _properties = useSelector(state => properties(state));
    const _versionInfo = useSelector(state => versionInfo(state));
    const _features = useSelector(state => features(state));

    const [helpDialogOpen, setHelpDialogOpen] = React.useState(false);

    /* Language menu */
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const isMenuOpen = Boolean(menuAnchorEl);

    /* Mobile menu */
    const [mobileMenuAnchorEl, setMobileMenuAnchorEl] = React.useState(null);
    const isMobileMenuOpen = Boolean(mobileMenuAnchorEl);

    const handleHelpOpen = () => {
        const newWindow = window.open(_helpUrl, '_blank', 'noopener, noreferrer');
        if (newWindow) {
            newWindow.opener = null;
        }
    };

    const handleMenuOpen = () => {
        setMenuAnchorEl(document.getElementById(id));
    };

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };

    const handleAccessible = () => {
        setAccessibilityOpen(true);
    }

    const handleMobileMenuClose = () => {
        setMobileMenuAnchorEl(null);
    };

    const setLanguage = language => {
        loadMessages(language);
        handleMenuClose();
        handleMobileMenuClose();
        document.documentElement.setAttribute("lang", language);
    };

    useEffect(() => {
        if (_language) {
            // dispatch(fetchMarkets());
            // dispatch(fetchTemplates());
        }
    }, [_language]);

    /**
     * Accessibility: Kontextbezogener Seitentitel und Beschreibung
     */
    const [pageDescription, setPageDescription] = React.useState("");
    useEffect(() => {
        let ps = location.pathname.split('/');
        let p = ps[ps.length - 1];
        let key = `app.pages.${p}.title`;
        let value = t(key);
        document.title = value !== key ? t('app.title') + ' - ' + value : t('app.title');

        let keyDesc = `accessibility.appHeader.${p}.description`;
        let valueDesc = t(keyDesc);
        setPageDescription(valueDesc !== keyDesc ? valueDesc : t('app.title'));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------------
    // accessibility dialog
    // --------------------------------------------------
    const [accessibilityOpen, setAccessibilityOpen] = React.useState(false);
    const AccessibilityDialog = () => {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={'large'}
                open={accessibilityOpen}
                onClose={() => { setAccessibilityOpen(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <DialogTitle>{t('accessibility.title')}</DialogTitle>
                    <DialogContentText id="alert-dialog-description">
                        <Stack alignItems="center" spacing={5}>
                            <Typography variant="text">{t('accessibility.description')}</Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'none' }} aria-label={t('status.login')} variant="text" onClick={() => {
                        const url = `${window.location.protocol}//${window.location.host}/webstore/app/index.html?mobile=true&ac`;
                        window.location = url;
                        setAccessibilityOpen(false);
                    }}>OK</Button>
                    <Button sx={{ textTransform: 'none' }} aria-label={t("status.choose")} variant="text" onClick={() => { setAccessibilityOpen(false) }}>{t('main.cancel')}</Button>
                </DialogActions>
            </Dialog >
        )
    };

    // --------------------------------------------------
    // info dialog
    // --------------------------------------------------
    const [infoOpen, setInfoOpen] = React.useState(false);
    const InfoDialog = () => {
        return (
            <Dialog
                fullWidth={true}
                maxWidth={'sm'}
                open={infoOpen}
                onClose={() => { setInfoOpen(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogContent>
                    <Stack alignItems="center" spacing={5}>
                        <AppLogo />
                        <div style={{ backgroundImage: "url(../public/webstore-splash.png)" }}></div>
                    </Stack>
                    <DialogTitle></DialogTitle>
                    <DialogContentText id="alert-dialog-description">
                        <Stack alignItems="center" spacing={5}>
                            <Typography variant="text">Powered by alfa WebStore Plus</Typography>
                            <Typography variant="text">Version: {_versionInfo.version} </Typography>
                            <Typography variant="text">&#xA9; alfa Media Partner GmbH &#xB7; {new Date().getFullYear()} </Typography>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button sx={{ textTransform: 'none' }} variant="text" onClick={() => { setInfoOpen(false) }}>OK</Button>
                </DialogActions>
            </Dialog >
        )
    };

    // --------------------------------------------------
    // deep links
    // --------------------------------------------------

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [deepLinkMessage, setDeepLinkMessage] = useState('');
    const handleDeepLink = () => {
        setMenuAnchorEl(null);
        try {
            navigator.clipboard.writeText(_deepLink).then(() => {
                setDeepLinkMessage(t('main.directLink.success', { link: _deepLink }));
                setSnackbarOpen(true);
            }).catch(e => {
                setAlertOpen(true);
            });
        }
        catch (e) {
            console.log(e.message, _deepLink);
            setDeepLinkMessage(t('main.directLink.error'));
            setAlertOpen(true);
        }
    };

    const [alertOpen, setAlertOpen] = React.useState(false);

    const AlertDialog = () => {
        return (
            <Dialog
                open={alertOpen}
                onClose={() => { setAlertOpen(false) }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <Alert severity="error">
                    <AlertTitle>{t('main.error.title')}</AlertTitle>
                    {deepLinkMessage}
                </Alert>
            </Dialog >
        )
    };

    const menuId = 'language-menu';
    const LanguageMenu = () => {
        return (
            <Menu
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                id={menuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMenuOpen}
                onClose={handleMenuClose}>
                <MenuList dense >
                    {
                        languages.map(e => {
                            // if (!location.pathname.includes('/webstore/app/m/markets' || !location.pathname.includes('/webstore/app/templates'))) {
                            //     return <></>
                            // }
                            if (e.key !== i18n.language) {
                                return (
                                    <MenuItem
                                        key={e.key}>
                                        <ListItemText
                                            // styling über theme greift in chrome nicht ?? !!
                                            primaryTypographyProps={{ fontSize: '14px' }}
                                            variant="menu" inset onClick={() => { setLanguage(e.key); }} color="inherit">
                                            {e.name}
                                        </ListItemText>
                                    </MenuItem>);
                            }
                            else {
                                return (
                                    <MenuItem key={e.key}>
                                        <ListItemIcon><Check /></ListItemIcon>
                                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{e.name}</ListItemText>
                                    </MenuItem>);
                            }
                        })
                    }
                    {location.pathname.includes('templates') && (<Divider />)}
                    <MenuItem onClick={handleHelpOpen} key={'Help'}>
                        <ListItemIcon><HelpIcon /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('admin.icons.helpIcon')}</ListItemText>
                    </MenuItem>
                    <MenuItem onClick={() => { setInfoOpen(true); setMenuAnchorEl(null) }} key={'Info'}>
                        <ListItemIcon><InfoIcon /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('admin.settings.about')}</ListItemText>
                    </MenuItem>
                    {_isCreateDeepLinks && (
                        <MenuItem onClick={() => { handleDeepLink() }}>
                            <ListItemIcon><DeepLinkIcon /></ListItemIcon>
                            <ListItemText primaryTypographyProps={{ fontSize: '14px' }}>{t('main.directLink.title')}</ListItemText>
                            {_device === 'desktop' && (<ListItemText primaryTypographyProps={{ fontSize: '14px' }}>: {_deepLink}</ListItemText>)}
                        </MenuItem>)}

                </MenuList>
            </Menu >
        )
    };

    const mobileMenuId = 'mobile-menu';
    const MobileMenu = () => {
        return (
            <Menu
                anchorEl={mobileMenuAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                id={mobileMenuId}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={isMobileMenuOpen}
                onClose={handleMobileMenuClose}>
                <MenuList dense>
                    {
                        languages.map(e => {
                            if (e.key !== i18n.language) {
                                return (<MenuItem key={e.key}>
                                    <ListItemText sx={{ color: 'red', fontSize: 14 }} inset onClick={() => { setLanguage(e.key); }} color="inherit">
                                        {e.name}
                                    </ListItemText>
                                </MenuItem>);
                            }
                            else {
                                return (<MenuItem key={e.key}>
                                    <ListItemIcon><Check /></ListItemIcon>
                                    {e.name}
                                </MenuItem>);
                            }
                        })
                    }
                    <Divider></Divider>
                    <MenuItem>
                        <Button
                            color="inherit"
                            startIcon={<LocaleIcon />}>
                            {i18n.language.toUpperCase()}
                        </Button>
                    </MenuItem>
                    <MenuItem>
                        <Button
                            onClick={handleHelpOpen}
                            color="inherit"
                            startIcon={<HelpIcon size='lg' />}>
                            {t(_device === DEVICE_ACC ? 'accessibility.appHeader.helpIcon' : 'Hilfe')}
                        </Button>
                    </MenuItem>
                </MenuList>
            </Menu>
        )
    };
    const LocaleIcon = () => {
        //       let locale = _properties.definedlocation || '';
        let locale = i18n.language || '';
        if (!locale) {
            const lang = navigator.language;
            locale = lang.length > 2 ? lang.substring(3, 5) : lang.substring(0, 2);
        }
        if (locale === "en") locale = "gb";
        return (
            <ReactCountryFlag
                alt=""
                svg
                countryCode={locale.toUpperCase()}
                style={{
                    fontSize: '1em',
                    lineHeight: '1em',
                }}>

            </ReactCountryFlag>
        )
    };

    const ProgressBar = () => {
        return _busy ? <LinearProgress variant="query" color="secondary" ></LinearProgress> : <Box sx={{ height: '4px' }}></Box>;
    };

    const contrastToolbarColor = () => {
        return _device === DEVICE_ACC ? _properties.appAccToolbarContrast : _properties.appToolbarContrast
    }

    const id = 'app-bar-id';
    const idButton = 'button-id';
    return (
        // <Box id={id} sx={{ width: "100%", flexGrow: 1 }}>

        <>
            <Box id="page-description" aria-live="assertive" sx={visuallyHidden}>{pageDescription}</Box>
            <AppBar id={id} position="static" sx={{ color: contrastToolbarColor }} aria-describedby="page-description">
                <ProgressBar></ProgressBar>
                {/* <Grid container columns={16}>
                <Grid item sm={0} md={1} lg={1} />
                <Grid item sm={16} md={14} lg={14} > */}
                <Toolbar sx={{ ml: { lg: '5%', sm: '2%' }, mr: { lg: '5%', sm: '2%' } }}>
                    {_device === DEVICE_ACC && (<HiddenButton />)}
                    <AppLogo />
                    {_isAccessibilityMode && (
                        <Box
                            tabIndex={-1}
                            color={contrastToolbarColor}
                            size="small">
                            <AccessibilityIcon />
                        </Box>
                    )}
                    <Box sx={{ flexGrow: 1 }} />
                    {/* <Box sx={{ pr: "1rem" }}>
                    <Button variant="text">Text</Button>
                    <Button variant="contained">Contained</Button>
                    <Button variant="outlined">Outlined</Button>
                    <Button disabled variant="text">Text</Button>
                    <Button disabled variant="contained">Contained</Button>
                    <Button disabled variant="outlined">Outlined</Button>
                </Box> */}
                    <Box id={idButton}  ><AuthenticationStatus menuAnchor={idButton} /></Box>
                    {(DEVICE_MOBILE === _device || _device === DEVICE_ACC) && (
                        <>
                            <IconButton
                                onClick={handleMenuOpen}
                                sx={{
                                    "&.MuiButtonBase-root": {
                                        color: contrastToolbarColor
                                    }
                                }}
                                aria-haspopup="true"
                                aria-controls="language-menu"
                                aria-label={t('accessibility.appHeader.settingsIcon')}>
                                <SettingsIcon />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                onClick={() => { setHelpDialogOpen(true) }}
                                aria-label={t('accessibility.appHeader.helpIcon')}
                            >
                                <HelpIcon />
                            </IconButton>
                        </>

                    )}
                    {DEVICE_DESKTOP === _device && (
                        <>
                            <Button color="inherit" xvariant="menu" onClick={handleMenuOpen} startIcon={<LocaleIcon />}>
                                {i18n.language.toUpperCase()}
                            </Button>
                            {_helpUrl && <IconButton
                                color="inherit"
                                onClick={handleMenuOpen}
                                size="small">
                                <SettingsIcon />
                            </IconButton>}
                            {_features.WEBSTORE_A11Y.active &&
                                <IconButton
                                    color="inherit"
                                    onClick={() => { setHelpDialogOpen(true) }}
                                >
                                    <HelpIcon />
                                </IconButton>}
                            {_features.WEBSTORE_A11Y.active &&
                                (<Tooltip title={t('accessibility.tooltip')} >
                                    <IconButton
                                        color="inherit"
                                        onClick={handleAccessible}
                                        size="small"
                                        autoFocus>
                                        <AccessibilityIcon />
                                    </IconButton>
                                </Tooltip>)}
                        </>
                    )}
                    <LanguageMenu></LanguageMenu>
                    <MobileMenu></MobileMenu>
                    <InfoDialog />
                    <AccessibilityDialog />
                </Toolbar>
                {/* </Grid>
                <Grid item sm={0} md={1} lg={1} />
            </Grid> */}
                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={_device !== DEVICE_ACC ? 10000 : null}
                    onClose={() => {
                        setSnackbarOpen(false);
                        setDeepLinkMessage('');
                        // closeHandler();
                    }}
                    message={deepLinkMessage}
                />
                <AlertDialog />
                <HelpDialog open={helpDialogOpen} handleClose={() => { setHelpDialogOpen(false) }} baseUrl={_helpUrl} location={location}></HelpDialog>

            </AppBar>
        </>
        // </Box >
    );
}

export default AppHeader