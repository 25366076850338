import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
    Box,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

const NavigationBar = ({ back, next, fixed }) => {

    const { t } = useTranslation();

    return (
        <Box sx={{
            position: fixed ? 'fixed' : undefined,
            bottom: 0,
            left: 0,
            width: '100%',
            //            height: '6vh'
            //    height: '2%', absolute Größenangabe führt zu Problemen beim Zoomen, prozentuale wirkt sich nicht aus
            xbackgroundColor: 'green'
        }}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                role="navigation"
            >
                {back && (<nav><IconButton size="large" variant="mobileNavigation" onClick={back.clicked}>
                    <FontAwesomeIcon icon={faChevronLeft} size="lg" />
                    <Typography variant='mobilePageNavigation'>{t(back.label)}</Typography>
                </IconButton></nav>)}
                <Box></Box>
                {next && (<nav><IconButton size="large" variant="mobileNavigation" onClick={next.clicked} disabled={next.disabled}>
                    <Typography variant='mobilePageNavigation'>{t(next.label)}</Typography>
                    <FontAwesomeIcon icon={faChevronRight} size="lg" />
                </IconButton></nav>)}
            </Stack>
        </Box>
    )
};

export default NavigationBar;
