import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, InputAdornment, Button, Input, InputLabel, FormControl, IconButton } from '@mui/material';
import { desiredDate, possibleDates, selectedOffer, reqProductionDates, orderPriceResponse } from '../../redux/Selectors';
import { useSelector, useDispatch } from 'react-redux';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import i18n, { localeMap, maskMap, formatMap } from '../../configuration/i18n';
import format from 'date-fns/format';
import { setSelectedDates, setDesiredDate } from '../../redux/Actions';
import { DataDivider, EditIcon, SwiperNextIcon, SwiperPrevIcon } from '../../common/widgetTools';
import { MultipleDatePicker } from '../../components/common/MultipleDatePicker';
import { getPage, DEVICE_MOBILE } from '../../common/navigationTools';
import { CustomExpandMore } from '../../common/widgetTools';


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const DesiredDate = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const _desiredDate = useSelector((state) => desiredDate(state));
    const _possibleDates = useSelector((state) => possibleDates(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const _productionDates = useSelector((state) => reqProductionDates(state));
    const _orderPriceResponse = useSelector((state) => orderPriceResponse(state));

    const [selectedValues, setSelectedValues] = useState([_possibleDates?.length > 0 ? _possibleDates[0] : _desiredDate]);
    const [value, setValue] = useState(_desiredDate ? new Date(_desiredDate) : new Date(new Date().getDate + 1));
    const [pickerOpen, setPickerOpen] = useState(false);
    const [displayDate, setDisplayDate] = useState('');
    const [displayDatesNumber, setDisplayDatesNumber] = useState(0);
    const [visibleDate, setVisibleDate] = useState(_desiredDate ? new Date(_desiredDate) : new Date(new Date().getDate + 1));

    const refDatePicker = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (_productionDates) {
            setSelectedValues(_productionDates);
        } else {
            setSelectedValues([_possibleDates?.length > 0 ? _possibleDates[0] : _desiredDate]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (_orderPriceResponse && _orderPriceResponse.value) {
            var orderPrice = _orderPriceResponse.value;
            var displayDate = orderPrice?.effectiveDates?.map(eDate => format(new Date(eDate), formatMap[i18n.language], { locale: localeMap[i18n.language] }))
                .join(DataDivider(DEVICE_MOBILE));
            //            .join(" \u2022 ");
            setDisplayDate(displayDate);
            setDisplayDatesNumber(orderPrice?.effectiveDates?.length);
        }
    }, [_orderPriceResponse]);

    useEffect(() => {
        if (_productionDates) {
            setSelectedValues(_productionDates);
        } else {
            setSelectedValues([_possibleDates?.length > 0 ? _possibleDates[0] : _desiredDate]);
        }
        if (_possibleDates?.length > 0 && Date.parse(_possibleDates[0]) > Date.parse(visibleDate)) {
            console.log("set visible date to " + _possibleDates[0]);
            setVisibleDate(_possibleDates[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_possibleDates])

    /*   function toJSONLocal(date) {
           var local = new Date(date);
           local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
           return local.toJSON().slice(0, 10);
       } */

    /*   function disableDays(date) {
           let dateString = toJSONLocal(date);
           return date <= new Date() && _possibleDates.find(d => d === dateString) === undefined ? true : false;
       } */

    const styleFormControl = () => {
        return (
            {
                width: '100%'
            });
    }

    const PickersToolbar = () => {
        return (<></>);
    }

    const PrevIcon = () => {
        return (
            <Box sx={{ mr: 4 }}>
                <SwiperPrevIcon />
            </Box>
        )
    }

    const NextIcon = () => {
        return (
            <Box sx={{}}>
                <SwiperNextIcon />
            </Box>
        )
    }

    const MobileDesiredDatePicker = () => {
        return (
            <Box>
                <FormControl sx={styleFormControl}>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={localeMap[i18n.language]}
                        locale={localeMap[i18n.language]}>
                        <MobileDatePicker
                            label={t('order.desired')}
                            inputFormat={formatMap[i18n.language]}
                            mask={maskMap[i18n.language]}
                            disablePast
                            minDate={new Date()}
                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                            value={value}
                            onChange={(newValue) => {
                                setValue(newValue);
                                dispatch(setDesiredDate(newValue));
                            }}
                            shouldDisableDate={(date) => {
                                return date <= Date.now();
                            }}
                            slots={{
                                toolbar: PickersToolbar,
                                leftArrowIcon: PrevIcon,
                                rightArrowIcon: NextIcon
                            }}

                            slotProps={{
                                textField: {

                                    variant: 'standard',
                                    style: {},
                                    InputProps: {
                                        value: value ? format(new Date(value), formatMap[i18n.language], { locale: localeMap[i18n.language] }) : '',
                                        type: 'text',
                                        style: { marginTop: '10px' },
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <CustomExpandMore />
                                            </InputAdornment>
                                        ),
                                    },
                                }, actionBar: {
                                    // The actions will be the same between desktop and mobile
                                    actions: []
                                }
                            }}

                        />
                    </LocalizationProvider>
                </FormControl>
            </Box>
        );
    }

    const MobilePublicationDatePicker = () => {
        return (
            <Box>
                <FormControl sx={styleFormControl}>


                    <MultipleDatePicker
                        ref={refDatePicker}
                        inputRef={inputRef}
                        variant="mobile"
                        openDatePicker={pickerOpen}
                        startDate={_desiredDate}
                        possibleDates={_possibleDates}
                        displayAll={!_selectedOffer.publicationCalendar}
                        selectedDates={selectedValues}
                        visibleDate={visibleDate}
                        toolbarPlaceholder={selectedValues?.length === 1 ? format(new Date(selectedValues[0]), formatMap[i18n.language], { locale: localeMap[i18n.language] }) : selectedValues?.length + ' ' + t('form.dates')}
                        label={t('order.dates')}
                        onChange={(values, selectedDate) => {
                            console.log("DesiredDate MultipleDatePicker onChange ...");
                            setSelectedValues(values);
                            dispatch(setSelectedDates(values));
                            setVisibleDate(selectedDate);
                            setPickerOpen(false);
                        }} />
                </FormControl >
            </Box>

        );
    }

    const LinkToSchedulePage = () => {
        return (<FormControl fullWidth>
            <InputLabel shrink={true} sx={{ ml: "-14px" }}>{displayDatesNumber === 1 ? t('m.order.date') : t('m.order.dates')}</InputLabel>
            <Input
                variant='standard'
                label={displayDatesNumber === 1 ? t('m.order.date') : t('m.order.dates')}
                value={displayDate}
                sx={{ width: '100%' }}
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            id="editDate"
                            onClick={() => navigate(getPage('schedule', DEVICE_MOBILE))}
                            sx={{ pr: 0 }}
                            aria-label={t("accessibility.date.edit.label")}
                            aria-description={t("accessibility.date.edit.description")}
                        >
                            <EditIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>);
    };


    if (props.variant === "mobileWithDatePicker") {
        return (<MobileDesiredDatePicker />);
    } else if (props.variant === "mobilePulicationDatePicker") {
        return (<MobilePublicationDatePicker />)
    } else if (props.variant === "linkToSchedulePage") {
        return (<LinkToSchedulePage />)
    }


}