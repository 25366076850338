import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { AdapterDateFns } from '@mui/x-date-pickers-pro/AdapterDateFns';
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { DateCalendar } from '@mui/x-date-pickers-pro';
/*import { StaticDatePicker as DatePickerWithoutInput } from '@mui/x-date-pickers/StaticDatePicker';*/
import { StaticDatePicker as DatePickerWithoutInput } from '@mui/x-date-pickers-pro';
//import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
//import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Stack } from '@mui/material';
import i18n, { localeMap, maskMap } from '../../configuration/i18n';
import { SwiperNextIcon, SwiperPrevIcon } from './DesktopIcons.jsx'
import { SelectIconComponent } from '../../common/widgetTools.js';

export const DesiredDatePicker = ({ startDate, possibleDates, displayAll, onChange, variant, monthsShown, orientation }) => {
    const [value, setValue] = useState(startDate ? new Date(startDate) : new Date(new Date().getDate + 1));

    useEffect(() => {
        console.log("useEffect possibleDates = " + possibleDates);
        if (!displayAll && possibleDates.length > 0 && new Date(value) < new Date(possibleDates[0]))
            setValue(new Date(possibleDates[0]));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [possibleDates])

    useEffect(() => {
        if (onChange) {
            onChange(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const changeSelectedDate = (value) => {
        setValue(value);
        /*     if (onChange) {
                 onChange(value);
             }*/
    }

    function toJSONLocal(date) {
        var local = new Date(date);
        local.setMinutes(local.getMinutes() - local.getTimezoneOffset());
        return local.toJSON().slice(0, 10);
    }

    function disableDays(date) {
        let dateString = toJSONLocal(date);
        return !(displayAll && date > new Date()) && possibleDates.find(d => d === dateString) === undefined ? true : false;
    }



    const PrevIcon = () => {
        return (
            <Box sx={{ mr: 4 }}>
                <SwiperPrevIcon />
            </Box>
        )
    }

    const NextIcon = () => {
        return (
            <Box sx={{}}>
                <SwiperNextIcon />
            </Box>
        )
    }




    const PickersToolbar = () => {
        return (<></>);
    }



    const StaticDatePicker = () => {
        return (
            <Box /* sx={{ "& .MuiIconButton-edgeEnd": { top: 300 }, "& .MuiIconButton-edgeStart": { top: 300 } }}*/>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    name="desiredDate"
                    adapterLocale={localeMap[i18n.language]}
                    orientation={orientation ? orientation : 'portrait'}>
                    <Stack direction='row' justifyContent="space-between">
                        <DatePickerWithoutInput
                            sx={{
                                '.MuiPickersDay-root': {
                                    fontSize: '12px',
                                    p: 10,
                                },
                                '.MuiButtonBase-root': {
                                    fontSize: '14px',
                                },
                                '.MuiBox-root': {
                                    fontSize: '20px',
                                },
                                '.MuiDayCalendar-weekDayLabel': {
                                    fontSize: '12px',
                                    p: 10,
                                },
                                '.MuiTypography-root': {
                                    fontSize: '14px',
                                },

                            }}
                            mask={maskMap[i18n.language]}
                            slots={{
                                toolbar: PickersToolbar,
                                leftArrowIcon: PrevIcon,
                                rightArrowIcon: NextIcon
                            }}

                            slotProps={{
                                actionBar: {
                                    // The actions will be the same between desktop and mobile
                                    actions: []
                                }
                            }}
                            showToolbar={false}
                            views={['day']}
                            disablePast
                            label=" "
                            value={value}
                            onChange={(newValue) => {
                                console.log("StaticDatePicker: onChange")
                                changeSelectedDate(newValue);
                            }}
                            shouldDisableDate={disableDays}
                        /*        renderInput={({ inputRef, inputProps, InputProps }) => (
                                    <></>)} */
                        />

                        {orientation === 'landscape' && (<DateCalendar
                            value={value}
                            minDate={new Date()}
                            maxDate={new Date(new Date().setFullYear(new Date().getFullYear() + 1))}
                            views={['month', 'year']}
                            openTo="month"
                            onChange={(newDate) => setValue(newDate)}
                            slots={{ switchViewIcon: SelectIconComponent }}
                        />)}
                        {monthsShown === 2 && (<DatePickerWithoutInput
                            mask={maskMap[i18n.language]}

                            slotProps={{
                                actionBar: {
                                    // The actions will be the same between desktop and mobile
                                    actions: []
                                }
                            }}
                            slots={{
                                toolbar: PickersToolbar,
                                leftArrowIcon: PrevIcon,
                                rightArrowIcon: NextIcon
                            }}
                            showToolbar={false}
                            views={['day']}
                            disablePast
                            label=" "
                            value={value}
                            onChange={(newValue) => {
                                console.log("StaticDatePicker: onChange")
                                changeSelectedDate(newValue);
                            }}
                            shouldDisableDate={disableDays}
                            renderInput={({ inputRef, inputProps, InputProps }) => (
                                <></>)}
                        />)}
                    </Stack>
                </LocalizationProvider >
            </Box >
        );
    }

    return (<StaticDatePicker />);


}