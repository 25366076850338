import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import {
    Box,
    Card,
    Grid,
    Stack,
    Tab,
    Typography
} from '@mui/material';
import {
    TabPanel,
    TabList,
    TabContext
} from '@mui/lab';
import { customerTypeRestrictedPaymentTypes as paymentTypes, preferredBankAccount } from '../../redux/Selectors.js';
import { BankAccount, CreateBankAccount } from './BankAccount';
import Fab from '@mui/material/Fab';
import { AddIcon } from '../../common/widgetTools';
import PointOfSaleSharpIcon from '@mui/icons-material/PointOfSaleSharp';

import { FaCoins, FaPaypal, FaCcMastercard, FaCcVisa, FaCcAmex, FaCcDinersClub, FaCreditCard } from "react-icons/fa";

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Payment = ({ businessPartner, commercialProps, dirty }) => {
    const { t } = useTranslation();
    const [height, setHeight] = useState();

    const [bankAccounts, setBankAccounts] = useState([]);
    const [paymentMethod, setPaymentMethod] = useState('');
    const [preferredPaymentType, setPreferredPaymentType] = useState();
    const _paymentTypes = useSelector((state) => paymentTypes(state));

    const [test, setTest] = useState(0);
    const [dialogOpen, setDialogOpen] = useState(false);

    const findPaymentType = key => {
        return _paymentTypes.find(e => e._key === key);
    }
    const findPaymentTypeByShortname = shortname => {
        return _paymentTypes.find(e => e.shortname === shortname);
    }

    const listenResize = () => {
        setHeight(window.innerHeight / 2);
    };

    useEffect(() => {
        setBankAccounts(businessPartner?.bankAccounts);
        setPaymentMethod(commercialProps?.preferredPaymentMethod ? findPaymentTypeByShortname(commercialProps?.preferredPaymentMethod)._key : _paymentTypes[0]._key);
        setPreferredPaymentType(findPaymentTypeByShortname(commercialProps?.preferredPaymentMethod));
        listenResize();
        window.addEventListener('resize', listenResize);
        return (() => {
            window.removeEventListener('resize', listenResize);
        });
    }, []);

    const handlePaymentMethodChange = (event, newValue) => {
        const pt = findPaymentType(newValue);
        setPaymentMethod(pt._key);
        commercialProps.preferredPaymentMethod = pt.shortname;
        setPreferredPaymentType(pt);
        dirty();
    };

    const icon = (key) => {
        const sx = { width: '2rem', height: '2rem' };
        const pt = findPaymentType(key);
        if ('payPal' === pt._key) {
            return (<FaPaypal style={sx} />)
        }
        else if ('DatatransECA' === pt._key) {
            return (<FaCcMastercard style={sx} />)
        }
        else if ('DatatransVIS' === pt._key) {
            return (<FaCcVisa style={sx} />)
        }
        else if ('DatatransAMX' === pt._key) {
            return (<FaCcAmex style={sx} />)
        }
        else if ('DatatransDIN' === pt._key) {
            return (<FaCcDinersClub style={sx} />)
        }
        else if (pt.epayment) {
            return (<FaCreditCard style={sx} />)
        }
        else if (pt.ibanPayment) {
            return (<FaCoins style={sx} />)
            //            return (<AccountBalanceSharpIcon sx={sx} />)
        }
        else {
            return (<PointOfSaleSharpIcon sx={sx} />)
        }

    };

    const preferredCallback = (iban) => {
        bankAccounts?.forEach(element => {
            element.preferred = false;
        });
        bankAccounts?.filter(it => it.iban === iban).forEach(element => {
            element.preferred = true;
        });
        setTest(test + 1);
    };

    const deleteCallback = (iban) => {
        //        businessPartner.bankAccounts = bankAccounts.filter(it => it.iban !== iban);
        businessPartner.bankAccounts = bankAccounts?.map(it => {
            return it.iban !== iban ? it : Object.assign({}, it, { _deleted: true });
        });
        setBankAccounts(businessPartner?.bankAccounts);
    };

    const handleAdd = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = (ba) => {
        setDialogOpen(false);
        if (ba) {
            if (!businessPartner.bankAccounts) {
                businessPartner.bankAccounts = [];
            }
            businessPartner.bankAccounts.push(ba);
            setBankAccounts(businessPartner?.bankAccounts);
        }
    };

    const BankAccounts = () => {
        return (
            <Stack
                direction="column"
                alignItems="left"
                spacing={'1em'}
            >
                <Typography variant="pageTitleBold">
                    {t('customer.payment.iban.title')} {preferredPaymentType?.name}
                </Typography>
                <Box sx={{ p: 1, height: 400, overflowY: "scroll" }}>

                    <Grid container spacing={10}>
                        {bankAccounts?.filter(e => { return Boolean(!e._deleted) })
                            .map(e =>
                                <Grid item sm={6} lg={4} key={e._id ? e._id : Math.random}>
                                    <BankAccount test={test}
                                        bankAccount={e}
                                        preferredCallback={preferredCallback}
                                        deleteCallback={deleteCallback}></BankAccount>
                                </Grid>
                            )}
                        <Grid item sm={6} lg={4} >
                            <Card elevation={4} sx={{ minHeight: "250px" }}>
                                <Stack
                                    direction="column"
                                    alignItems="center"
                                >
                                    <Fab size="large" sx={{ mt: 45 }} onClick={handleAdd}>
                                        {/* <FontAwesomeIcon icon={FaPlus} /> */}
                                        {/* <AddIcon ></AddIcon> */}
                                        <AddIcon />
                                    </Fab>
                                </Stack>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>

            </Stack >

        )
    }

    const Panel = () => {
        if (paymentMethod) {
            const pt = findPaymentType(paymentMethod);
            if (pt.ibanPayment /*&& bankAccounts */) {
                return (<BankAccounts />)
            }
            else {
                return (<></>)
            }

        }

    };

    return (
        <>
            {paymentMethod && (<Stack
                sx={{ margin: '1em' }}
                direction="column"
                alignItems="left"
                spacing={'1em'}>
                <Typography variant="pageTitleBold">
                    {t('customerdata.payment.title')}
                </Typography>
                <TabContext value={paymentMethod}>
                    <TabList
                        variant="scrollable"
                        scrollButtons="auto"
                        allowScrollButtonsMobile
                        value={paymentMethod}
                        onChange={handlePaymentMethodChange} >
                        {
                            _paymentTypes.map(e => {
                                return (
                                    <Tab
                                        key={e._key}
                                        label={e.name}
                                        value={e._key}
                                        iconPosition='top'
                                        icon={icon(e._key)} />

                                )
                            })
                        }
                    </TabList>
                    {_paymentTypes.map(e => {
                        return (
                            <TabPanel key={e._key} value={e._key}>
                                <Panel />
                            </TabPanel>
                        )
                    })}
                </TabContext>
                <CreateBankAccount
                    open={dialogOpen}
                    closeCallback={handleDialogClose}
                />
            </Stack>)}
        </>
    );
}

export const PaymentSmall = ({ commercialProps }) => {
    const { t } = useTranslation();
    const _paymentTypes = useSelector((state) => paymentTypes(state));
    const _preferredBankAccount = useSelector((state) => preferredBankAccount(state));
    const findPaymentTypeByShortname = shortname => _paymentTypes.find(e => e.shortname === shortname);

    if (!commercialProps) {
        return <></>
    }
    if (!_paymentTypes) {
        return <></>
    }

    const pt = findPaymentTypeByShortname(commercialProps[0]?.preferredPaymentMethod);

    return pt ? (
        <>
            <Grid container width='100%' >
                {pt._key !== 'paymentDirectDebit' && (
                    <>
                        <Grid item xs={4} >
                            <Typography variant="cardData">
                                {t('customerdata.home.payment.preferred')}:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="cardData">
                                {pt.name}
                            </Typography>
                        </Grid>
                    </>
                )}
                {pt._key === 'paymentDirectDebit' && (
                    <>
                        < Grid item xs={4} >
                            <Typography variant="cardData">
                                {t('customerdata.home.payment.preferred')}:
                            </Typography>
                        </Grid>
                        < Grid item xs={8} >
                            <Typography variant="cardData">
                                {_preferredBankAccount?.bankName}
                            </Typography>
                        </Grid>
                        < Grid item xs={4} >
                        </Grid>
                        < Grid item xs={8} >
                            <Typography variant="cardData">
                                {_preferredBankAccount?.ibanHidden}
                            </Typography>
                        </Grid>
                        < Grid item xs={4} >
                        </Grid>
                        < Grid item xs={8} >
                            <Typography variant="cardData">
                                {_preferredBankAccount?.swift}
                            </Typography>
                        </Grid>
                    </>
                )}

            </Grid >
        </>
    ) : <></>;
}