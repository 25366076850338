import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux'; import { Box, Stack, Typography } from '@mui/material';
import { device } from '../../redux/Selectors';
import { DEVICE_ACC } from '../../common/navigationTools';

const RegistrationPaging = ({ step, pagetitle }) => {

    const { t } = useTranslation();

    const _device = useSelector((state) => device(state));

    const subtitle = t(pagetitle);

    return (
        <Box sx={{ xheight: '4vh', width: '100%' }}>
            <Stack sx={{ width: '100%', ml: 4 }} justifyContent="left" direction="row" alignItems="center" spacing={5}>
                <Typography
                    variant={_device === DEVICE_ACC ? 'h1' : 'mobilePageSubTitle'}
                    noWrap={true}
                >{!step || step === 0 ? subtitle : t('m.register.title', { step: step, title: subtitle })}
                </Typography>
            </Stack>
        </Box >

    );
};

export default RegistrationPaging;
