import React from 'react';

import i18n from 'i18next';

import { useTranslation } from 'react-i18next';
import { useRef, forwardRef, useEffect, useState, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import MsgPopper from '../components/common/MsgPopper';
import {
    device,
    formxUri,
    formxValid,
    formxValues,
    material,
    motifUuid,
    orderToEdit,
    orderToRepeat,
    selectedMarket,
    selectedTemplate,
    services,
    styloReady,
    customerType
} from '../redux/Selectors.js';
import {
    addMaterial,
    removeMaterial,
    setFormxReady,
    setFormxValid,
    setFormxValues,
} from '../redux/Actions.js';

import axios from 'axios';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Formx = forwardRef(({ form }, ref) => {
    const ID = 'formx';

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const _device = useSelector((state) => device(state));
    const _formxUri = useSelector((state) => formxUri(state));
    const _formxValid = useSelector((state) => formxValid(state));
    const _formxValues = useSelector((state) => formxValues(state));
    const _material = useSelector((state) => material(state));
    const _motifUuid = useSelector((state) => motifUuid(state));
    const _orderToEdit = useSelector((state) => orderToEdit(state));
    const _orderToRepeat = useSelector((state) => orderToRepeat(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    const _services = useSelector((state) => services(state));
    const _styloReady = useSelector((state) => styloReady(state));
    const _customerType = useSelector(state => customerType(state));

    const [context, setContext] = useState(null);
    const [formReady, setFormReady] = useState(false);
    const [initialFormxValues, setInitialFormxValues] = useState({});
    const [msgOpen, setMsgOpen] = useState(false);
    const [msgText, setMsgText] = useState('');
    const [doCheck, setDoCheck] = useState(false);

    const formxFrame = useRef(null);
    const language = i18n.language;

    let timeoutId = useRef(null);

    const placement = "bottom";

    const style = {
        width: "100%",
        height: "100%",
        border: 'none'
    };

    const eventListener = e => {
        if (e.data.opcode) {
            let lastFormData = {};
            console.log(`opcode=${e.data.opcode}`);
            switch (e.data.opcode) {
                case "resize":
                    break;
                case "somethingChanged":
                    break;
                case "validate":
                    dispatch(setFormxValid(e.data.valid));
                    break;
                case "getForm":
                    dispatch(setFormxValues(e.data.form));
                    break;
                case "setForm":
                    break;
                case "formReady":
                    if (form) {
                        formx().postMessage({ "opcode": "setForm", "form": form }, "*");
                    }
                    setFormReady(true);
                    dispatch(setFormxReady(true));
                    break;
                case "formChanged":
                    dispatch(setFormxValues(e.data.form));
                    break;
                case "fieldChanged":
                    //console.log(`event=fieldChanged field=${JSON.stringify(e.data.field)}`);
                    let field = e.data.field;
                    // check for upload field
                    if (Array.isArray(field.new) && field.new.length > 0 && field.new[0].type) {
                        formx().postMessage({ "opcode": "uploadCallback" }, "*");
                    }
                    let param = JSON.parse("{\"changedField\" :{\"" + field.id + "\": " + JSON.stringify(field.newvalue) + "}}");
                    //               console.log("fieldChanged - lastFormData = " + JSON.stringify(lastFormData));
                    let formData = lastFormData;
                    formData.changedField = param.changedField;
                    //                    console.log(formData);
                    //                dispatch(setFormxValues(e.data.form));
                    break;
                case "removeFile":
                    if (e.data.file) {
                        let sn = _selectedTemplate.formxName + '.' + e.data.file.widget + '.' + e.data.file.name;
                        deleteAsset(sn);
                    }
                    break;
                case "uploadCallback":
                    let uploads = [];
                    e.data.uploads.forEach(u => {
                        let existing = _material ? _material.find(it => {
                            return shortName(u) === it.shortName;
                        }) : undefined;
                        if (!existing) {
                            uploads.push(u);
                        }
                        else if (!u.file.url) {
                            uploads.push(u);
                        }
                    });
                    if (uploads != null && uploads.length > 0) {
                        //                        this.images2upload = uploads.length;
                        //                        this.dispatchEvent(new CustomEvent('something-changed', { detail: { "images2upload": this.images2upload }, bubbles: true, compose: true }));
                        uploads.forEach(u => {
                            let name = shortName(u);
                            let contentData = {};
                            contentData.name = _selectedTemplate.formxName + '.' + u.widget + '.' + u.file.name;
                            contentData.name = name;
                            contentData.length = u.file.size;
                            contentData.mimetype = u.file.type;
                            contentData.type = 'material';
                            if (u.file.data) {
                                let s = u.file.data;
                                let data = s.substring(s.indexOf(',') + 1, s.length);
                                contentData.content = base64Decode(data);
                                uploadAsset(contentData);
                            } else if (u.file.url) {
                                // this.dispatch(this.actions.book.setFormxAttachmentUrl(u.file.url));									}
                            } else {											// original upload file
                                let reader = new FileReader();
                                reader.onload = (e) => {
                                    contentData.content = e.target.result;
                                    //                                console.log('contentData:', contentData);
                                    uploadAsset(contentData);
                                }
                                reader.readAsBinaryString(u.file);
                            }
                        });
                    }
                    break;
                case "galleryRequest":
                    loadComponents(e.data);
                    break;
                default:
                    break;
            }
        }
    };

    useEffect(() => {
        window.addEventListener('message', eventListener);
        return () => {
            window.removeEventListener('message', eventListener);
            clearTimeout(timeoutId.current);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (_styloReady) {
            getForm();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_styloReady]);

    useEffect(() => {
        if (_orderToRepeat && _selectedTemplate?.formxName) {
            fetchFormxValues(_orderToRepeat);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_orderToRepeat, _selectedTemplate?.formxName]);

    useEffect(() => {
        if (_orderToEdit && _selectedTemplate?.formxName) {
            fetchFormxValues(_orderToEdit);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_orderToEdit, _selectedTemplate?.formxName]);

    useEffect(() => {
        if (_selectedMarket && _selectedTemplate && _device && _customerType) {
            let context = {
                //       "webstore": _device !== 'mobile',                 wird von formx falsch ausgewertet ... WS-8338
                //     "bazaar": _device === 'mobile',
                "template": _selectedTemplate.shortName,
                "market": _selectedMarket.shortName,
                "customertype": _customerType
            };
            if (_device !== 'mobile' && _device !== "a11y") {
                context.webstore = true;
            } else {
                context.bazaar = true;
            }

            setContext(context);
        }
    }, [_selectedMarket, _selectedTemplate, _device, _customerType]);

    useEffect(() => {
        if (context && formx()) {
            //      console.log("set context " + JSON.stringify(context));
            formx().postMessage({
                "opcode": "setContext",
                "context": context
            }, "*");
        }
    }, [context, formReady]);

    useEffect(() => {
        if (formx() && initialFormxValues && formReady) {
            // ('useEffect (formx)', JSON.stringify(initialFormxValues));
            formx().postMessage({ "opcode": "setForm", "form": initialFormxValues }, "*");
            setFormReady(false);
        }
    }, [initialFormxValues, formReady]);

    useEffect(() => {
        if (_formxValues && formReady) {
            formx().postMessage({ "opcode": "setForm", "form": _formxValues }, "*");
            setFormReady(false);
        }
    }, [_formxValues, formReady]);

    useEffect(() => {
        setMsgOpen(doCheck && !_formxValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_formxValid]);

    const validateForm = async () => {
        if (formx()) {
            dispatch(setFormxValid(null));
            formx().postMessage({ "opcode": "validate" }, "*");
        }
    }

    const shortName = (u) => {
        let widget = encodeFormxPath(u.widget);
        return _selectedTemplate.formxName + '.' + widget + '.' + u.file.name;
    };

    const encodeFormxPath = (path) => {
        return path.replace(/[^A-Za-z0-9]/g, c => "_" + c.charCodeAt(0).toString(16));
    };

    const base64Decode = (str) => {
        const byteCharacters = atob(str);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        return new Uint8Array(byteNumbers);
    };

    const src = () => {
        //       return 'http://alfa-formxtest.alfa-cloud.net/forms/form/alfa_Media/HochzeitMitBild#formx?locale=de&amp;tmpbaseurl=https%3A%2F%2Falfa-wsen-demo.alfa-cloud.net%2Fwebstore%2Fservice%2Fv1%2Forder%2Fassets%2Fmaterial%2F9140001069626285';
        // return 'http://alfa-formxtest.alfa-cloud.net/forms/form/alfa_Media/HochzeitMitBild#formx?locale=de&amp;tmpbaseurl=https%3A%2F%2Falfa-wsen-demo.alfa-cloud.net%2Fwebstore%2Fservice%2Fv1%2Forder%2Fassets%2Fmaterial%2F18211513866467244';
        if (_formxUri) {
            let tmpBase = _services.materialBase.href + "/" + _motifUuid;
            let uri = _formxUri + "#" + ID + "?locale=" + language + "&tmpbaseurl=" + encodeURIComponent(tmpBase);
            let baseUrl = "";
            if (_orderToRepeat) {
                baseUrl = encodeURIComponent(_orderToRepeat._links.materialBase.href);
                uri += "&baseurl=" + baseUrl;
            }
            else if (_orderToEdit) {
                baseUrl = encodeURIComponent(_orderToEdit._links.materialBase.href);
                uri += "&baseurl=" + baseUrl;
            }
            return uri;
        }
        else {
            return 'about:blank';
        }
    };

    const uploadAsset = (contentData) => {
        axios({
            method: 'post',
            url: `/webstore/service/v1/order/assets/material/${_motifUuid}/${contentData.name}`,
            data: contentData.content,
            headers: {
                'content-type': 'application/octet-stream',
                'Accept': 'application/json'
            }
        }).then((res) => {
            let webStoreRessource = res.data;
            dispatch(addMaterial(webStoreRessource));
            let asset = makeAsset(webStoreRessource.shortName);
            formx().postMessage({ "opcode": "setUrl", "widget": asset.widget, "url": "/" + encodeURIComponent(webStoreRessource.name), "name": asset.name }, "*");
        }, (err) => {
            console.log(err);
        }
        );
    };

    const deleteAsset = (shortName) => {
        axios({
            method: 'delete',
            url: `/webstore/service/v1/order/assets/material/${_motifUuid}/${shortName}`,
            headers: { 'Accept': 'application/json' }
        }).then((res) => {
            let webStoreRessource = res.data;
            dispatch(removeMaterial(webStoreRessource));
        }, (err) => {
            console.log(err);
        }
        );
    };

    const loadComponents = (galleryRequest) => {
        axios({
            method: 'get', url: _selectedTemplate._links.components.href, headers: { 'Accept': 'application/json' }
        }).then((res) => {
            let pool = res.data.galleryPools.find(p => p.poolType === galleryRequest.type);
            let content = galleryRequest.type === "snippet" ?
                pool.logoPools.map(p => ({
                    "id": p.shortName, "name": p.name, "url": p.imageUrls[0]
                }))
                : pool.logoPools.map(p => ({
                    "id": p.shortName, "name": p.name, "files": p.imageUrls.map(u => {
                        let name = decodeURIComponent(u.substring(u.lastIndexOf("/") + 1));
                        // stylo proxy servlet is needed to handle alfa image connector urls, but does not yet support svg
                        let url = u.substring(u.lastIndexOf(".")).toLowerCase() === ".svg" ? u
                            : window.location.protocol + "//" + window.location.host + "/webstore/styloproxy/ProxyServlet?url=" + encodeURIComponent(u);
                        return { name, "title": "", "description": "", "type": name.toLowerCase().endsWith(".png") ? "image/png" : "image/jpeg", url };
                    })
                }));
            formx().postMessage({ "opcode": "galleryResolution", "widget": galleryRequest.widget, "gallery": galleryRequest.gallery, content }, "*");
        }, (err) => {
            console.log(err);
        }
        );
    };

    const fetchFormxValues = (order) => {
        if (_formxValues) {
            // for back button order page
            setInitialFormxValues(JSON.parse(JSON.stringify(_formxValues)))
        } else {
            axios({
                method: 'get', url: order._links.formxValues.href, headers: { 'Accept': 'application/json' }
            })
                .then((res) => {
                    setInitialFormxValues(res.data);
                });
        }
    }

    const isComplete = () => {
        if (!_formxValid) {
            setMsgText(t('form.validation'));
            timeoutId.current = setTimeout(() => {
                setMsgOpen(false);
                setDoCheck(false);
            }, 2000);
            setDoCheck(true);
            return false;
        }
        return true;
    };

    const setForm = data => {
        if (formx()) {
            formx().postMessage({ "opcode": "setForm", "form": data }, "*");
        }
    };

    const getForm = () => {
        if (formx()) {
            formx().postMessage({ "opcode": "getForm" }, "*");
        }
    };

    function handleMouseMove(ev) {
        if (msgOpen === true) {
            setMsgOpen(false);
        }
    }

    useImperativeHandle(ref, () => ({ isComplete, validateForm, getForm, setForm }));

    const formx = () => formxFrame?.current?.contentWindow;

    if (!_selectedTemplate || !_selectedTemplate.formxName) {
        return null;
    }

    return (
        <Box id="form" sx={{ height: '100%' }} onMouseMove={(ev) => handleMouseMove(ev)}>
            <iframe title={_selectedTemplate.formxName} ref={formxFrame} id={ID} style={style} src={src()}></iframe>
            <MsgPopper
                open={msgOpen}
                anchorRef={formxFrame}
                placement={placement}
                arrow={true}
                text={msgText}
            >
            </MsgPopper>
        </Box >
    );
});

const decodeFormxPath = (path) => {
    return path.replace(/_[a-f0-9]{2}/gi, h => String.fromCharCode(parseInt(h.substring(1), 16)));
};

export const makeAsset = (name) => {
    let f = name.substring(0, name.indexOf('.'));
    let w = name.split('.')[1];
    let n = name.substring(name.indexOf('.') + 1, name.length);
    n = n.substring(n.indexOf('.') + 1, n.length);
    return { 'form': f, 'widget': decodeFormxPath(w), 'name': n };
};


export default Formx;
