import React, { useRef, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Stack,
    Typography
} from '@mui/material';
import { device, offerPrices } from '../../redux/Selectors.js';
import { DEVICE_ACC } from '../../common/navigationTools.js';


export const OfferTooltip = ({ offer }) => {
    return (
        <React.Fragment>
            <Stack
                direction='column'
                spacing='10'
                sx={{ width: 200, m: 10 }}>
                <Typography
                    sx={{ fontSize: 14, fontWeight: 'bold', textAlign: 'left' }}>
                    {offer.name}
                </Typography>
                <Typography
                    sx={{ fontSize: 12, textAlign: 'left' }}>
                    {offer.description || ''}
                </Typography>
            </Stack>
        </React.Fragment>
    );
};

export const OfferCard = ({ offer, highlight, image, properties }) => {
    const ref = useRef(null);
    const { t } = useTranslation();

    const _device = useSelector((state) => device(state));
    const _offerPrices = useSelector((state) => offerPrices(state));

    const [priceText, setPriceText] = useState('');

    useEffect(() => {
        console.log(_offerPrices);
        const p = _offerPrices[offer._id];
        var currency = p?.currency ? p.currency : 'EUR';
        let text = p && p.price !== null ? t('global.currency.' + currency, { value: p.price }) : '';
        setPriceText(text);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_offerPrices])

    const secondaryColor = _device === DEVICE_ACC ? "#000000" : properties.appSecondaryColor;
    const color = highlight ? 'white' : secondaryColor;
    const backgroundColor = highlight ? secondaryColor : 'white';

    const getStyle = () => {
        if (_device === DEVICE_ACC)
            return ({
                padding: '0.2rem',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                backgroundColor,
                color,
                border: '1px solid',
                borderRadius: '10px',
                cursor: 'pointer',
                width: "80vw"
            });
        else
            return ({
                padding: '0.2rem',
                paddingLeft: '0.5rem',
                paddingRight: '0.5rem',
                backgroundColor,
                color,
                border: '1px solid',
                borderRadius: '10px',
                cursor: 'pointer',
                height: '48vw'
            });
    }


    return (
        properties && <Box
            sx={getStyle}
        >

            {image && (
                <Stack spacing={5} direction="column" alignItems="center" justifyContent="left">
                    <img alt={offer.name} width="90%" height="auto" src={offer._links.image.href}></img>
                    <Typography
                        variant='templatePickerTitle'
                        noWrap
                        sx={{
                            backgroundColor,
                            color,
                        }}
                    >{offer.name}
                    </Typography>
                </Stack>
            )}
            {!image && (
                <Stack ref={ref} sx={{ ml: '3rem', mr: '1rem', pt: 2 }} spacing={5} direction="column" alignItems="left" justifyContent="left">
                    <Typography
                        variant='offerCardTitle'
                        noWrap
                        sx={{
                            backgroundColor,
                            color,
                            display: 'block',
                            overflow: 'hidden',
                            textAlign: 'start'
                        }}
                    >{offer.name}
                    </Typography>
                    <Box sx={{ minHeight: _device === DEVICE_ACC ? '10vw' : '18vw' }}>
                        <Typography
                            variant='offerCardText'
                            sx={{
                                backgroundColor,
                                color,
                            }}
                        >{offer.description}
                        </Typography>
                    </Box>
                    <Typography
                        variant='offerCardPrice'
                        aria-label={t('accessibility.priceArea.priceText')}
                        sx={{
                            backgroundColor,
                            color,
                        }}
                    >{priceText}
                    </Typography>
                </Stack>
            )}


        </Box >
    );

};