import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { offers, offerPrices, selectedOffer, properties } from '../../redux/Selectors.js';
import { setOffer, setEditions } from '../../redux/Actions.js';
import AreaCardHeader from './AreaCardHeader';
import PriceArea from './PriceArea';

import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Pagination, Navigation, Grid as SwiperGrid } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/grid';
import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    Input,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Typography
} from '@mui/material';
import HtmlTooltip from '@mui/material/Tooltip';

import { t } from 'i18next';
import { OfferCard, OfferTooltip } from '../common/Offer'
import { EditIcon, SwiperPrevIcon, SwiperNextIcon } from '../../common/widgetTools.js';
import { getPage, DEVICE_MOBILE } from '../../common/navigationTools.js';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const OfferPicker = (props) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const swiperRef = useRef();
    const refs = useRef([]);

    const _offerPrices = useSelector((state) => offerPrices(state));
    const _offers = useSelector((state) => offers(state));
    const _properties = useSelector((state) => properties(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));
    const [focusedIndex, setFocusedIndex] = useState(0);

    const [height, setHeight] = useState(0);
    const [showNextButton, setShowNextButton] = useState(false);
    const [showPrevButton, setShowPrevButton] = useState(false);
    const [slidesPerView, setSlidesPerView] = useState(3);
    const [sortedOffers, setSortedOffers] = useState(_offers);


    useEffect(() => {
        var tmpOffers = [];
        if (props.displayCurrentOffer === 'true') {
            tmpOffers.push(_selectedOffer);
        }
        _offers?.forEach(e => {
            if (e._id !== _selectedOffer
                ._id) {
                tmpOffers.push(e);
            }
        });
        setSortedOffers(tmpOffers);
    }, [_offers, _selectedOffer, props.displayCurrentOffer]);

    useEffect(() => {
        setHeight(props.height ? props.height : "100%");
    }, [props.height]);

    useEffect(() => {
        var maxSlides = 3;
        if (props.width) {
            if (props.width < 800) {
                maxSlides = 1;
            } else if (props.width < 1200) {
                maxSlides = 2;
            }
        }
        //      setSlidesPerView(props.displayCurrentOffer === 'true' ? Math.min(2, _offers.length) : Math.min(maxSlides, _offers.length - 1));
        setSlidesPerView(props.direction === 'vertical' ? 1 : Math.min(maxSlides, _offers.length - 1))
    }, [props.width, _offers.length, props.direction]);

    useEffect(() => {
        console.log("useEffect swiperRef ");
        setShowPrevButton(!swiperRef?.current?.isBeginning);
        setShowNextButton(!swiperRef?.current?.isEnd);
    }, [swiperRef?.current?.isBeginning, swiperRef?.current?.isEnd])

    useEffect(() => {
        if (refs.current[focusedIndex]) {
            refs.current[focusedIndex].focus();
        }
    }, [focusedIndex]);

    if (!_offers) {
        return null;
    }

    const offerSelected = (offerId) => {
        dispatch(setOffer(offerId));
        dispatch(setEditions(null)); /* nicht wirklich schön :( */
    };

    const getStyle = (m, e) => {
        let _m = m || { _id: 999999 };
        let borderBottom;
        if ((_m && e && _m._id === e._id) || _offers.length === 1) {
            borderBottom = `solid ${_properties.appPrimaryColor} 5px`
        }
        else {
            //         transform = 'none';
        };
        return {
            color: _properties.appPrimaryColor,
            borderBottom: borderBottom
        };
    };


    const getHeaderStyle = () => {
        return {
            fontSize: '14px',
            color: `${_properties.appPrimaryColor}`,
            opacity: 1,
            display: 'flex',
            justifyContent: props.variant === 'xlarge' ? 'flex-start' : 'center',
        }
    };

    const getSwiperStyle = () => {
        return {
            "--swiper-pagination-color": _properties.appPrimaryColor,
            "--swiper-navigation-color": _properties.appPrimaryColor,
            "--swiper-navigation-size": "25px"
        }
    }

    const getXLargeSwiperStyle = () => {
        return {
            "--swiper-pagination-color": _properties.appPrimaryColor,
            "--swiper-navigation-color": _properties.appPrimaryColor,
            "--swiper-navigation-size": "28px",
            "paddingBottom": "40px",
            "paddingTop": "15px"
        }
    }

    const getCardContentStyle = () => {
        if (props.direction !== 'vertical') {
            return {
                pt: 0
            }
        } else {
            return {

            }
        }
    }

    function SlideNextButton() {
        return (
            <Button variant='swiperXL' onClick={() => doSwipe(true)}><SwiperNextIcon size='xl' /></Button>
        );
    }

    function SlidePrevButton() {
        return (
            <Button variant='swiperXL' onClick={() => doSwipe(false)}><SwiperPrevIcon size='xl' /></Button>
        );
    }

    const doSwipe = (next) => {
        if (next) {
            swiperRef?.current?.slideNext();
        } else {
            swiperRef?.current?.slidePrev();
        }
        setShowPrevButton(!swiperRef?.current?.isBeginning);
        setShowNextButton(!swiperRef?.current?.isEnd);
    }

    const OfferImageBox = ({ offer }) => {
        const p = _offerPrices[offer._id];
        var currency = p?.currency ? p.currency : 'EUR';
        let text = p ? t('global.currency.' + currency, { value: p.price }) : '';
        return (
            <HtmlTooltip
                arrow
                placement="right-start"
                title={<OfferTooltip offer={offer} />}
            >
                <figure
                    style={getStyle(_selectedOffer
                        , offer)}>

                    {offer._links.image && (<img alt={offer.name}
                        id={offer._id}
                        onClick={(e) => offerSelected(e.target.id)}
                        className='inline'
                        style={{
                            cursor: "pointer", height: props.direction === "vertical" ? (height / 6) : 80, paddingTop: 5, paddingBottom: 5, minWidth: 80
                        }}
                        src={offer._links.image.href}></img>)}
                    <Typography sx={{ fontSize: 14, fontWeight: 'bold', ml: 0, pl: 0 }}>{text}</Typography>
                </figure>
            </HtmlTooltip>);
    }

    const LinkToOfferPage = () => {
        return (<FormControl fullWidth>
            <InputLabel htmlFor="input-offer" variant="dataInputLabel" shrink="true" sx={{}}>{t('Angebot')}</InputLabel>
            <Input
                id="input-offer"
                variant='standard'
                label={t('Angebot')}
                value={_selectedOffer.name}
                sx={{ width: '100%' }}
                //              aria-disabled="true"
                endAdornment={
                    <InputAdornment position='end'>
                        <IconButton
                            onClick={() => navigate(getPage('offers', DEVICE_MOBILE))}
                            sx={{ pr: 0 }}
                            aria-label={t("accessibility.offer.edit.label")}
                            aria-description={t("accessibility.offer.edit.description")}
                        >
                            <EditIcon />
                        </IconButton>
                    </InputAdornment>
                }
            />
        </FormControl>);
    };

    const Tiny = () => {
        return (<FormControl fullWidth>
            <InputLabel size="small">{t('Angebot')}</InputLabel>
            <Select
                labelId="offers-label"
                id="offers-simple-select"
                label="Angebote"
                variant="standard"
                value={_selectedOffer._id}
                IconComponent={EditIcon}
                onChange={e => offerSelected(e.target.value)}
            >
                {_offers.map(it => {
                    return <MenuItem key={it._id} value={it._id}>{it.name}</MenuItem>
                })}

            </Select>
        </FormControl>);
    };

    const Small = () => {

        const handleKeyDown = (e) => {
            switch (e.key) {
                case 'ArrowDown':
                    setFocusedIndex((prevIndex) =>
                        prevIndex === sortedOffers.length - 1 ? 0 : prevIndex + 1
                    );
                    break;
                case 'ArrowUp':
                    setFocusedIndex((prevIndex) =>
                        prevIndex === 0 ? sortedOffers.length - 1 : prevIndex - 1
                    );
                    break;
                case 'Enter':
                case ' ':
                    offerSelected(sortedOffers[focusedIndex]._id);
                    break;
                default:
                    break;
            }
        };

        return (
            <>
                <Stack direction="column" spacing={6} role="list">
                    {sortedOffers.map((item, index) =>
                        <Box
                            role="listitem"
                            tabIndex={0}
                            onKeyDown={handleKeyDown}
                            aria-label={item.htmlText}
                            ref={(el) => (refs.current[index] = el)}

                            key={item._id}
                            onClick={(e) => offerSelected(item._id)}
                            aria-controls="selectedOffer"
                        >
                            <OfferCard offer={item} highlight={false} properties={_properties} image={false} />
                        </Box >)
                    }
                </Stack >
            </>
        );
    };

    const Large = () => {
        return (
            <Card sx={{ height: height }} >
                <AreaCardHeader title={t('creative.offerCardTitle')} onClose={props.onClose} />
                <CardContent
                    height="100%"
                    sx={getCardContentStyle}>
                    <Stack direction={props.direction === 'vertical' ? 'column' : 'row'}
                        spacing={10}
                        justifyContent="space-between"
                        alignItems="center"
                        height="100%"
                    >
                        {
                            props.displayCurrentOffer === 'true' && (<Box width={props.direction === "vertical" ? '100%' : '75%'}
                                height={height / 2 - 60}
                                sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column', mb: 2, mt: -2 }}>
                                <Typography sx={getHeaderStyle} >{t('order.titleSelectedOffer')}</Typography>
                                {sortedOffers.map((e, index) => {
                                    if (e._id === _selectedOffer._id) {
                                        if (_properties.displayOffersAsText === true) {
                                            return (
                                                <OfferTextBox key={e._id} offer={e} offerSelected={offerSelected} />
                                            );
                                        } else {
                                            return (
                                                <OfferImageBox key={e._id} offer={e} />
                                            );
                                        }
                                    }
                                    return (<></>);
                                }
                                )}
                            </Box>
                            )
                        }
                        < Box height={height / 2 - 30} sx={{ display: 'block', width: props.direction === "vertical" ? '100%' : '75%' }}>
                            {props.displayCurrentOffer === 'true' && sortedOffers.length > 1 && (<Typography sx={getHeaderStyle}>{t('order.titleOfferToSelect')}</Typography>)}
                            <Swiper
                                slidesPerView={slidesPerView}
                                spaceBetween={10}
                                keyboard={{
                                    enabled: true,
                                }}
                                pagination={{
                                    clickable: true,
                                }}
                                navigation={props.withNavigation === "true" ? true : false}
                                modules={[Keyboard, Pagination, Navigation]}
                                className="mySwiper"
                                direction={/*props.direction ? props.direction : */"horizontal"}
                                style={getSwiperStyle()}
                            >
                                {sortedOffers.map((e, index) => {
                                    if (e._id !== _selectedOffer._id) {
                                        if (_properties.displayOffersAsText === true) {
                                            return (
                                                <SwiperSlide key={e._id} width="100%">
                                                    <OfferTextBox offer={e} key={e._id} />
                                                </SwiperSlide>
                                            )
                                        }
                                        else {
                                            return (
                                                <SwiperSlide key={e._id} >
                                                    <OfferImageBox offer={e} />
                                                </SwiperSlide>
                                            )
                                        }
                                    }
                                    return (<></>);
                                }
                                )}
                            </Swiper >
                        </Box>
                        {props.doNotDisplayPrice !== "true" && (<PriceArea displayOffer={true} sx={{ pt: 1 }} />)}
                    </Stack>
                </CardContent>
            </Card >
        )
    };

    const XLarge = (rows, columns) => {
        return (
            <Card sx={{ height: height }} >
                <AreaCardHeader title={t('creative.offerCardTitle')} />
                <CardContent
                    height="100%"
                    sx={getCardContentStyle}>
                    <Box sx={{ position: 'relative' }}>
                        <Box sx={{ overflowY: "scroll" }}>
                            <Stack direction='column'
                                justifyContent="space-between"
                                sx={{ height: '100%' }}>
                                <Stack direction={'row'}
                                    spacing={10}
                                    justifyContent="space-between"
                                >
                                    {
                                        props.displayCurrentOffer === 'true' && (<Box key={1} width={_selectedOffer.editionsSelectable ? sortedOffers.length > 1 ? '25%' : '100%' : '18vw'}
                                            height={height / 2}
                                            sx={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column' }}>
                                            <Typography sx={getHeaderStyle} >{t('order.titleSelectedOffer')}</Typography>
                                            <Box sx={{ pt: 5 }} />
                                            {sortedOffers.map((e, index) => {
                                                if (e._id === _selectedOffer._id) {
                                                    if (_properties.displayOffersAsText === true) {
                                                        return (
                                                            <OfferTextBox key={e._id} offer={e} offerSelected={offerSelected} offers={_offers} properties={_properties} selectedOffer={_selectedOffer} />
                                                        );
                                                    } else {
                                                        return (
                                                            <OfferImageBox key={e._id} offer={e} />
                                                        );
                                                    }
                                                }
                                                return (<></>);
                                            }
                                            )}
                                        </Box>
                                        )
                                    }
                                    {sortedOffers.length > 1 && < Box key={2} height={2.3 * height / 3} sx={{ display: 'block', width: '65%' }}>
                                        {props.displayCurrentOffer === 'true' && (<Typography sx={getHeaderStyle}>{t('order.titleOfferToSelect')}</Typography>)}
                                        <Swiper
                                            slidesPerView={_selectedOffer.editionsSelectable ? 2 : 3}
                                            spaceBetween={10}
                                            grid={{
                                                rows: 2,
                                                fill: "row"
                                            }}
                                            keyboard={{
                                                enabled: true,
                                            }}
                                            pagination={{
                                                clickable: true,
                                            }}
                                            onSwiper={(swiper) => {
                                                swiperRef.current = swiper;
                                            }}
                                            navigation={props.withNavigation === "true" ? true : false}
                                            modules={[Keyboard, Pagination, Navigation, SwiperGrid]}
                                            className="swiper-xlarge"

                                            style={getXLargeSwiperStyle()}
                                        >
                                            {sortedOffers.map((e, index) => {
                                                if (e._id !== _selectedOffer._id) {
                                                    if (_properties.displayOffersAsText === true) {
                                                        return (
                                                            <SwiperSlide key={`${e._id}-${index}`} width="100%">
                                                                <Box sx={{ mb: -2, mt: -2 }}>
                                                                    <OfferTextBox key={e._id} offer={e} offerSelected={offerSelected} offers={_offers} properties={_properties} selectedOffer={_selectedOffer} />
                                                                </Box>
                                                            </SwiperSlide>
                                                        )
                                                    }
                                                    else {
                                                        return (
                                                            <SwiperSlide key={e._id} >
                                                                <OfferImageBox offer={e} sx={{ mt: -2 }} />
                                                            </SwiperSlide>
                                                        )
                                                    }
                                                }
                                                return (<></>);
                                            }
                                            )}
                                        </Swiper >

                                    </Box>}
                                    {props.doNotDisplayPrice !== "true" && (<PriceArea displayOffer={true} sx={{ pt: 1 }} />)}
                                </Stack>
                            </Stack>
                            {sortedOffers.length > 1 && <Box sx={{ position: 'absolute', bottom: '0px', right: '0px' }}>
                                <Stack direction='row'>
                                    {showPrevButton && (<Box sx={{ zIndex: 20 }}><SlidePrevButton /></Box>)}
                                    {showNextButton && (<Box sx={{ zIndex: 20 }}><SlideNextButton /></Box>)}
                                </Stack>
                            </Box>}
                        </Box>
                    </Box>

                </CardContent>
            </Card >
        )
    };


    if (!props.variant) {
        return Large();
    } else if (props.variant === 'xlarge') {
        return (XLarge(props.rows, props.columns))
    }
    else if ('small' === props.variant) {
        return Small();
    }
    else if ('tiny' === props.variant) {
        return Tiny();
    } else if ('linkToOfferPage' === props.variant) {
        return LinkToOfferPage();
    }
};

/**
 * 
 * @param {*} param0 
 * @returns 
 */
const OfferTextBox = ({ offer, offerSelected }) => {

    const _properties = useSelector((state) => properties(state));
    const _offerPrices = useSelector((state) => offerPrices(state));
    const _selectedOffer = useSelector((state) => selectedOffer(state));

    const p = _offerPrices[offer._id];
    let currency = p?.currency ? p.currency : 'EUR';
    let priceLabel = p ? t('global.currency.' + currency, { value: p.price }) : '';

    let _s = _selectedOffer || { _id: 999999 };
    let isSelected = _s?._id === offer?._id || offers.length === 1;
    console.log("textbox " + offer?.shortName + " selected = " + isSelected);

    const getTextboxStyle = (selOffer, e) => {
        let _s = selOffer || { _id: 999999 };
        let backgroundColour, fontColour, border, bgHoover, cursorHoover;
        if ((_s?._id === e?._id) || offers.length === 1) {
            backgroundColour = `${_properties.appSecondaryColor}`;
            fontColour = '#F4F4F4';
            border = `1px solid ${_properties.appSecondaryColor}`;
            bgHoover = `${_properties.appSecondaryColor}`;
            cursorHoover = "default";
        } else {
            backgroundColour = '#FFFFFF';
            fontColour = `${_properties.appSecondaryColor}`;
            border = `1px solid ${_properties.appSecondaryColor}`;
            bgHoover = ``;
            cursorHoover = ``;
        };
        return {
            color: fontColour,
            border: border,
            backgroundColor: backgroundColour,
            borderRadius: '10px',
            opacity: 1,
            width: '98%',
            height: '150px',
            pt: 1,
            pb: 1,
            mt: 1,
            mb: 1,
            "&.MuiButtonBase-root:hover": {
                bgcolor: bgHoover,
                cursor: cursorHoover
            }

        }
    };

    let direction = "vertical";

    return (
        <Box key={offer._id} sx={{ display: 'block', m: 2, ml: 0, mr: 0 }}>
            <Button disableRipple id={offer._id} onClick={(e) => offerSelected(e.target.id)} sx={getTextboxStyle(_selectedOffer, offer)}>
                <Box sx={{ display: 'block', m: 2, mr: 0 }}>
                    <Grid id={offer._id} container sx={{ p: 2 }}>
                        <Grid item xs={1} />
                        <Grid item xs={10}>
                            <Box sx={{ display: 'block', m: 0, p: 0, pt: 1, width: direction === "vertical" ? (isSelected ? "12vw" : "14vw") : "8vw" }}>
                                <Typography id={offer._id} noWrap sx={{ fontSize: 18, display: 'block', overflow: 'hidden', textAlign: 'start' }}>{offer.name}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={1} />

                        <Grid item xs={1} />
                        <Grid item xs={10}>
                            <Box sx={{ display: 'block', overflow: 'hidden', m: 0, p: 0, pt: 1, width: direction === "vertical" ? (isSelected ? "12vw" : "14vw") : "8vw" }}>
                                <Typography id={offer._id} height="70px" sx={{ fontSize: 14, textAlign: 'start' }}>{offer.description}</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={1} />

                        <Grid item xs={7} />
                        <Grid item xs={5} sx={{ width: "10 vw", display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <Box >
                                {priceLabel && (
                                    <Typography id={offer._id} sx={{ fontSize: 20, fontWeight: 'bold', letterSpacing: '0px', textAlign: 'right', pr: 2, pb: 2 }}>{priceLabel}</Typography>
                                )}
                                {!priceLabel && (
                                    <Skeleton variant="text" sx={{ fontSize: 20 }} />
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Button>
        </Box>);
};

export default OfferPicker;

