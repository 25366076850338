import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import {
    Fab,
    Grid,
    Stack,
    TextField,
    Typography,
    Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { visuallyHidden } from "@mui/utils";
import { device, isSsoAuthentication, isWSS, isGuestAuthenticated } from '../../redux/Selectors';
import TeleComm from './TeleComm';
import { DEVICE_ACC, DEVICE_DESKTOP, DEVICE_MOBILE } from '../../common/navigationTools';


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Contact = forwardRef(({ _teleComms, _netAddress, dirty, valid, bpNew }, ref) => {
    const { t } = useTranslation();

    const _device = useSelector((state) => device(state));
    const _isSsoAuthentication = useSelector((state) => isSsoAuthentication(state));
    const _isWSS = useSelector(state => isWSS(state));
    const _isGuestAuthenticated = useSelector((state) => isGuestAuthenticated(state));

    const [netAddress, setNetAddress] = useState({ address: '' });
    const [teleComms, setTeleComms] = useState([]);
    const [showError, setShowError] = useState(false);
    const [formIsReadOnly, setFormIsReadOnly] = useState(false);
    const [statusMessage, setStatusMessage] = useState('');

    const refTeleComms = useRef([]);


    useImperativeHandle(ref, () => ({
        showErrors: () => {
            setShowError(true);
            refTeleComms.current.forEach(refTele => { if (refTele) refTele.showErrors() });
        }
    }));

    useEffect(() => {
        setFormIsReadOnly(!_isGuestAuthenticated);
//        setFormIsReadOnly(_isWSS && !_isGuestAuthenticated);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        checkTeleComms();
        // evtl. Prüfung netAdress ergänzen
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [netAddress?.address, teleComms, _teleComms]);

    useEffect(() => {
        console.log("useEffect: _netAddress " + JSON.stringify(_netAddress));
        setNetAddress(_netAddress);
        console.log("useEffect: _teleComms " + JSON.stringify(_teleComms));
        let list = JSON.parse(JSON.stringify(_teleComms));
        list?.forEach(element => {
            element.valid = false;
        });
        setTeleComms(list);
        console.log("useEffect: list " + JSON.stringify(list));

    }, [_teleComms, _netAddress]);

    const checkTeleComms = () => {
        if (valid) {
            let isInvalid = teleComms.find(tele => tele.valid === undefined || tele.valid === false);
            valid(!isInvalid /* && netAddress.address !== '' */);
        }
    }

    const addressChanged = (value) => {
        setNetAddress(Object.assign({}, netAddress, { address: value }));
        _netAddress.address = value;
        if (dirty) dirty();
    }

    const handleAddTelecomm = e => {
        let list = JSON.parse(JSON.stringify(teleComms));
        list.push(
            {
                _id: new Date().getTime(),
                mobilityType: _device === DEVICE_DESKTOP ? '' : 'mobile',
                internationalAreaCode: '',
                areaCode: '',
                subscriberCode: '',
                valid: false
            }
        );
        setTeleComms(list);
        if (dirty) dirty();
        setStatusMessage(t("accessibility.customer.contact.telecom.add.statusMessage"));
    };

    const handleChangeTeleComm = (teleComm, index) => {
        console.log("handleChangeTeleComm teleComms = " + JSON.stringify(teleComms));
        console.log("handleChangeTeleComm teleComm = " + JSON.stringify(teleComm));
        console.log("handleChangeTeleComm index = " + JSON.stringify(index));

        if (index !== undefined) {
            if (index >= _teleComms.length) {
                _teleComms.push(teleComm);
            } else {
                _teleComms[index].mobilityType = teleComm.mobilityType;
                _teleComms[index].internationalAreaCode = teleComm.internationalAreaCode;
                _teleComms[index].areaCode = teleComm.areaCode;
                _teleComms[index].subscriberCode = teleComm.subscriberCode;
            }
        }
        setTeleComms(JSON.parse(JSON.stringify(teleComms)));

        console.log("handleChangeTeleComm _teleComms = " + JSON.stringify(_teleComms));
    }

    const handleDeleteTeleComm = (teleComm) => {
        console.log("handleDeleteTeleComm teleComms =  " + JSON.stringify(teleComms));
        console.log("handleDeleteTeleComm teleComm = " + JSON.stringify(teleComm));
        if (teleComms.length > 1) {

            var index = teleComms.findIndex(t => t._id === teleComm._id);
            console.log("handleDeleteTeleComm index = " + index);

            let list = JSON.parse(JSON.stringify(teleComms));
            list.splice(index, 1);
            setTeleComms(list);
            _teleComms.splice(index, 1);
            console.log("handleDeleteTeleComm _teleComms = " + JSON.stringify(_teleComms));
            if (dirty) dirty();
            setStatusMessage(t("accessibility.customer.contact.telecom.delete.statusMessage", { phonenumber: teleComm.internationalAreaCode + teleComm.areaCode + teleComm.subscriberCode }))
        }
    }

    return (
        <form>
            <Stack
                id="maincontent"
                sx={{ margin: '1em', maxWidth: 800 }}
                direction="column"
                alignItems="left">
                {!_isSsoAuthentication && (<Typography variant={_device === DEVICE_ACC ? "h2" : "pageTitleBold"}>
                    {t('customerdata.contact.title')}
                </Typography>)}

                {/* ???? */}
                {/* <Typography variant={_device === 'mobile' ? "mobilePageSubTitle" : "pageTitleBold"}>
                    {_device === 'mobile' ? t('customer.m.home.contact.title') : t('customerdata.home.contact.title2')}
                </Typography> */}
                <Stack
                    sx={{ mt: _device === DEVICE_MOBILE ? 0 : '2em' }}
                    direction="column"
                    alignItems="left"
                    spacing={'1em'}
                >
                    {
                        teleComms?.map((e, index) => {
                            return (
                                <TeleComm
                                    onDelete={handleDeleteTeleComm}
                                    onChange={handleChangeTeleComm}
                                    key={e?._id ? e._id : -99999}
                                    item={e}
                                    ref={(element) => { refTeleComms.current[index] = element }}
                                    bpNew={true}
                                    index={index}>
                                </TeleComm>
                            )
                        })
                    }

                </Stack>
                {!formIsReadOnly && !_isWSS ? (<Stack
                    sx={{ mt: '2em' }}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    spacing={'1em'}
                >
                    <Fab size="small" color="primary" onClick={() => handleAddTelecomm()} aria-label={t('accessibility.customer.contact.telecom.add.label')}
                        aria-description={t('accessibility.customer.contact.telecom.add.description')}>
                        <AddIcon

                        />
                    </Fab>

                </Stack>) : (<></>)}
                {_device === 'mobile' && !isWSS && (
                    <Typography variant="mobilePagePrimaryLabel" sx={{ textAlign: 'center' }}>
                        {t('customer.m.home.contact.addTelecom')}
                    </Typography>
                )}
                {(_isSsoAuthentication || _isGuestAuthenticated) && (<Stack
                    sx={{ mt: '2em' }}
                    direction="column"
                    alignItems="left"
                    spacing={'1em'}
                >
                    {
                        ((netAddress && !bpNew) || _isGuestAuthenticated) && (
                            <TextField
                                sx={{ maxWidth: 400 }}
                                variant="standard"
                                label={t('login.name')}
                                type="email"
                                error={showError && netAddress?.address === ''}
                                required
                                value={netAddress?.address}
                                onChange={e => addressChanged(e.target.value)}
                                disabled={formIsReadOnly}
                            />
                        )
                    }
                </Stack>)}
                <Box id="statusMessage" sx={visuallyHidden} aria-live="assertive" >{statusMessage}</Box>
            </Stack>
        </form >
    );

});

/**
 * 
 * 
 * @param {*} businessPartner 
 * @returns 
 */
export const ContactSmall = ({ teleComms, netAddresses }) => {

    const { t } = useTranslation();

    let tc = [];
    for (let i = 0; i < Math.min(teleComms.length, 2); ++i) {
        tc.push({
            k: i ? '' : t('customerdata.home.contact.phone') + ':',
            v: teleComms[i].formattedString
        })
    }

    let na = [];
    for (let i = 0; i < netAddresses?.length; ++i) {
        tc.push({
            k: i ? '' : t('customerdata.home.contact.email') + ':',
            v: netAddresses[i].address
        })
    }

    return (<>
        <Grid container width='100%' >
            {
                tc.map(it => {
                    return (<Grid key={it.v} container width='100%' >
                        <Grid item xs={4} >
                            <Typography variant="cardData">
                                {it.k}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="cardData">
                                {it.v}
                            </Typography>
                        </Grid>
                    </Grid>
                    )
                })
            }
        </Grid >

        <Grid container width='100%' >
            {
                na.map(it => {
                    return (<Grid key={it.v} container width='100%' >
                        <Grid item xs={4} >
                            <Typography variant="cardData">
                                {it.k}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="cardData">
                                {it.v}
                            </Typography>
                        </Grid>
                    </Grid>
                    )
                })
            }
        </Grid >
    </>
    );
}

