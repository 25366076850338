import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import { EditIcon } from '../../common/widgetTools.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons';
import { EyeIcon } from '../../common/widgetTools.js';
import {
    fetchDesignAlternatives,
    setMarket,
    setTemplate,
    setTemplatesPerPage,
    initOrder,
    setOffer
} from '../../redux/Actions';

import {
    templates,
    templatesPerPage,
    properties,
    preSelectedMarket,
    preSelectedTemplate,
    selectedMarket,
    selectedTemplate,
    customerType
} from '../../redux/Selectors.js';
import AppHeader from '../../components/AppHeader';
import SelectedMarket from '../components/SelectedMarket.jsx';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import { getPage, DEVICE_ACC } from '../../common/navigationTools.js';

const TemplatePicker = ({ items, selected }) => {

    const _properties = useSelector((state) => properties(state));
    const _customerType = useSelector(state => customerType(state));

    const [focusedIndex, setFocusedIndex] = useState(0);
    const refs = useRef([]);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        if (items && items.length && selected) {
            setFocusedIndex(items.findIndex(it => it._id === selected._id));
        }
    }, [items, selected]);

    useEffect(() => {
        if (refs.current[focusedIndex]) {
            refs.current[focusedIndex].focus();
        }
    }, [focusedIndex]);

    const onPreview = (template) => {
        dispatch(setTemplate(template._id));
        dispatch(fetchDesignAlternatives());
        navigate(getPage('preview', DEVICE_ACC));
    };

    const onEdit = (template) => {
        if (_customerType) {
            console.log("initOrder ...");
            dispatch(initOrder(_customerType));
        }
        dispatch(setTemplate(template._id));
        dispatch(setOffer());
        //       dispatch(fetchDesignAlternatives());             Upselling page wird in der barrierefreien Version nicht angezeigt (?) 
        navigate(getPage('creative', DEVICE_ACC));
    };

    const handleKeyDown = (e) => {
        switch (e.key) {
            case 'ArrowDown':
                setFocusedIndex((prevIndex) =>
                    prevIndex === items.length - 1 ? 0 : prevIndex + 1
                );
                break;
            case 'ArrowUp':
                setFocusedIndex((prevIndex) =>
                    prevIndex === 0 ? items.length - 1 : prevIndex - 1
                );
                break;
            case 'Enter':
            case ' ':
                onEdit(items[focusedIndex]);
                break;
            default:
                break;
        }
    };

    return (

        <Stack direction="column" spacing={6} role="list">
            {items.map((item, index) =>
                <>
                    <Box role="listitem" aria-label={item.altHtmlTitle} >
                        <Stack
                            direction="column"
                            aria-description={item.altHtmlText}
                            justifyContent="space-evenly"
                            alignItems="flex-start"
                            spacing={2}
                            tabIndex={0}
                            onKeyDown={handleKeyDown}
                            ref={(el) => (refs.current[index] = el)}
                            key={item._id}
                            xsx={{
                                color: _properties.appPrimaryColor,
                            }}
                        // style={{
                        //     outline: focusedIndex === index ? 'solid' : 'none',
                        // }}

                        >
                            <Grid container direction="row" spacing={2} >
                                <Grid item xs={10} sx={{}}>
                                    <img
                                        style={{ height: "auto", maxWidth: "70%" }}
                                        src={item._links.image.href}
                                        onClick={() => { onEdit(item); }}
                                        alt={item.altHtmlTitle ?? item.name}
                                    ></img>
                                </Grid>
                                <Grid xs={2} sx={{ borderLeft: 'solid' }}>
                                    <Stack direction="column" spacing={4} sx={{ margin: '1rem' }}>
                                        <Typography
                                            variant="mobilePageLabelBold"
                                            dangerouslySetInnerHTML={{ __html: item.htmlTitle || item.name }} />
                                        <Typography
                                            variant="mobilePageLabel"
                                            dangerouslySetInnerHTML={{ __html: item.htmlText }} />
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Stack>
                    </Box>
                    <Divider />
                </>
            )}
        </Stack >
    );
};

/**                       
 * @returns 
 */
export const Templates = () => {

    const step = 3;
    const windowHeight = window.innerHeight;

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const _templatesPerPage = useSelector((state) => templatesPerPage(state));
    const _templates = useSelector((state) => templates(state));
    const _selectedMarket = useSelector((state) => selectedMarket(state));
    const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    const _preSelectedMarket = useSelector((state) => preSelectedMarket(state));
    const _preSelectedTemplate = useSelector((state) => preSelectedTemplate(state));

    const [items, setItems] = useState([]);

    const BACK = getPage('markets', DEVICE_ACC);
    const LABEL_BACK = 'm.navigation.toMarkets';
    const NEXT = getPage('creative', DEVICE_ACC);
    const LABEL_NEXT = 'm.navigation.toCreative';

    useEffect(() => {
        window.scrollBy({ left: 0, top: -window.innerHeight * 2, behavior: 'smooth' });
    }, []);

    useEffect(() => {
        if (_preSelectedTemplate) {
            dispatch(setTemplate(_preSelectedTemplate._id));
            next();
        }
    }, [_preSelectedTemplate]);

    useEffect(() => {
        if (_templates) {
            setItems(_templates.slice(0, _templates.length));
        }
    }, [_templates, _templatesPerPage]);

    const onMore = () => {
        dispatch(setTemplatesPerPage(_templatesPerPage + step));
    };

    const More = () => {
        return (
            _templates?.length > items.length ?
                <Stack alignItems="center" spacing={5}>
                    <IconButton color="inherit" aria-label="settings" onClick={() => { onMore(); }}>
                        <FontAwesomeIcon icon={faChevronDown} size="sm" />
                    </IconButton>
                    <Typography variant="mobilePageLabel" sx={{ textAlign: 'center' }}>{t('m.templates.showMore')}</Typography>
                </Stack>
                :
                <></>);
    };

    const back = () => {
        navigate(BACK);
    }

    const next = () => {
        navigate(NEXT);
    }

    return (
        <Stack direction="column" alignItems="center" spacing={8}>

            <AppHeader></AppHeader>
            <Stack id="maincontent" sx={{ width: '90%', minHeight: windowHeight - 150 }} direction="column" alignItems="left" spacing={5}>
                <SelectedMarket
                    sx={{ mt: '1rem' }}
                    market={_selectedMarket} clickHandler={() => {
                        dispatch(setMarket());
                        navigate(getPage('markets', DEVICE_ACC));
                    }
                    }></SelectedMarket>
                <Box xsx={{ height: '60vh', overflowY: 'scroll' }}>
                    <TemplatePicker id="maincontent" items={items} selected={_selectedTemplate}></TemplatePicker>
                    <More></More>
                </Box>
            </Stack>

            <NavigationBar
                // fixed
                back={!_preSelectedMarket ? { clicked: back, label: t(LABEL_BACK) } : null}
                next={_selectedTemplate ? { clicked: next, label: t(LABEL_NEXT) } : null}
            >
            </NavigationBar>
            <InfoText />
        </Stack >
    );
}

export default Templates;
