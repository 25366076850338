
import { combineReducers } from "redux";

import { ApiReducer, CommonReducer } from "./CommonReducers.js";
import { OrderReducer } from "./OrderReducers.js";
import { TrackingReducer } from "./TrackingReducers.js";

import { urlParamsToState, customerTypeFromLE, propertiesToState, urlParamValue } from "../common/tools.js";
import { Expose } from "../common/Expose.js";

import {
	INIT_CREATIVE,
	REFRESH_AUTHENTICATION,
	SET_INVOICES_PERIOD,
	SET_ORDERS_PERIOD,
	SET_ORDERS_LAYOUT,
	SET_CUSTOMER_TAB,
	SET_EPAYMENT,
	RESET_EPAYMENT,
	SET_DATAGRID_LOCALE_TEXT,
	SET_FORMX_READY,
	SET_FORMX_VALUES,
	RESET_FORMX_VALUES,
	SET_UPSELLING_DONE,
	STYLO_REQUEST,
	STYLO_DONE,
	STYLO_READY,
	STYLO_SAVED,
	LOAD_PAGE_DIMENSIONS_SUCCESS,
	SET_UPSELLING_TEXTFLOWS,
	SET_UPSELLING_IMAGES,
	SET_TEMPLATES_PER_PAGE,
	LOAD_DESIGN_ALTERNATIVES_REQUEST,
	LOAD_DESIGN_ALTERNATIVES_SUCCESS,
	LOAD_DESIGN_ALTERNATIVES_FAILURE,
	LOAD_UPSELLING_ALTERNATIVES_REQUEST,
	LOAD_UPSELLING_ALTERNATIVES_SUCCESS,
	LOAD_UPSELLING_ALTERNATIVES_FAILURE,
	SET_UPSELLING_COLUMNS,
	SET_MOTIF_STRUCTURE,
	RESET_PRESELECTED,
	CHECK_PASSWORD_REQUEST,
	CHECK_PASSWORD_SUCCESS,
	CHECK_PASSWORD_FAILURE

} from "./Actions.js";
import { preSelectedMarket, preSelectedTemplate } from "./Selectors.js";

const keyValueList = response => response && response._embedded ? response._embedded.keyValueList : [];

const masterDataReducer = (state, action) => {
	if (!state) {
		return {
			customerType: 'private'
		};
	}
	let newState = state;
	switch (action.type) {
		case 'URL_PARAMS':
			newState = Object.assign({}, state, urlParamsToState(state));
			break;
		case 'SAVE_BP_SUCCESS':
		// eslint-disable-next-line  no-fallthrough
		case 'FETCH_BP_SUCCESS':
			if (Boolean(action.json)) {
				let json = action.type === 'SAVE_BP_SUCCESS' ? action.json.value : action.json;
				let ctype = customerTypeFromLE(json.legalEntity.type);
				newState = Object.assign({}, state, {
					orgMarketingApproval: json.marketingApproval.value,
					customerType: ctype
				});
			}
			break;
		case 'LOGOUT_SUCCESS':
			let pbe = state.perflogBaseEvent;
			if (pbe) {
				pbe.userName = null;
				newState = Object.assign({}, state, {
					perflogBaseEvent: pbe
				});
			}
			break;
		case 'SET_CUSTOMER_TYPE':
			newState = Object.assign({}, state, {
				customerType: action.value
			});
			break;
		case 'FETCH_PROPERTIES_SUCCESS':
			newState = Object.assign({}, state, propertiesToState(state, action));
			break;
		case 'LOAD_MARKETS_SUCCESS': {
			let markets = action.json._embedded.marketList;
			// let id = Number(urlParamValue('category'));
			// let selectedMarket = id ? markets.find(t => t._id === id) : null;
			// newState = Object.assign({}, state, { markets, selectedMarket });
			newState = Object.assign({}, state, { markets });
			break;
		}
		case 'SET_MARKET': {
			const filtered = state.markets.filter(e => e._id === action.value);
			if (filtered) {
				newState = Object.assign({}, state, { selectedMarket: filtered[0] });
			}
			break;
		}
		case 'LOAD_OFFERS_SUCCESS': {
			let id = Number(urlParamValue('offer'));
			let preselectedOffer = undefined;
			if (id) {
				preselectedOffer = action.json._embedded.offerList.find(o => o._id === id);
				if (preselectedOffer) {
					const now = Date.now();
					let from = Date.parse(preselectedOffer.validFrom.substring(0, 10));
					let to = Date.parse(preselectedOffer.validTo.substring(0, 10));
					if (now >= to || now <= from) {
						preselectedOffer = undefined;
					}
				}
			}
			newState = Object.assign({}, state, {
				offers: action.json._embedded.offerList,
				selectedOffer: preselectedOffer,
				preSelectedOffer: preselectedOffer
			});
			break;
		}
		case 'LOAD_OFFERCRITERIAS_SUCCESS': {
			newState = Object.assign({}, state, {
				offercriterias: action.json._embedded.criterionList,
			});
			break;
		}
		case 'SET_OFFER': {
			const id = action.value;
			if (id) {
				const offer = state.offers.find(e => e._id === id);
				if (offer) {
					newState = Object.assign({}, state, {
						selectedOffer: offer,
					});
				}
			}
			else {
				newState = Object.assign({}, state, { selectedOffer: null });
			}
			break;
		}
		case 'LOAD_TEMPLATES_SUCCESS': {
			let templates = action.json._embedded.templateList;
			// remove alle all templates which inavlid markets
			templates.forEach(t => {
				if (state.markets) t.marketIds = t.marketIds.filter(i => state.markets.find(m => m._id === i));
			});
			// !!!???!!!
			templates = templates.filter(t => t.marketIds.length > 0);
			let id = Number(urlParamValue('template'));
			let selectedTemplate = id ? templates.find(t => t._id === id) : null;
			newState = Object.assign({}, state, { templates, selectedTemplate, components: null });
			break;
		}
		case 'LOAD_CHANNELS_SUCCESS': {
			let channels = action.json;
			// remove alle all templates which inavlid markets
			newState = Object.assign({}, state, { channels: channels });
			break;
		}
		case 'LOAD_SALUTATIONS_SUCCESS': {
			newState = Object.assign({}, state, { salutations: keyValueList(action.json) });
			break;
		}
		case 'LOAD_TITLES_SUCCESS': {
			newState = Object.assign({}, state, { titles: keyValueList(action.json) });
			break;
		}
		case 'LOAD_COUNTRIES_SUCCESS': {
			newState = Object.assign({}, state, { countries: action.json._embedded.countryList });
			break;
		}
		case 'LOAD_CITIES_SUCCESS': {
			newState = Object.assign({}, state, { cities: action.json?._embedded.cityList });
			break;
		}
		case 'LOAD_ORDERTYPES_SUCCESS': {
			newState = Object.assign({}, state, { orderTypes: action.json._embedded.keyValueList });
			break;
		}
		case 'LOAD_PAYMENT_TYPES_SUCCESS': {
			newState = Object.assign({}, state, {
				paymentTypes: action.json._embedded.paymentTypeList
			});
			break;
		}
		case 'SET_TEMPLATE': {
			const id = action.value;
			if (id) {
				const filtered = state.templates.filter(e => e._id === id);
				if (filtered) {
					const template = filtered[0];
					const markets = state.customerType ? state.markets.filter(e => e.legalEntityTypes.indexOf(state.customerType.toUpperCase()) !== -1) : state.markets;
					let changes = {
						selectedTemplate: template,
						// ????						components: template._components,
						adBoxNumberTypes: null,
						isTemplateSuggestion: action.isSuggestion
					};
					if (!state.selectedMarket) {
						changes.selectedMarket = markets.find(e => template.marketIds.indexOf(e._id) !== -1);
					}
					const ct = state.customerType === "business" ? "businessCustomer" : "privateCustomer";
					const defOffer = state.offers.find(o => o.templates.indexOf(id) !== -1 && o[ct] && o.defaultfortemplate);
					if (defOffer && !state.selectedOffer) {
						changes.selectedOffer = defOffer;
						changes.selectedEditionIds = defOffer?.editionsSelectable ? defOffer.preSelectedEditions : defOffer.editions;
					}
					newState = Object.assign({}, state, changes);
				}
			}
			else {
				newState = Object.assign({}, state, {
					selectedTemplate: null,
					components: null,
					adBoxNumberTypes: null,
					isTemplateSuggestion: false
				});
			}
			break;
		}

		case 'CLEAR_ALTERNATIVES':
			newState = Object.assign({}, state, {
				designAlternatives: [],
				upsellingAlternatives: []
			});
			break;

		case LOAD_DESIGN_ALTERNATIVES_SUCCESS: {
			let templates = action.json._embedded.templateList;
			templates.forEach(t => {
				if (state.markets) t.marketIds = t.marketIds.filter(i => state.markets.find(m => m._id === i));
			});
			templates = templates.filter(t => t.marketIds.length > 0);
			if (templates) {
				newState = Object.assign({}, state, { designAlternatives: templates });
			}
			break;
		}
		case LOAD_UPSELLING_ALTERNATIVES_SUCCESS: {
			let templates = action.json._embedded.templateList;
			templates.forEach(t => {
				if (state.markets) t.marketIds = t.marketIds.filter(i => state.markets.find(m => m._id === i));
			});
			templates = templates.filter(t => t.marketIds.length > 0);
			if (templates) {
				newState = Object.assign({}, state, { upsellingAlternatives: templates });
			}
			break;
		}
		case LOAD_DESIGN_ALTERNATIVES_REQUEST:
		case LOAD_DESIGN_ALTERNATIVES_FAILURE:
		case LOAD_UPSELLING_ALTERNATIVES_REQUEST:
		case LOAD_UPSELLING_ALTERNATIVES_FAILURE:
			newState = Object.assign({}, state, { designAlternatives: [] });
			break;
		case 'LOAD_TEMPLATE_CRITERIA_SUCCESS': {
			newState = Object.assign({}, state, { criteria: action.json });
			break;
		}

		case 'LOAD_TEMPLATE_COMPONENTS_SUCCESS': {
			newState = Object.assign({}, state, { components: action.json });
			break;
		}

		case 'FETCH_AD_BOX_NUMBER_TYPES_SUCCESS': {
			newState = Object.assign({}, state, { adBoxNumberTypes: action.json });
			break;
		}

		case 'FETCH_EDITIONS_SUCCESS':
			newState = Object.assign({}, state, { editions: action.json._embedded.editionList });
			break;
		case 'SET_EDITIONS':
			newState = Object.assign({}, state, { selectedEditionIds: action.value });
			break;
		case 'FETCH_EDITION_PARTS_SUCCESS':
			if (action.json) {
				newState = Object.assign({}, state, { editionPartsHierarchy: action.json._embedded.editionPartList });
			}
			break;
		case 'FETCH_EDITION_PARTS_FAILURE':
			newState = Object.assign({}, state, { editionPartsHierarchy: null });
			console.log(action.type, action.error);
			break;
		case 'RESET_EDITION_PARTS':
			newState = Object.assign({}, state, { editionPartsHierarchy: action.value });
			break;
		case 'FETCH_ALL_EDITION_PARTS_SUCCESS':
			newState = Object.assign({}, state, { editionParts: action.json ? action.json._embedded.editionPartList : [] });
			break;
		case 'FETCH_ALL_EDITION_PARTS_FAILURE':
			newState = Object.assign({}, state, { editionParts: [] });
			break;
		case 'SET_EDITION_PART': {
			let filtered = action.value && state.editionParts ? state.editionParts.filter(e => e._id === action.value) : null;
			if (state.selectedEditionPart) {
				console.log(state.selectedEditionPart);
				//						filtered = action.value && state.selectedEditionPart._id ? state.editionParts.filter(e => e._id === state.selectedEditionPart._id) : null;
			}
			if (filtered) {
				newState = Object.assign({}, state, { selectedEditionPart: filtered[0] });
			} else {
				newState = Object.assign({}, state, { selectedEditionPart: null });
			}
			break;
		}
		case 'CUSTOMER_TYPE_CHANGED':
			newState = Object.assign({}, state, {
				selectedTemplate: null,
				selectedMarket: null,
				selectedOffer: null
			});
			break;
		case 'SET_DEVICE':
			newState = Object.assign({}, state, { device: action.value });
			break;
		case 'FETCH_IMMOMARKT_CONFIG_SUCCESS':
			if (action.json && action.json.jsExpression) {
				newState = Object.assign({}, state, { exposeConfiguration: action.json });
			}
			break;
		case 'FETCH_CLASSMARKETS_CONFIG_SUCCESS':
			if (action.json && action.json.jsExpression) {
				newState = Object.assign({}, state, { exposeConfiguration: action.json });
			}
			break;
		case LOAD_PAGE_DIMENSIONS_SUCCESS: {
			newState = Object.assign({}, state, { pageDimensions: action.json._embedded.pageDimensionList });
			break;
		}
		case RESET_PRESELECTED: {
			newState = Object.assign({}, state, {
				preSelectedMarket: null,
				preSelectedTemplate: null,
				preselectedOffer: null
			});
			break;
		}
		default:
			break;
	}
	return newState;
};

const perfLogReducer = (state, action) => {
	if (!state) {
		return {};
	}
	let newState = state;
	let pfl;
	switch (action.type) {
		case 'SET_PERFLOG_BASEEVENT':
			newState = Object.assign({}, state, { perflogBaseEvent: action.value });
			break;
		case 'DELETE_SERVICE_PERFLOGS':
			pfl = state.serviceDependendPerfLogs;
			delete (pfl[action.value])
			newState = Object.assign({}, state, { serviceDependendPerfLogs: pfl });
			break;
		case 'SET_SERVICE_PERFLOGS':
			pfl = action.value;
			if (Boolean(state.serviceDependendPerfLogs)) {
				for (var key in state.serviceDependendPerfLogs) {
					pfl[key] = state.serviceDependendPerfLogs[key];
				}
			}
			newState = Object.assign({}, state, { serviceDependendPerfLogs: pfl });
			break;
		default:
			break;
	}
	return newState;
}

const authenticationReducer = (state, action) => {
	//	console.log(action.type, action.error);
	//	console.log(state);
	const initialState = {
		orgMarketingApproval: false,
		authentication: {},
		authenticationResponse: {},
		passwordReminderResponse: {},
		CIC: undefined,
		authRefresh: 0

	};  // nicht gut?
	if (!state) {
		return initialState;
	}
	let newState = state;
	switch (action.type) {
		case 'URL_PARAMS':
			let params = urlParamsToState(state);
			if (params && params.cicUserName && params.cicBusinessPartnerNo) {
				newState = Object.assign({}, state, { CIC: { userName: params.cicUserName, businessPartnerNo: params.cicBusinessPartnerNo } });
			}
			break;
		case 'SAVE_BP_SUCCESS':
		case 'LOGIN_SUCCESS':
			console.log(action?.json);
			if (action?.json) {
				newState = Object.assign({}, state, {
					authentication: action.json.value ? action.json.value : action.json,
					authenticationResponse: { success: true }
				});
			}
			console.log(newState);
			break;
		case 'SET_AUTHENTICATION': // @Deprecated
			newState = Object.assign({}, state, {
				authentication: action.authentication,
				authenticationResponse: { success: true }
			});
			console.log(newState);
			break;
		case 'GET_AUTHENTICATION_SUCCESS':
			newState = Object.assign({}, state, {
				authentication: action.json,
				authenticationResponse: { success: true }
			});
			console.log(newState);
			break;
		case 'PASSWORD_REMINDER_SUCCESS':
			newState = Object.assign({}, state, {
				passwordReminderResponse: action.json
			});
			break;
		case 'REMOVE_AUTHENTICATION':
		case 'LOGIN_REQUEST':
		case 'LOGOUT_REQUEST':
			newState = initialState;
			break;
		case 'LOGOUT_SUCCESS':
			newState = initialState;
			newState.authentication.loggedOut = true;
			break;
		case 'LOGIN_FAILURE':
			newState = Object.assign({}, state, {
				authentication: {},
				authenticationResponse: { success: false, status: action.json.status }
			});
			console.log(newState);
			break;
		case REFRESH_AUTHENTICATION:
			newState = Object.assign({}, state, {
				authRefresh: state.authRefresh + 1
			});
			break;
		default:
			break;
	}
	return newState;
};

const businessPartnerReducer = (state, action) => {
	//	console.log(action.type, action.error);
	//	console.log(state);
	const initialState = {
		orders: [],
		drafts: [],
		invoices: [],
		orderToRepeat: null,
		orderCancelled: null,
		businessPartner: null,
		bpForModify: null,
		salesReps: null,
		draftReleased: null,
		draftDeleted: null
	};
	if (!state) {
		return initialState;
	}
	let newState = state;
	switch (action.type) {
		case 'SET_BUSINESS_PARTNER':
			newState = Object.assign({}, state, { businessPartner: action.businessPartner });
			break;
		case 'REMOVE_BUSINESS_PARTNER':
		case 'LOGIN_FAILURE':
		case 'LOGOUT_SUCCESS':
			newState = Object.assign({}, state, initialState);
			break;
		case 'FETCH_BP_REQUEST':
			break;
		case 'FETCH_BP_SUCCESS':
			newState = Object.assign({}, state, { businessPartner: action.json });
			if (newState.businessPartner.bankAccounts) {
				newState.businessPartner.bankAccounts = newState.businessPartner.bankAccounts.map(it => {
					let iban = it.iban
					let l = iban.length;
					return Object.assign({}, it, { ibanHidden: '-***' + iban.substring(l - 3, l) });
				});
			}
			break;
		case 'FETCH_BP_FAILURE':
			console.log(action.type, action.error);
			break;
		case 'SET_BP_FOR_MODIFY':
			console.log("SET_BP_FOR_MODIFY ...");
			console.log(action);
			let bpfm;
			if (action.businessPartner !== '') {
				bpfm = JSON.parse(JSON.stringify(action.businessPartner));
				if (bpfm?.legalEntity?.credentials?.password && action.businessPartner?.businessPartnerNo) {
					bpfm.legalEntity.credentials.password = '';
				}
			}
			console.log(bpfm);
			newState = Object.assign({}, state, { bpForModify: bpfm });
			break;
		case 'VALIDATE_BANK_ACCOUNT_SUCCESS': { // richtiger reducer?
			const validation = action.json;
			if (!validation.success) {
				validation.errors.forEach(e => console.log("validateBankAccount(): error:", e));
				validation.errorText = validation.errors.map(e => e.text).join(' ');
			}
			newState = Object.assign({}, state, { bankAccountValidation: validation });
			break;
		}
		case 'FETCH_ORDERS_SUCCESS':
			newState = Object.assign({}, state, { orders: action.json ? action.json.value : [] });
			break;
		case 'RELEASE_DRAFT_SUCCESS':
			newState = Object.assign({}, state, { drafts: action.json ? action.json._embedded.orderList : [], draftReleased: { success: true } });
			break;
		case 'FETCH_DRAFTS_SUCCESS':
		case 'DELETE_DRAFT_SUCCESS':
			newState = Object.assign({}, state, { drafts: action.json ? action.json._embedded?.orderList : [], draftDeleted: { success: true } });
			break;
		case 'FETCH_SALES_REPS_SUCCESS':
			newState = Object.assign({}, state, { salesReps: action.json._embedded.salesRepList });
			break;
		case 'READ_ORDER_SUCCESS':
			newState = Object.assign({}, state, { orderToEdit: action.json });
			break;
		case 'LOAD_FORMX_VALUES_SUCCESS':
			newState = Object.assign({}, state, { orderFormxValues: action.json });
			break;
		case 'RESET_EDITED_ORDER':
			newState = Object.assign({}, state, { orderToEdit: null })
			break;
		case 'PAYMENT_STATUS_SUCCESS':
			newState = Object.assign({}, state, { paymentStatus: action.json })
			break;
		case 'EDIT_ORDER_SUCCESS':
		case 'REPEAT_ORDER_SUCCESS':
		case 'CONTINUE_DRAFT_SUCCESS':
			newState = Object.assign({}, state, { orderToRepeat: action.json });
			break;
		case 'RESET_REPEATED_ORDER':
			//	??!! formx				newState = Object.assign({}, state, { orderToRepeat: null });
			newState = Object.assign({}, state, { orderToRepeat: null, cancelUrl: null });
			break;
		case 'CANCEL_ORDER_REQUEST':
			newState = Object.assign({}, state, { orderCancelled: action.json });
			break;
		case 'CANCEL_ORDER_FAILURE':
			newState = Object.assign({}, state, { orderCancelled: action.json });
			break;
		case 'CANCEL_ORDER_SUCCESS':
			newState = Object.assign({}, state, { orderCancelled: action.json });
			break;
		case 'FETCH_INVOICES_SUCCESS':
			newState = Object.assign({}, state, { invoices: action.json ? action.json.value : [] });
			break;
		case 'RESET_BP_RESPONSE':
			newState = Object.assign({}, state, { bpResponse: null });
			break;
		case 'SAVE_BP_SUCCESS': {
			// @TODO
			const response = action.json;
			if (Boolean(response)) {
				const businessPartner = response.value;
				businessPartner._links = response._links;
				response.isNew = !Boolean(state.businessPartner);
				newState = Object.assign({}, state, { businessPartner, bpResponse: response });
			}
			break;
		}
		case 'SAVE_BP_FAILURE':
			// @TODO
			newState = Object.assign({}, state, {
				bpResponse: {
					isNew: Boolean(400 === action.json.status),
					success: false,
					status: action.json.status
				}
			})
			break;
		case 'SET_MARKETING_APPROVAL':
			var bp = state.businessPartner;
			bp.marketingApproval.value = action.value;
			newState = Object.assign({}, state, {
				businessPartner: bp
			});
			break;
		case 'SET_CANCEL_URL':
			newState = Object.assign({}, state, {
				cancelUrl: action.value
			});
			break;
		default:
			break;
	}
	return newState;
};

const businessPartnerOrdersReducer = (state, action) => {
	const initialState = {
		orders: [],
		orderToRepeat: null,
		orderCancelled: false,
		draftReleased: false,
		draftDeleted: false
	};
	if (!state) {
		return initialState;
	}
	let newState = state;
	switch (action.type) {
		case 'REMOVE_BUSINESS_PARTNER':
		case 'LOGIN_FAILURE':
		case 'LOGOUT_SUCCESS':
			newState = Object.assign({}, state, initialState);
			break;
		case 'FETCH_ORDERS_SUCCESS':
			newState = Object.assign({}, state, { orders: action.json ? action.json.value : [] });
			break;
		case 'ADDITIONAL_ORDER_DATA_SUCCESS':
		case 'CANCEL_EXPOSE_SUCCESS': {
			const o = state.orders.find(o => o.orderCode === action.json.orderCode);
			if (o) {
				o.expose = Expose.status(o, action.json);
				newState = Object.assign({}, state, {
					orders: JSON.parse(JSON.stringify(state.orders))
				});
			}
			break;
		}
		case 'EDIT_ORDER_SUCCESS':
			newState = Object.assign({}, state, { orderToRepeat: action.json /*, cancelUrl: action.json._links.cancel*/ });
			break;
		case 'REPEAT_ORDER_SUCCESS':
		case 'CONTINUE_DRAFT_SUCCESS':
			newState = Object.assign({}, state, { orderToRepeat: action.json });
			break;
		case 'RESET_REPEATED_ORDER':
			//	??!! formx				newState = Object.assign({}, state, { orderToRepeat: null });
			newState = Object.assign({}, state, { orderToRepeat: null });
			break;
		case 'CANCEL_ORDER_REQUEST':
			newState = Object.assign({}, state, { orderCancelled: action.json });
			break;
		case 'CANCEL_ORDER_FAILURE':
			newState = Object.assign({}, state, {
				orderCancelled: {
					success: false,
					error: action.json
				}
			});
			break;
		case 'CANCEL_ORDER_SUCCESS':
			newState = Object.assign({}, state, { orderCancelled: action.json });
			break;
		case 'RELEASE_DRAFT_REQUEST':
			newState = Object.assign({}, state, { draftReleased: action.json });
			break;
		case 'RELEASE_DRAFT_FAILURE':
			newState = Object.assign({}, state, {
				draftReleased: {
					success: false,
					error: action.json
				}
			});
			break;
		case 'RELEASE_DRAFT_SUCCESS':
			newState = Object.assign({}, state, { draftReleased: { success: true } });
			break;
		case 'DELETE_DRAFT_REQUEST':
			newState = Object.assign({}, state, { draftDeleted: action.json });
			break;
		case 'DELETE_DRAFT_FAILURE':
			newState = Object.assign({}, state, {
				draftDeleted: {
					success: false,
					error: action.json
				}
			});
			break;
		case 'DELETE_DRAFT_SUCCESS':
			newState = Object.assign({}, state, { draftDeleted: { success: true } });
			break;
		default:
			break;
	}
	return newState;
};

const registrationReducer = (state, action) => {
	const initialState = {
		userProfile: null
	};
	if (!state) {
		return initialState;
	}
	let newState = state;
	switch (action.type) {
		case 'FETCH_USER_PROFILE_SUCCESS':
			newState = Object.assign({}, state, { userProfile: action.json });
			break;
		case 'LOGOUT_REQUEST':
			newState = initialState;
			break;
		default:
			break;
	}
	return newState;
};

const orderResultReducer = (state, action) => {
	if (!state) {
		return {
			templatePrices: {},
			offerPrices: {},
			offerPricesPending: 0,
			orderPricePending: 0,
			paymentResponse: {},
			ePayment: null
		};
	}
	let newState = state;
	switch (action.type) {

		// @Deprecated
		case 'SET_OFFER_PRICE': {
			const price = action.value;
			if (price) {
				const offerPrices = JSON.parse(JSON.stringify(state.offerPrices));
				offerPrices[price.offerId] = price;
				newState = Object.assign({}, state, { offerPrices: offerPrices });
			}
			break;
		}
		case 'SET_ORDER_PRICE':
			newState = Object.assign({}, state, { orderPrice: action.value });
			break;
		// 				case 'SET_CONFIRMED_ORDER':
		// 					newState = Object.assign({}, state, { confirmedOrder: action.order });
		// 					break;
		case 'RESET_ORDER': {
			newState = Object.assign({}, state, {
				confirmedOrder: null,
				orderResponse: null,
				draftResponse: null,
				paymentResponse: {},
				ePayment: null,
			});
			break;
		}
		case 'BUY_SUCCESS':
			if (action.json) {
				action.json.type = action.type;
				newState = Object.assign({}, state, { orderResponse: action.json });
			}
			break;
		case 'UPDATE_ORDER_SUCCESS':
		case 'UPDATE_EXPOSE_SUCCESS':
			action.json.type = action.type;
			newState = Object.assign({}, state, { orderResponse: action.json });
			break;
		case 'SAVE_DRAFT_SUCCESS':
			newState = Object.assign({}, state, { draftResponse: action.json });
			break;
		case 'BUY_FAILURE':
			newState = Object.assign({}, state, {
				orderResponse: {
					success: false,
					error: action.json
				}
			});
			break;
		case 'CALCULATE_POSSIBLE_DATES_SUCCESS':
			newState = Object.assign({}, state, {
				possibleDatesResponse: action.json
			});
			break;
		case 'CALCULATE_POSSIBLE_DATES_FAILURE':
			newState = Object.assign({}, state, {
				possibleDatesResponse: {
					success: false,
					value: null
				}
			});
			break;
		case 'CALCULATE_PRICE_SUCCESS':
			newState = Object.assign({}, state, {
				orderPriceResponse: action.json,
				orderPricePending: state.orderPricePending - 1
			});
			break;
		case 'RESET_PRINT_DATA':
			newState = Object.assign({}, state, {
				orderPrint: undefined,
				orderPrintUrl: undefined
			});
			break;
		case 'PRINT_ORDER_SUCCESS':
			newState = Object.assign({}, state, {
				orderPrint: action.html
			});
			break;
		case 'PRINT_ORDER_SUCCESS2':
			newState = Object.assign({}, state, {
				orderPrintUrl: action.json._links.self.href
			});
			break;
		case 'EXPOSE_PREVIEW_SUCCESS':
		case 'SET_EXPOSE_STATUS':
			newState = Object.assign({}, state, {
				exposeStatus: action.json
			});
			break;
		case 'CALCULATE_PRICE_FAILURE':
			console.log('CALCULATE_PRICE_FAILURE');
			newState = Object.assign({}, state, {
				orderPriceResponse: {
					success: false,
					orderPricePending: state.orderPricePending - 1
				}
			});
			break;
		case 'CALCULATE_PRICE_REQUEST':
			newState = Object.assign({}, state, { orderPricePending: state.orderPricePending + 1 });
			break;
		case 'RESET_PRICE':
			newState = Object.assign({}, state, { orderPriceResponse: null });
			break;

		case 'SET_TEMPLATE':
			newState = Object.assign({}, state, { offerPrices: {} })
			break;

		case 'CALCULATE_OFFER_PRICE_SUCCESS': {
			const response = action.json;
			const ns = {
				offerPriceResponse: response,
				offerPricesPending: state.offerPricesPending - 1
			}
			if (response && response.success && response.value) {
				const offerPrices = JSON.parse(JSON.stringify(state.offerPrices));
				const price = response.value;
				offerPrices[price.offerId] = price;
				ns.offerPrices = offerPrices;
			}
			newState = Object.assign({}, state, ns);
			break;
		}
		case 'CALCULATE_OFFER_PRICE_FAILURE':
			console.log('CALCULATE_OFFER_PRICE_FAILURE');
			newState = Object.assign({}, state, {
				offerPriceResponse: { success: false },
				offerPricesPending: state.offerPricesPending - 1
			});
			break;
		case 'CALCULATE_OFFER_PRICE_REQUEST':
			newState = Object.assign({}, state, { offerPricesPending: state.offerPricesPending + 1 });
			break;

		case 'RESET_OFFER_PRICES':
			newState = Object.assign({}, state, { offerPrices: {} });
			break;

		case 'CALCULATE_TEMPLATE_PRICE_SUCCESS': {
			const response = action.json;
			const ns = {
				templatePriceResponse: response
			}
			if (response && response.success && response.value) {
				const templatePrices = JSON.parse(JSON.stringify(state.templatePrices));
				const price = response.value;
				templatePrices[price.templateId] = price;
				ns.templatePrices = templatePrices;
			}
			newState = Object.assign({}, state, ns);
			break;
		}
		case 'CALCULATE_TEMPLATE_PRICE_FAILURE':
			console.log('CALCULATE_TEMPLATE_PRICE_FAILURE');
			break;
		case 'CALCULATE_TEMPLATE_PRICE_REQUEST':
			break;

		case 'CONFIRM_EPAYMENT':
			newState = Object.assign({}, state, { paymentResponse: { success: true } })
			break;
		case SET_EPAYMENT:
			newState = Object.assign({}, state, { ePayment: action.value })
			break;
		case RESET_EPAYMENT:
			newState = Object.assign({}, state, { ePayment: null })
			break;
		default:
			break;
	}
	return newState;
};


const creativeReducer = (state, action) => {

	const textFlows = value => {
		return {
			textFlows: value.textFlows ? Object.assign({}, state.textFlows.textFlows, value.textFlows) : undefined,
			mediaLegends: value.mediaLegends ? Object.assign({}, state.textFlows.mediaLegends, value.mediaLegends) : undefined
		}
	};

	const initialTextFlow = {
		textFlows: {},
		mediaLegends: {}
	};

	const initialState = () => {
		return {
			textFlows: initialTextFlow,
			uuid: String(Math.random()).substring(2),
			material: undefined,									// Assets provided by a formular
			coloured: true,
			styloReady: false,
			formxReady: false,
		}
	};

	if (!state) {
		return initialState();
	}

	let newState = state;
	switch (action.type) {
		case STYLO_READY:
			newState = Object.assign({}, state, { styloReady: action.value });
			break;
		case STYLO_SAVED:
			newState = Object.assign({}, state, { styloSaved: action.value });
			break;
		case INIT_CREATIVE:
			newState = Object.assign({}, state, {
				uuid: String(Math.random()).substring(2),
				material: [],
				coloured: true,
				styloSaved: false
			});
			break;
		case 'LOAD_SERVICES_SUCCESS':
			newState = Object.assign({}, state, {
				activeProfiles: action.json.activeProfiles
			});
			break;
		case 'TEMPLATE_STRUCTURE_SUCCESS':
			try {
				newState = Object.assign({}, state, {
					namedMedia: Array.prototype.slice.call(action.document.getElementsByTagName("NamedMedia")),	// convert NodeList to Array
					textInput: Array.prototype.slice.call(action.document.getElementsByTagName("Struct")[0].childNodes).filter(t => t.attributes && (!t.attributes.noFormField || t.attributes.noFormField.nodeValue !== "true"))
				});
			}
			catch (e) { }
			break;
		case SET_MOTIF_STRUCTURE:
			newState = Object.assign({}, state, { xmlMotifStructure: action.value });
			break;
		case 'SET_MOTIV':
			newState = Object.assign({}, state, {
				rawText: action.value.rawText,
				params: action.value.params
			});
			break;
		case 'SET_MOTIF_CRITERION_VALUES':
			newState = Object.assign({}, state, { motifCriterionValues: action.value });
			break;
		case SET_FORMX_READY:
			newState = Object.assign({}, state, { formxReady: action.value });
			break;
		case SET_FORMX_VALUES:
			newState = Object.assign({}, state, { formxValues: action.value });
			break;
		case RESET_FORMX_VALUES:
			newState = Object.assign({}, state, { formxValues: undefined, formxInitialValues: undefined });
			break;

		case 'ADD_MATERIAL': {
			let webstoreResource = action.value;
			let material = state.material ? JSON.parse(JSON.stringify(state.material)) : [];
			if (!material.find(e => e._id === webstoreResource._id)) {
				material.push(webstoreResource);
			}
			newState = Object.assign({}, state, {
				material: material
			});
			break;
		}
		case 'REMOVE_MATERIAL': {
			let webstoreResource = action.value;
			let material = state.material.filter(it => webstoreResource._id !== it._id);
			newState = Object.assign({}, state, {
				material: material
			});
			break;
		}
		case 'SET_FORMX_VALID':
			newState = Object.assign({}, state, { formxValid: action.value });
			break;
		case 'SET_PREVIEW':
			newState = Object.assign({}, state, { preview: action.value });
			break;
		case 'SET_TEXT_FLOWS':
			newState = Object.assign({}, state, { textFlows: action.value || initialTextFlow });
			break;
		case 'ADD_TEXT_FLOW':
			newState = Object.assign({}, state, { textFlows: textFlows(action.value) });
			break;
		case SET_UPSELLING_TEXTFLOWS:
			newState = Object.assign({}, state, { upsellingTextflows: textFlows(action.value) });
			break;
		case SET_UPSELLING_IMAGES:
			newState = Object.assign({}, state, { upsellingImages: action.value });
			break;
		case 'SET_TEMPLATE_USER_FIELDS':
			newState = Object.assign({}, state, { templateUserFields: action.value ? JSON.parse(action.value).UserFields : null });
			break;
		case 'SET_TEMPLATE_USER_FIELD_VALUES':
			newState = Object.assign({}, state, { templateUserFieldValues: action.value });
			break;
		case 'SET_AD_BOX_FIELDS':
			newState = Object.assign({}, state, { adBoxFields: action.value || [] });
			break;
		case 'READ_ORDER_SUCCESS': {
			const uuid = state.activeProfiles.includes('SAP-SC') ? action.json.motif.motifUuid : action.json.orderCode;
			newState = Object.assign({}, state, { uuid });
		}
			break;
		case 'EDIT_ORDER_SUCCESS':
		case 'REPEAT_ORDER_SUCCESS':
		case 'CONTINUE_DRAFT_SUCCESS': {
			const uuid = state.activeProfiles.includes('SAP-SC') ? action.json.motif.motifUuid : action.json.orderCode;
			const originalMotifCode = uuid + ".A";
			newState = Object.assign({}, state, { originalMotifCode, uuid });
		}
			break;
		case 'RESET_REPEATED_ORDER':
			newState = Object.assign({}, state, { originalMotifCode: undefined });
			break;
		case 'RESET_ORDER':
			newState = Object.assign({}, initialState(), { activeProfiles: state.activeProfiles });
			break;
		case 'FETCH_TEMPLATE_ONLINE_ID_SUCCESS':
			newState = Object.assign({}, state, { onlineId: action.json });
			break;
		case 'SET_MOTIF_COLUMNS':
			newState = Object.assign({}, state, { columns: action.value });
			break;
		case SET_UPSELLING_COLUMNS:
			newState = Object.assign({}, state, { upsellingColumns: action.value });
			break;
		case 'SET_MOTIF_COLOURED':
			newState = Object.assign({}, state, { coloured: action.value });
			break;
		case 'SET_TEMPLATE':
			newState = Object.assign({}, state, {
				styloSaved: false
			});
			break;
		case 'SET_ORDER_CATCHWORD':
			newState = Object.assign({}, state, { orderCatchword: action.value });
			break;
		default:
			break;
	}
	return newState;
};


const uiReducer = (state, action) => {
	if (!state) {
		return {
			pendingRequests: 0,
			blockingRequests: 0,
			page: '',
			customer: {
				tab: 'home',
				orders: {
					//		layout: 'tile',
					period: 'month'
				},
				invoices: {
					period: 'month'
				}
			},
			upSellingDone: false,
			templatesPerPage: 3
		};
	}

	let customer = state.customer;

	let newState = state;

	switch (action.type) {
		case STYLO_REQUEST:
			newState = Object.assign({}, state, { styloPending: true });
			break;
		case STYLO_DONE:
			newState = Object.assign({}, state, { styloPending: false });
			break
		case 'SET_ENTRY_POINT':
			newState = Object.assign({}, state, { entryPoint: action.value });
			break;
		case 'SET_PAGE':
			newState = Object.assign({}, state, {
				page: action.value,
				previousPage: state.page
			});
			break;
		case 'SET_DEVICE':
			newState = Object.assign({}, state, { device: action.value });
			break;
		case 'SET_LANGUAGE':
			newState = Object.assign({}, state, { language: action.value });
			break;
		case 'SET_LOCALE':
			newState = Object.assign({}, state, { locale: action.value });
			break;
		case SET_INVOICES_PERIOD:
			customer = Object.assign({}, state.customer);
			customer.invoices.period = action.value;
			newState = Object.assign({}, state, { customer });
			break;
		case SET_ORDERS_PERIOD:
			customer = Object.assign({}, state.customer, { tab: action.value })
			customer.orders.period = action.value;
			newState = Object.assign({}, state, { ordersPeriod: action.value });
			break;
		case SET_ORDERS_LAYOUT:
			customer = Object.assign({}, state.customer, { tab: action.value })
			customer.orders.layout = action.value;
			newState = Object.assign({}, state, { ordersLayout: action.value });
			break;
		case SET_CUSTOMER_TAB:
			customer = Object.assign({}, state.customer, { tab: action.value })
			newState = Object.assign({}, state, { customer });
			break;
		case SET_DATAGRID_LOCALE_TEXT:
			newState = Object.assign({}, state, { datagridLocaleText: action.value });
			break;
		case SET_UPSELLING_DONE:
			newState = Object.assign({}, state, { upSellingDone: action.value });
			break;
		case SET_TEMPLATES_PER_PAGE:
			newState = Object.assign({}, state, { templatesPerPage: action.value });
			break;
		default:
			break;
	}
	return newState;
};

const businessPartnerDataReducer = (state, action) => {
	if (!state) {
		return {};
	}
	let newState = state;
	switch (action.type) {
		case 'FETCH_SALES_REPS_SUCCESS':
			newState = Object.assign({}, state, { salesReps: action.json._embedded.salesRepList });
			break;
		case 'SET_CANCEL_URL':
			newState = Object.assign({}, state, {
				cancelUrl: action.value
			});
			break;
		default:
			break;
	}
	return newState;
};

const userReducer = (state, action) => {
	if (!state) {
		return {
		};
	}
	let newState = state;
	switch (action.type) {
		case 'SET_USER_DATA':
			newState = Object.assign({}, state, { user: JSON.parse(JSON.stringify(action.value)) });
			break;
		default:
			break;
	}
	return newState;
};

const checkPasswordAllowedReducer = (state, action) => {
	if (!state) {
		return {
		};
	}
	let newState = state;
	switch (action.type) {
		case 'CHECK_PASSWORDALLOWED_REQUEST':
			break;
		case 'CHECK_PASSWORDALLOWED_SUCCESS':
			newState = Object.assign({}, state, {
				checkedPasswordValid: { passwordcheck: action.json.passwordcheck, errortext: action.json.errortext }
			});
			break;
		case 'CHECK_PASSWORDALLOWED_FAILURE':
			break;
		default:
			break;
	}
	return newState;
};

const checkPasswordReducer = (state, action) => {
	if (!state) {
		return {
		};
	}
	let newState = state;
	switch (action.type) {
		case CHECK_PASSWORD_REQUEST:
			break;
		case CHECK_PASSWORD_SUCCESS:
			newState = Object.assign({}, state, {
				passwordCheck: action.json
			});
			break;
		case CHECK_PASSWORD_FAILURE:
			break;
		default:
			break;
	}
	return newState;
};

const checkUserNameReducer = (state, action) => {
	if (!state) {
		return {
		};
	}
	let newState = state;
	switch (action.type) {
		case 'CHECK_USERNAME_REQUEST':
			break;
		case 'CHECK_USERNAME_SUCCESS':
			newState = Object.assign({}, state, { userNameValid: action.json.available });
			break;
		case 'CHECK_USERNAME_FAILURE':
			break;
		case 'RESET_CHECKED_USERNAME_VALID':
			newState = Object.assign({}, state, { userNameValid: undefined });
			break;
		default:
			break;
	}
	return newState;
};

const styloReducer = (state, action) => {

	if (!state) {
		return {};
	}

	let newState = state;
	switch (action.type) {
		case 'SET_USER_FIELDS':
			if (action.value && action.value.length) {
				let added = {}
				action.value.forEach(v => {
					added[v.name] = v;
				});
				newState = Object.assign({}, state, added);
			}
			else {
				newState = {};
			}
			break;
		default:
			break;
	};
	return newState;
}

const rootReducer = combineReducers({
	order: OrderReducer,
	orderResults: orderResultReducer,
	masterData: masterDataReducer,
	authentication: authenticationReducer,
	BP: businessPartnerReducer,
	businessPartnerOrdersReducer,
	registration: registrationReducer,
	common: CommonReducer,
	creative: creativeReducer,
	ui: uiReducer,
	businessPartnerData: businessPartnerDataReducer,
	tracking: TrackingReducer,
	user: userReducer,
	checkPasswordAllowed: checkPasswordAllowedReducer,
	checkPassword: checkPasswordReducer,
	checkUserName: checkUserNameReducer,
	api: ApiReducer,
	perfLog: perfLogReducer,
	stylo: styloReducer
});

export default rootReducer;
