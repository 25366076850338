import './App.css';

import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useSearchParams } from "react-router-dom";
import {
  defaults,
  device,
  features as webstoreFeatures,
  properties,
  services as selectServices
} from './redux/Selectors'

import {
  features,
  fetchAllEditionParts,
  fetchChannels,
  fetchClassmarketsConfig,
  fetchCountries,
  fetchEditions,
  fetchImmomarktConfig,
  fetchMarkets,
  fetchOffers,
  fetchOrderTypes,
  fetchPageDimensions,
  fetchPaymentTypes,
  fetchProperties,
  fetchSalutations,
  fetchTemplates,
  fetchTitles,
  getAuthentication,
  getAuthProvider,
  services,
  setDevice,
  urlParams
} from './redux/Actions.js';
import { useNavigate } from 'react-router-dom';
import './configuration/i18n';
import Splash from './pages/Splash';
import { getPage } from './common/navigationTools';

const App = ({ theme }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const loadMasterData = async () => {
    await dispatch(fetchMarkets());
    await dispatch(fetchTemplates());
    await dispatch(getAuthProvider());
    await dispatch(fetchOffers());
    await dispatch(fetchEditions());
    dispatch(getAuthentication());
    dispatch(fetchAllEditionParts());
    dispatch(fetchPaymentTypes());
    dispatch(fetchSalutations());
    dispatch(fetchTitles());
    dispatch(fetchCountries());
    dispatch(fetchOrderTypes());
    dispatch(fetchChannels());
    dispatch(fetchImmomarktConfig());
    dispatch(fetchPaymentTypes());
    dispatch(fetchClassmarketsConfig());
    dispatch(fetchPageDimensions());
  }

  const _defaults = useSelector((state) => defaults(state));
  const _device = useSelector((state) => device(state));
  const _properties = useSelector((state) => properties(state));
  const _selectServices = useSelector((state) => selectServices(state));
  const _webstoreFeatures = useSelector((state) => webstoreFeatures(state));

  useEffect(() => {

    if (_properties && Object.keys(_properties).length) {
      loadMasterData().then(() => {
        if (!_webstoreFeatures.WEBSTORE_NG.active) {
          window.location = '/webstore';
        }
        else if ('mini' === _device) {
          navigate('/webstore/app/min-width');
        }
        else if ('mobile' !== _device && _webstoreFeatures.MOBILE_ONLY.active) {
          window.location = '/webstore';
        } else {
          navigate(getPage('start', _device));
        }
        /*       else if ('mobile' === _device) {
                 navigate('/webstore/app/m/markets');
               }
               else {
                 navigate('/webstore/app/templates');
               } */
      });
    }
  }, [_properties]);

  useEffect(() => {
    if (_webstoreFeatures) {
      dispatch(fetchProperties());
    }
  }, [_webstoreFeatures]);

  useEffect(() => {
    if (_defaults && Object.keys(_defaults).length) {
      dispatch(features());
    }
  }, [_defaults]);

  useEffect(() => {
    if (_selectServices) {
      dispatch(urlParams());
    }
  }, [_selectServices]);

  useEffect(() => {
    dispatch(services());
  }, []);

  return (
    <Splash></Splash>
  );
}

export default App;