import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import '../../configuration/i18n';
import {
    Box,
    Grid,
    Skeleton,
    Stack,
    Tabs,
    Tab,
    tabsClasses,
    Typography,
    IconButton,
    Snackbar
} from '@mui/material';
import HtmlTooltip from '@mui/material/Tooltip';

import { DataGrid, GridToolbar } from '@mui/x-data-grid';

import OrderPicker from './OrderPicker';
import { GridViewIcon, InfoIcon, MoreIcon, TableViewIcon } from '../../common/widgetTools';

import { OrderDetail } from './OrderCard';

import {
    fetchDrafts,
    setOrdersPeriod,
    setOrdersLayout,
} from '../../redux/Actions.js';
import {
    drafts as orders,
    ordersPeriod,
    ordersLayout,
    datagridLocaleText,
    draftReleased,
    draftDeleted
    // selectedMarket,
    // selectedTemplate,
    // components
} from '../../redux/Selectors.js';

import { cutCatchword } from '../../common/tools';
import DraftActionsMenu from './DraftActionsMenu';

const TILE_LAYOUT = 'tile';
const GRID_LAYOUT = 'grid';
const stringList = array => {
    return array ? array.join(',') : '';
};


const renderPubDates = (params, t) => {

    const fpd = params.value[0].substring(0, 10);
    const lpd = params.value[params.value.length - 1].substring(0, 10);
    const pd = fpd !== lpd ? t('common.date.short', { value: new Date(fpd) }) + ' - ' + t('common.date.short', { value: new Date(lpd) }) : t('common.date.short', { value: new Date(fpd) });

    return (
        params.value.length === 1 ?
            (<span>{pd}</span>)
            :
            (<HtmlTooltip
                arrow
                placement="right-start"
                title={
                    <React.Fragment>
                        <Stack
                            direction='column'
                            spacing='10'
                            sx={{ width: 200, m: 10 }}>
                            <Typography
                                sx={{ fontSize: 14, fontWeight: 'bold', textAlign: 'left', color: '#F4F4F4' }}>
                                {pd}
                            </Typography>
                            {params.value.map(it =>
                                <Typography
                                    sx={{ fontSize: 12, textAlign: 'left', color: '#F4F4F4' }}>
                                    {t('common.date.short', { value: new Date(it) })}
                                </Typography>
                            )}
                        </Stack>
                    </React.Fragment>
                }
            >
                <span>{pd}</span>
            </HtmlTooltip>)
    )
};

const renderEditions = (params) => {

    return (
        params.value.length === 1 ?
            (<span>{params.value[0]}</span>)
            :
            (<HtmlTooltip
                arrow
                placement="right-start"
                title={
                    <React.Fragment>
                        <Stack
                            direction='column'
                            spacing='10'
                            sx={{ width: 200, m: 10 }}>
                            {params.value.map(it =>
                                <Typography
                                    sx={{ fontSize: 12, textAlign: 'left', color: '#F4F4F4' }}>
                                    {it}
                                </Typography>
                            )}
                        </Stack>
                    </React.Fragment>
                }
            >
                <Stack direction="row"
                    justifyContent="left"
                    alignItems="center"
                    spacing={4}>
                    <span>{params.value[0]}</span>
                    <InfoIcon fontSize="small" />
                </Stack>
            </HtmlTooltip>)
    )
};

const orderList = (orders, t) => {
    if (!orders) {
        return [];
    }
    else {
        let mapped = orders.map(e => {
            const fpd = e.effectiveDates[0].substring(0, 10);
            const lpd = e.effectiveDates[e.effectiveDates.length - 1].substring(0, 10);
            return {
                id: e.orderCode,
                preview: e._links.preview.href,
                orderCode: e.orderCode,
                orderDate: e.created ? t('common.date.short', { value: new Date(e.created.substring(0, 10)) }) : null,
                editionPart: stringList(e.effectiveEditionPartNames),
                editions: e.effectiveEditionNames,
                pubDates: e.effectiveDates,
                firstpubDate: t('common.date.short', { value: new Date(fpd) }),
                lastPubDate: t('common.date.short', { value: new Date(lpd) }),
                catchWord: cutCatchword(e.orderCatchword, 20),
                //                price: t('global.currency.' + (e.orderPrice.currency || 'EUR'), { value: e.orderPrice.price }),
                price: Math.round((e.orderPrice.price + Number.EPSILON) * 100) / 100,
                orderState: e.orderState
            }
        });
        return mapped;
    }
}


/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Drafts = ({ showBusyIndicator }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const _orders = useSelector(state => orders(state));
    const _datagridLocaleText = useSelector(state => datagridLocaleText(state));
    const _draftReleased = useSelector(state => draftReleased(state));
    const _draftDeleted = useSelector(state => draftDeleted(state));
    const activeTab = useSelector(state => ordersLayout(state));
    const [rows, setRows] = useState([]);
    const period = useSelector(state => ordersPeriod(state));
    const [pending, setPending] = useState(false);
    const [refreshCount, setRefreshCount] = useState(1);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [menuAnchorEl, setMenuAnchorEl] = React.useState(null);
    const [orderState, setOrderState] = useState('new');
    const isMenuOpen = Boolean(menuAnchorEl);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMsg, setSnackbarMsg] = useState('');

    const refPicker = useRef(null);


    // const _selectedMarket = useSelector((state) => selectedMarket(state));
    // const _selectedTemplate = useSelector((state) => selectedTemplate(state));
    // const _components = useSelector((state) => components(state));

    const calcHeight = () => {
        return window.innerHeight / 2 + 50;
    }
    const [height, setHeight] = useState(calcHeight());
    const listenResize = () => {
        setHeight(calcHeight());
    };

    useEffect(() => {
        console.log("Drafts");
        listenResize();
        window.addEventListener('resize', listenResize);
        return (() => {
            window.removeEventListener('resize', listenResize);
        });
    }, []);

    useEffect(() => {
        console.log("called _draftReleased ...")
        if (_draftReleased) {
            if (_draftReleased.success) {
                setSnackbarMsg(t('draft.release.success'));
                setSnackbarOpen(true);
                refPicker?.current?.closeDetailDialog();
                handleDialogClose('xx');
            }
            else {
                //          this._lastError(draftReleased);
                //					this.$.cancelFailure.open();
            }
        }
    }, [_draftReleased]);

    useEffect(() => {
        console.log("called _draftDeleted ...")
        if (_draftDeleted) {
            if (_draftDeleted.success) {
                setSnackbarMsg(t('draft.deleted.success'));
                setSnackbarOpen(true);
                refPicker?.current?.closeDetailDialog();
                handleDialogClose('xx');
                showBusyIndicator(false);
            }
            else {
                //          this._lastError(draftReleased);
                //					this.$.cancelFailure.open();
            }
        }
    }, [_draftDeleted]);

    const handleMenuOpen = (e) => {
        setMenuAnchorEl(e.target);
        //        setMenuAnchorEl(document.getElementById('app-bar-id'));
    }

    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    }

    const rowSelected = orderCode => {
        let o = _orders.find(it => it.orderCode === orderCode);
        setSelectedOrder(o);
    };

    const columns = [
        {
            field: 'preview',
            headerName: '',
            renderCell: (params) => <img alt="" style={{ width: '50px' }} src={params.value} ></img >, width: 150
        },
        { field: 'orderCode', headerName: t('order.orderCode'), width: 150 },
        { field: 'catchWord', headerName: t('order.orderCatchword'), width: 200 },
        {
            field: 'pubDates',
            headerName: t('order.pubDates'),
            width: 230,
            renderCell: (params) => { return renderPubDates(params, t) }
        },
        // {field: 'firstPubDate', headerName: t('order.pubDates'), width: 150, type: Date },
        // {field: 'lastPubDate', headerName: t('order.lastPubDate'), width: 150, type: Date },
        {
            field: 'price',
            headerName: t('order.orderPrice'),
            width: 120,
            valueFormatter: params => {
                // todo: get currency by params.id from order-list
                return t('global.currency.' + ('EUR'), { value: params.value });
            },
            type: Number,
            align: 'right',
            headerAlign: 'right'
        },
        // {field: 'orderState', headerName: t('order.orderState'), width: 50 },
        { field: 'editionPart', headerName: t('order.editionPart'), width: 150 },
        {
            field: 'editions',
            headerName: t('order.editions'),
            width: 200,
            renderCell: params => { return renderEditions(params) }
        },
        {
            field: 'actions',
            headerName: '',
            renderCell: params => {
                return (<IconButton onClick={(e) => { handleMenuOpen(e) }}><MoreIcon /></IconButton>);
            },
            width: 50
        }
    ];

    useEffect(() => {
        showBusyIndicator(true);
        dispatch(fetchDrafts());
        setPending(true);
        setRefreshCount(refreshCount + 1);
    }, [_draftReleased, _draftDeleted]
    );

    useEffect(() => {
        dispatch(fetchDrafts());
    }, []);

    useEffect(() => {
        setRows(orderList(_orders, t));
        setPending(false);
        showBusyIndicator(false);
    }, [_orders]);

    useEffect(() => {
        if (selectedOrder) {
            //            setMenuAnchorEl(document.getElementById('app-bar-id'));
        }
    }, [selectedOrder]);

    const handleChange = (event, newValue) => {
        dispatch(setOrdersLayout(newValue));
    };

    const OrderTabs = () => {
        return (
            <Tabs sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                    '&.Mui-disabled': { opacity: 0.3 },
                },
                mt: 0
            }}
                variant="scrollable"
                scrollButtons="auto"
                allowScrollButtonsMobile
                value={activeTab}
                onChange={handleChange} >
                <Tab
                    value={TILE_LAYOUT}
                    iconPosition='start'
                    icon={<GridViewIcon size='lg' />}
                >
                </Tab>
                <Tab
                    value={GRID_LAYOUT}
                    iconPosition='start'
                    icon={<TableViewIcon size='lg' />}
                >
                </Tab>
            </Tabs >
        );
    }

    /**
     * Detail dialog like in tile view
     */
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleDialogOpen = () => {
        console.log("handleDialogOpen ....");
        setDialogOpen(true);
    };

    const handleDialogClose = (ba) => {
        console.log("handleDialogClose ....");
        setDialogOpen(false);
    };

    const DetailDialog = () => {
        console.log("DetailDialog " + (dialogOpen ? "open" : "closed"));
        return selectedOrder && (
            <OrderDetail order={selectedOrder} handleDialogClose={handleDialogClose} dialogOpen={dialogOpen} isDraft={true} />
        );
    };

    return (
        <Stack
            sx={{ m: '1em' }}
            direction="column"
            justifyContent="space-between"
            spacing="0.5em"
            alignItems="left">

            <Typography variant="pageTitleBold">
                {t('customerdata.drafts.title')}
            </Typography>

            <Stack direction="column" alignItems="flex-end" justifyContent="space-between">
                <OrderTabs />
            </Stack>

            <Box sx={{ height, width: '100%' }}>
                {GRID_LAYOUT === activeTab && (
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        components={{
                            // Toolbar: GridToolbar,
                        }}
                        localeText={_datagridLocaleText}
                        onRowDoubleClick={(params, event, detail) => {
                            rowSelected(params.id);
                            handleDialogOpen();
                        }}
                        onRowClick={(params, event) => {
                            rowSelected(params.id);
                        }} />
                )}
                {TILE_LAYOUT === activeTab && (
                    <OrderPicker xHeight={height} orders={_orders} isDraft={true} ref={refPicker} />
                )}
            </Box>
            <DraftActionsMenu
                menuAnchorEl={menuAnchorEl}
                isMenuOpen={isMenuOpen}
                handleMenuClose={handleMenuClose}
                //                handleMenuClick={handleMenuClick}
                selectedOrder={selectedOrder}
                showBusyIndicator={showBusyIndicator}
            />
            <DetailDialog
                open={dialogOpen}
            />

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={() => {
                    setSnackbarOpen(false);
                    setSnackbarMsg('');
                    // closeHandler();
                }}
                message={snackbarMsg}
            />
        </Stack >
    );
}

/**
 *
 *
 * @param {*} orders
                        * @returns
                        */
export const DraftsSmall = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const _orders = useSelector((state) => orders(state));
    const period = useSelector((state) => ordersPeriod(state));
    const [pending, setPending] = useState(true);
    //    const [timeoutId, setTimeoutId] = useState(0);
    const [list, setList] = useState([]);

    let timeoutId;
    useEffect(() => {
        timeoutId = setTimeout(() => { setPending(false) }, 5000);
        return (() => {
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        });
    }, []);

    useEffect(() => {
        setPending(true);
        dispatch(fetchDrafts());
    }, []);

    useEffect(() => {
        const l = []
        orderList(_orders, t).forEach((it, index, array) => {
            if (index < 3) {
                l.push(it);
            }
        });
        setPending(Boolean(_orders.length === 0));
        setList(l)
    }, [_orders]);

    return (!pending ? (
        < Box >
            {
                list.map(it => {
                    return (<Grid container width='100%' key={it.orderCode}>
                        <Grid item xs={4} >
                            <Typography variant="cardData">
                                {t('common.date.long', { value: it.orderDate })}
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="cardData">
                                {it.orderCode} - {it.catchWord}
                            </Typography>
                        </Grid>
                    </Grid>
                    )
                })
            }
        </Box >
    ) : (< Box >
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
    </Box>
    ));
}


