import React from 'react';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import '../../configuration/i18n';
import {
    bpForModify, businessPartner, isWSS, properties, isGuestAuthenticated
} from '../../redux/Selectors.js';
import {
    setBpForModify
} from '../../redux/Actions.js';
import { Box, List, ListItem, ListItemButton, Stack, Typography } from '@mui/material';
import AppHeader from '../../components/AppHeader';
import NavigationBar from '../components/NavigationBar';
import InfoText from '../../components/InfoText';
import {
    ContactMailIcon, ContactIcon, PaymentsIcon, OrdersIcon,
    InvoiceIcon, ServicePackageIcon, DraftsIcon
} from '../../common/widgetTools';
import { DEVICE_ACC, getPage } from '../../common/navigationTools';

/**
 * 
 * @param {*} props 
 * @returns 
 */
export const Customer = (props) => {

    const windowHeight = window.innerHeight;

    const navigate = useNavigate();
    const { tab } = useParams()
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const MAIL_PAGE = "register-mail";
    const CONTACT_PAGE = "register-contact";
    const PAYMENT_PAGE = "register-payment";
    const ORDERS_PAGE = "orders";
    const INVOICES_PAGE = "invoices";
    const SERVICEPACKAGES_PAGE = "servicepackages";
    const DRAFTS_PAGE = "customer-drafts";

    const _bpForModify = useSelector((state) => bpForModify(state));
    const _businessPartner = useSelector((state) => businessPartner(state));
    const _isWSS = useSelector((state) => isWSS(state));
    const _properties = useSelector((state) => properties(state));
    const _isGuestAuthenticated = useSelector((state) => isGuestAuthenticated(state));
    const backgroundColor = '#FEFEFE';
    const primaryColor = '#000000'

    useEffect(() => {
        //        alert("Customer ...");
        if ((!_bpForModify || _bpForModify === '{}') && _businessPartner) {
            dispatch(setBpForModify(_businessPartner));
        }
    }, []);


    const next = () => {
        //      navigate(getPage('templates', DEVICE));
    }

    const back = () => {
        navigate(-1);
    }
    const buttonStyle = () => {

        return (
            {
                padding: '0.2rem',
                paddingLeft: '1rem',
                paddingRight: '1rem',
                color: primaryColor,
                backgroundColor: backgroundColor,
                border: '1px solid',
                borderRadius: '10px',
                cursor: 'pointer',
                width: "100%"
            }
        );
    };

    const typographyStyle = market => {
        return (
            {
                color: primaryColor,
                fontSize: 28
            }
        );
    }


    const DataButton = ({ title, ariaTitle, ariaDescription, page }) => {
        return (
            <Box
                sx={buttonStyle}
                aria-label={t(ariaTitle)}
                //          aria-description={t(ariaDescription)}
                onClick={(e) => navigate(getPage(page, DEVICE_ACC))}
                role="listitem"
                tabIndex={0}
            >
                <Stack spacing={5} direction="row" alignItems="center" justifyContent="left">
                    {page === MAIL_PAGE && (<ContactMailIcon size='xl' />)}
                    {page === CONTACT_PAGE && (<ContactIcon size='xl' />)}
                    {page === PAYMENT_PAGE && (<PaymentsIcon size='xl' />)}
                    {page === ORDERS_PAGE && (<OrdersIcon size='xl' />)}
                    {page === INVOICES_PAGE && (<InvoiceIcon size='xl' />)}
                    {page === SERVICEPACKAGES_PAGE && (<ServicePackageIcon size='xl' />)}
                    {page === DRAFTS_PAGE && (<DraftsIcon size='xl' />)}
                    <Typography
                        variant='templatePickerTitle'
                        sx={typographyStyle}
                    >{t(title)}
                    </Typography>
                </Stack>
            </Box>
        );
    }

    return (<>
        <Stack direction="column" alignItems="center" spacing={8}>
            <AppHeader></AppHeader>
            <Stack sx={{ width: '90%', minHeight: windowHeight - 150 }} direction="column" alignItems="left" spacing={5}>
                <h1> <Typography sx={{ mt: '1rem' }} variant="mobilePagePrimaryLabel">{t('accessibility.customer.choose')}</Typography></h1>
                <List direction="column" >
                    <ListItem sx={{ width: "100%" }}><ListItemButton component={(props) => <DataButton {...props} title='customerdata.address.title' ariaTitle={'accessibility.customer.mail.label'} page={MAIL_PAGE} />} /></ListItem>
                    <ListItem sx={{ width: "100%" }}><ListItemButton component={(props) => <DataButton {...props} title='customerdata.contact.title' ariaTitle={'accessibility.customer.contact.label'} page={CONTACT_PAGE} />} /></ListItem>
                    <ListItem sx={{ width: "100%" }}> <ListItemButton component={(props) => <DataButton {...props} title='customerdata.payment.title2' ariaTitle={'accessibility.customer.payment.label'} page={PAYMENT_PAGE} />} /></ListItem>
                    {!_isGuestAuthenticated && (<ListItem sx={{ width: "100%" }}><ListItemButton component={(props) => <DataButton {...props} title='status.myOrders' ariaTitle={'accessibility.customer.orders.label'} page={ORDERS_PAGE} />} /></ListItem>)}
                    {_isWSS === false && (<ListItem sx={{ width: "100%" }}><ListItemButton component={(props) => <DataButton {...props} title='status.myInvoices' ariaTitle={'accessibility.customer.invoices.label'} page={INVOICES_PAGE} />} /></ListItem>)}
                    {_isWSS === false && _bpForModify?.servicePackages?.length > 0 && (<ListItem sx={{ width: "100%" }}><ListItemButton {...props} component={(props) => <DataButton title='status.myServicePackages' ariaTitle={'accessibility.customer.servicepackages.label'} page={SERVICEPACKAGES_PAGE} />} /></ListItem>)}
                </List >
            </Stack>
            <NavigationBar
                back={{ clicked: back, label: t('customerdata.back') }}
            />
            <InfoText />
        </Stack >
    </>);

}

export default Customer;