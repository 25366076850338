import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
    Box,
    Stack,
    Typography
} from '@mui/material';
import { urlIsSvg, readSVG, SVGIcon } from '../../common/SvgTools.js';
import { properties } from '../../redux/Selectors.js';

const SelectedMarket = ({ market, clickHandler }) => {

    const _properties = useSelector((state) => properties(state));

    const [isSVG, setIsSVG] = useState(false);
    const [svgMarket, setSVGMarket] = useState({});

    useEffect(() => {
        isSVGIcon();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isSVG === true) {
            createSVG();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [market, isSVG]);

    async function isSVGIcon() {
        let isSvgIcon = await urlIsSvg(market._links.marketIcon.href)
        setIsSVG(isSvgIcon);
    }

    async function createSVG() {
        let m = market;
        if (m?._links) {
            m.svg = await readSVG(market._links.marketIcon.href);

            setSVGMarket(m);
        }
    }



    const MarketSvgIcon = ({ market }) => {

        const color = "#000000";
        if (market?.svg) {
            market.svg.fill = color;
            market.svg.name = market.name;
        }
        //       console.log(color);
        //       console.log(market?.svg);

        const MSvgIcon = (props) => {
            //       return <></>
            return <SVGIcon svg={market.svg} {...props} />;
        }

        return market?.svg ?
            //    (<img style={{ height: "2rem", fill: color }} src={href} alt={market.name}></img>)
            (<MSvgIcon sx={{ fontSize: "3rem", pr: 2, color: color }} />)
            :
            (<></>);
    };

    return (
        market && (
            <Box sx={{ xheight: '4vh' }} onClick={clickHandler}>
                <h1><Stack sx={{ width: '90%' }} justifyContent="left" direction="row" alignItems="center" spacing={5}>
                    {isSVG === false && (<img height="32" alt="" src={market._links.selectedMarketIcon.href}></img>)}
                    {isSVG === true && (<MarketSvgIcon market={svgMarket} properties={_properties} />)}
                    <Typography
                        variant='mobilePageSelectedMarket'
                    >{market.name}
                    </Typography>
                </Stack>
                </h1>
            </Box >

        )
    );
};

export default SelectedMarket;
