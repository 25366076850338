import React from 'react';
import ReactDOM from 'react-dom';

import { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { getAuthentication } from '../redux/Actions';
import { properties, device } from '../redux/Selectors.js';
import { DEVICE_ACC } from '../common/navigationTools.js';

import {
    AppBar,
    Button,
    Box,
    Stack,
    Toolbar,
    Paper
} from '@mui/material';

import IconButton from '@mui/material/IconButton';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faXmark } from '@fortawesome/pro-regular-svg-icons';

const ExternalAuthentication = ({ url, closeHandler }) => {

    const innerWidth = window.innerWidth;

    const dispatch = useDispatch();
    const _properties = useSelector((state) => properties(state));
    const _device = useSelector(state => device(state));

    useEffect(() => {
        window.addEventListener('keydown', escapeKeyPressed);
        return (() => {
            window.removeEventListener('keydown', escapeKeyPressed);
        });
    }, []);

    const escapeKeyPressed = (event) => {
        if (event.key === "Escape") {
            closeHandler();
        }
    };

    const src = `${url}?auto=true`;
    const style = {
        zIndex: 99,
        height: "100vh",
        left: 0,
        position: "fixed",
        top: 0,
        width: "100vw",
    }

    const iframeStyle = () => {
        if (_device === 'mobile') {
            return {
                border: 'none',
                height: "100vh",
                left: 0,
                top: 0,
                width: innerWidth
            }
        } else {
            return {
                border: 'none',
                height: "80vh",
                left: 0,
                top: 0,
                width: '60vh'
            }
        }
    }

    const ID = 'authentication-app-bar-id';

    return ReactDOM.createPortal(
        _device === 'mobile' ?
            (<Box style={{
                left: 0,
                position: "fixed",
                top: 0,
                width: innerWidth
            }}>
                <AppBar id={ID} position="static" sx={{ color: _device === DEVICE_ACC ? _properties.appAccToolbarContrast : _properties.appToolbarContrast }}>
                    <Toolbar>
                        <Box sx={{ ml: '1rem', flexGrow: 1 }}>
                            <IconButton onClick={closeHandler} size="large" color="primary">
                                < FontAwesomeIcon icon={faChevronLeft} />
                            </IconButton>
                        </Box>
                        <IconButton sx={{ mr: '1rem' }} onClick={closeHandler} size="large" color="primary">
                            < FontAwesomeIcon icon={faXmark} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <iframe title='Login'
                    style={iframeStyle()}
                    src={src}></iframe>
            </Box>
            ) :
            (<Box sx={style}>
                <AppBar id={ID} position="static" sx={{ color: _device === DEVICE_ACC ? _properties.appAccToolbarContrast : _properties.appToolbarContrast }}>
                    <Toolbar>
                        <Box sx={{ ml: '1rem', flexGrow: 1 }}>
                            <IconButton onClick={closeHandler} size="large" color="primary">
                                < FontAwesomeIcon icon={faChevronLeft} />
                            </IconButton>
                        </Box>
                        <IconButton sx={{ mr: '1rem' }} onClick={closeHandler} size="large" color="primary">
                            < FontAwesomeIcon icon={faXmark} />
                        </IconButton>
                    </Toolbar>
                </AppBar>
                <Paper elevation={4} sx={{
                    backgroundColor: _properties.appBackgroundColor,
                    height: "100vh",
                    display: "grid",
                    placeItems: "center"
                }}>
                    <iframe title='Login'
                        style={iframeStyle()}
                        src={src}></iframe>
                </Paper>
            </Box>),
        document.getElementById("portal")
    );
};

export default ExternalAuthentication;